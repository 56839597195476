import React, {Component} from 'react';
import {Col, FormInput, InputGroup, InputGroupAddon, InputGroupText, Row} from 'shards-react';
import {withTranslation} from 'react-i18next';
import SwitchSelector from 'react-switch-selector';
import Loading from '../Loading/Loading';
import {Checkbox} from '@mui/material';
import {CheckCircle, CheckCircleOutline, HighlightOffOutlined, HighlightOffTwoTone} from '@mui/icons-material';
import {SelfServiceProcesses} from './SelfServiceProcesses';
import {SelfServicePostProcesses} from './SelfServicePostProcesses';
import {SelfServiceProductionRequirements} from './SelfServiceProductionRequirements';
import {getProcessesForSelfService, patchOrCreateSupplierProcess} from '../../services/kooper';
import {FilterBar} from '../FilterBar/FilterBar';
import {logEvent} from '../../utils/firebase';
import {toast} from 'react-hot-toast';


const _ = require('lodash');

export class SelfServiceCards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      processes: [],
      unfilteredProcesses: [],
      loading: true,
      selectedClass: 'process',
    };
  }

  componentDidMount() {
    getProcessesForSelfService()
      .then((res) => {
        const unfilteredProcessesWithTranslation = res.data.map(process => ({...process, translation: this.props.t(`processes:${process.key}`)})).sort();
        this.setState({processes: res.data, unfilteredProcesses: unfilteredProcessesWithTranslation, loading: false});
      })
      .catch(() => {
        this.setState({loading: 'error'});
      });
  }

  createToast = (type, message, process, error) => {
    message = message.replace('$PROCESS$', this.props.t(`processes:${process.key}`));
    message = message.replace('$ERROR$', error);
    toast[type](message);
  };

  toggleSupplierProcess = (process, toggleToState) => {
    process.active = toggleToState;
    process.collapseOpen = false;
    patchOrCreateSupplierProcess(process)
      .then(() => {
        this.updateProcessInState(process);
        const message = process.active === true ? this.props.t('selfService:toast_process_created_sucess') : this.props.t('selfService:toast_process_deleted_sucess');
        this.createToast('success', message, process);
      })
      .catch((err) => {
        const message = process.active === true ? this.props.t('selfService:toast_process_created_error') : this.props.t('selfService:toast_process_deleted_error');
        this.createToast('error', message, process, err);
      });
  };

  handleProcessFeatureInput = (process, processFeature, value) => {
    const processesCopy = [...this.state.processes];
    const currentProcess = processesCopy.find(x => x.key === process.key);
    let currentProcessFeature = currentProcess.processFeatures.find(x => x.processFeatureKey === processFeature.processFeatureKey);
    currentProcessFeature.value = value;
    this.setState({processesCopy});
  };

  updateProcessInState = (process) => {
    const processesCopy = [...this.state.processes];
    let currentProcess = processesCopy.find(x => x.key === process.key);
    currentProcess = process;
    this.setState({processesCopy});
  };

  createProcessFeatureInputGroup = (processFeature, process) => {
    if (processFeature) {
      return (
        <InputGroup>
          <FormInput
            value={processFeature.value}
            onChange={(e) =>
              this.handleProcessFeatureInput(process, processFeature, e.target.value)
            }
            invalid={(!processFeature.value && process.active) || (!processFeature.value && process.collapseOpen && process.processFeatures.some(x => x.processFeatureKey === 'additional_leadtime'))}
            type={'number'}
          />
          <InputGroupAddon type='append'>
            <InputGroupText style={{width: '110px'}}>
              {this.props.t(`units:${processFeature.unitKey}`)}
            </InputGroupText>
          </InputGroupAddon>
        </InputGroup>
      );
    }
  };

  renderCards() {
    const {t} = this.props;
    const selectedProcesses = this.state.processes.filter(
      (process) => process.class === this.state.selectedClass,
    );

    if (!selectedProcesses.length) {
      window.location.hash = '';
    }

    switch (this.state.selectedClass) {
      case 'process':
        return (
          <SelfServiceProcesses
            processes={selectedProcesses}
            createProcessFeatureInputGroup={this.createProcessFeatureInputGroup}
            t={t}
            handleToggle={this.toggleSupplierProcess}
            handleProcessUpdate={this.updateProcessInState}
          />
        );
      case 'post_process':
        return <SelfServicePostProcesses
          processes={selectedProcesses}
          createProcessFeatureInputGroup={this.createProcessFeatureInputGroup}
          t={t}
          handleToggle={this.toggleSupplierProcess}
          handleProcessUpdate={this.updateProcessInState}
        />;
      default:
        return <SelfServiceProductionRequirements
          processes={selectedProcesses}
          createProcessFeatureInputGroup={this.createProcessFeatureInputGroup}
          t={t}
          handleToggle={this.toggleSupplierProcess}
        />;
    }
  }

  findByKey = (array, key) => {
    return array.find((x) => x.key === key);
  };

  showLoading = () => {
    if (this.state.loading === 'error') {
      return (
        <div style={{display: 'flex', justifyContent: 'center', marginTop: '150px'}}>
          <span> {this.props.t('errors:error_server_error_contact_support')} </span>
        </div>
      );
    }
    return (
      <div style={{display: 'flex', justifyContent: 'center', marginTop: '150px'}}>
        <Loading pageHeight={300} />
      </div>
    );
  };

  render() {
    const {t} = this.props;
    const options = [
      {
        label: t('processClasses:process'),
        value: 'process',
      },
      {
        label: t('processClasses:post_process'),
        value: 'post_process',
      },
      {
        label: t('processClasses:production_requirement'),
        value: 'production_requirement',
      },
    ];

    this.props.history.listen(history => {

      if (!history.hash) {
        this.setState({processes: this.state.unfilteredProcesses});
      } else {
        const params = history.hash.split('?')[1].split('&');
        const searchParam = params.find(x => x.includes('search'));

        if (searchParam) {
          this.setState({
            processes: this.state.unfilteredProcesses.filter(process => process.key === searchParam?.split('=')[1]),
          });
        } else {
          const orderBy = params.find(x => x.includes('orderBy')).split('=')[1];
          const sortedProcesses = _.orderBy(this.state.unfilteredProcesses, ['translation'], [orderBy]);
          this.setState({processes: sortedProcesses});
        }
      }
    });

    return (
      <React.Fragment>
        <Row>
          <div
            className='your-required-wrapper'
            style={{width: '100%', height: '50px', marginBottom: '50px'}}
          >
            <SwitchSelector
              onChange={(newValue) => {
                this.setState({selectedClass: newValue});
              }}
              options={options}
              initialSelectedIndex={0}
            />
          </div>
        </Row>
        <Row>
          <Col>
            <div className='pt-2' style={{marginBottom: '60px', padding: '10px'}}>
              <FilterBar
                t={t}
                searchOptions={
                  this.state.unfilteredProcesses
                    .filter((process) => process.class === this.state.selectedClass)
                    .map(process => ({value: process.key, label: process.translation}))}
              />
            </div>
          </Col>
        </Row>
        {this.state.loading ? this.showLoading() : this.renderCards()}
      </React.Fragment>
    );
  }
}

export default withTranslation()(SelfServiceCards);

export const ThreeStateCheckboxSwitch = (props) => {
  const {checked, onEnable, onDisable} = props;

  const disable = () => {
    logEvent('self_service_process_disable');
    onDisable();
  };
  const enable = () => {
    logEvent('self_service_process_enable');
    onEnable();
  };

  return (
    <React.Fragment>
      <Checkbox
        icon={<HighlightOffOutlined fontSize={'large'} />}
        checkedIcon={<HighlightOffTwoTone fontSize={'large'} />}
        checked={typeof checked === 'boolean' && !checked}
        onChange={() => (typeof checked !== 'boolean' || checked) && disable()}
      />
      <Checkbox
        icon={<CheckCircleOutline fontSize={'large'} />}
        color={'primary'}
        style={checked ? {color: '#11db11'} : {}}
        checkedIcon={<CheckCircle fontSize={'large'} />}
        checked={typeof checked === 'boolean' && checked}
        onChange={() => !checked && enable()}
      />
    </React.Fragment>
  );
};