import React from 'react';
import {Col, Container, Row} from 'shards-react';
import PageTitle from 'components/Common/PageTitle';
import BundleTable from 'components/BundleTable/BundleTable';
import {useTranslation} from 'react-i18next';

const ProducedBundles = (props: any) => {
  const {t} = useTranslation('bundletable');
  return (
    <Container fluid className={'main-content-container px-4'}>
      <Row noGutters className={'page-header py-4'}>
        <PageTitle
          sm={'4'}
          title={t('page_title_produced')}
          subtitle={t('page_subtitle_produced')}
          className={'text-sm-left'}
        />
      </Row>

      <Row>
        <Col>
          <BundleTable
            {...props}
            statuses={['shipped', 'partially_delivered', 'delivered', 'invoice_sent', 'invoice_paid']}
            order={{id: 'desc'}}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default ProducedBundles;
