import React from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import routes from 'routes.js';
import ErrorPage from 'views/Pages/ErrorPage';
import ReactTooltip from 'react-tooltip';
import {Container} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {BundleDetails} from '../../../../containers/BundleDetails/BundleDetails';
import {useTranslation} from 'react-i18next';
import {AuctionPackageDetails} from '../../../../containers/CombinatorialAuctions/AuctionPackageDetails';
import RFQDetails from '../../../../containers/RFQ/RFQDetails';

export const MainContainer = (props) => {

  const navigate = useNavigate();
  const {t} = useTranslation(['productionRequests', 'bundledetailspage', 'commenttypes', 'modals', 'dates', 'services', 'commenttypes', 'auctions']);
  const getRoutes = () => {
    return routes.map((route, key) => {
      if (route.component) {
        return <Route path={route.layout + route.path} element={<route.component {...props} t={t} navigate={navigate} />} key={key} />;
      }
    });
  };

  return (
    <Container style={{...props.style, padding: 0, backgroundColor: '#FFF', minWidth: 'calc(100vw - 80px)'}}>
      <Routes>
        <Route path="/error/:statusCode" render={<ErrorPage />} />
        <Route path={'/'} element={<Navigate to={'/dashboard'} replace />} />
        <Route path={'/bundle-suppliers/:id'} history={props.history} element={<BundleDetails {...props} t={t} navigate={navigate} />} />
        <Route path={'/auctions/:id'} history={props.history} element={<AuctionPackageDetails {...props} t={t} navigate={navigate} />} />
        <Route path={'/rfq/:id'} history={props.history} element={<RFQDetails {...props} t={t} navigate={navigate} />} />
        {getRoutes(routes)}
        <Route element={() => <ErrorPage statusCode={'404'} />} />
      </Routes>
      <ReactTooltip effect="solid" type="error" />
    </Container>
  );
};
export default MainContainer;
