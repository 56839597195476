import React, {Component} from 'react';
import i18n from 'i18next';
import {Dropdown, DropdownMenu, DropdownItem, DropdownToggle, Button} from 'shards-react';
import en from './en.svg';
import de from './de.svg';
import eu from './eu.svg';
import './LanguageToggle.css';


class LanguageToggle extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {open: false};
  }

  toggle() {
    this.setState({open: !this.state.open});
  }

  changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    this.setState({open: false});
  };

  showFlag = () => {
    switch (i18n.language) {
      case 'de':
        return <img src={de} className={'flag'} alt='German' />;

      case 'en':
        return <img src={en} className={'flag'} alt='English' />;

      default:
        return <img src={eu} className={'flag'} alt='English' />;
    }
  };

  render() {

    return (
      <React.Fragment>
        <Dropdown open={this.state.open} toggle={this.toggle}>

          <DropdownToggle nav>
            <Button className={'language-button'} style={{borderRadius: '38px'}}>
              <div>
                {this.showFlag()}
              </div>
            </Button>
          </DropdownToggle>

          <DropdownMenu className={'language-dropdown'} right>
            <DropdownItem onClick={() => {this.changeLanguage('en');}}>
              <img src={en} className={'flag'} alt='English' /> {'English'}
            </DropdownItem>
            <DropdownItem onClick={() => {this.changeLanguage('de');}}>
              <img src={de} className={'flag'} alt='Deutsch' /> {'Deutsch'}
            </DropdownItem>
          </DropdownMenu>

        </Dropdown>
      </React.Fragment>
    );
  }
}

export default LanguageToggle;