import React, {Component} from 'react';
import {getBundlePageData, getReorderedPartsPerBundle, insertSeenAt} from '../../services/kooper';
import {Box, Grid, IconButton, Tooltip, Typography} from '@mui/material';
import {CollapsablePartDetails} from '../../components/BundleDetailsPage/CollapsablePartDetails/CollapsablePartDetails';
import {ActionPanel} from '../../components/BundleDetailsPage/ActionPanel';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import DownloadModal from '../../components/Modals/DownloadModal/DownloadModal';
import KeyboardDoubleArrowUpOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowUpOutlined';
import {MessageModal} from '../../components/Modals/MessageModal/MessageModal';
import {BundleStatusIcons} from '../../components/BundleDetailsPage/BundleStatusIcons';
import {BundleStatus} from '../../components/BundleDetailsPage/BundleStatus';
import kaktusLoader from '../../assets/icons/kaktusLoader.svg';
import {logEvent} from '../../utils/firebase';
import {ZoomModal} from '../../components/Modals/ZoomModal/ZoomModal';
import ReplayIcon from '@mui/icons-material/Replay';

interface Props {
  t: Function;
  navigate: Function;
}

interface State {
  loading: boolean,
  bundle: any,
  collapsedCards: { bopcId: number, collapse: boolean }[],
  reorderedParts: number
}

export class BundleDetails extends Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: true,
      bundle: {},
      collapsedCards: [],
      reorderedParts: 0,
    };
  }

  getRequiredQms = (processes: string[], batchSize: number) => {
    let requiredServices: string[] = [];
    if (!processes.some((process: string) => ['sls', 'slm', 'polyjet', 'fdm'].includes(process))) {
      requiredServices.push('measurement_report');
      if (batchSize > 30) {
        requiredServices.push('material_certificate', 'control_plan');
      }
    }
    return requiredServices;
  };

  getRequiredAndActualQmsDocuments = () => {
    let bopcWithQms: any[] = [];
    for (const _bopc of this.state.bundle.bundleOrderedPartConfigs) {
      const processes = _bopc.orderedPartConfig.orderedPartConfigProcesses.map((x: any) => x.process.key);
      const batchSize = _bopc.orderedPartConfig.batchSize;
      const requiredQms = this.getRequiredQms(processes, batchSize);
      const attachments = _bopc.attachments?.map((x: any) => x.kaktusAttachment.attachmentType.key);
      bopcWithQms.push({bundleOrderedPartConfigId: _bopc.id, requiredQms: requiredQms, actualQms: attachments});
    }
    return bopcWithQms;
  };

  componentDidMount() {
    const bundleId = Number.parseInt(window.location.pathname.split('/')[2]);
    const query = new URLSearchParams(window.location.search);
    const source = query.get('source');
    if (source === 'email') {
      logEvent('bundle_opened_from_email');
      window.location.search = '';
    }
    this.getDataAndSaveToState(bundleId);
  }

  getDataAndSaveToState = (bundleId: number) => {
    this.setState({loading: true});
    getBundlePageData(bundleId)
      .then(res => {
        insertSeenAt(bundleId);
        this.setState({bundle: res.data, loading: false, collapsedCards: res.data.bundleOrderedPartConfigs.map(x => ({bopcId: x.id, collapse: res.data.statusKey === 'open'}))});
        document.title = res.data.bundleKid;
      });
    getReorderedPartsPerBundle([bundleId]).then((response) => {
      const res = response.data;
      this.setState({reorderedParts: res[0].reorderedParts.length});
    });
  };

  render() {
    const {loading, bundle} = this.state;
    if (loading) {
      return (
        <Grid container>
          <Grid item xs={12} display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100vh'}>
            <Typography variant="h3" component="h4">
              <div className={'lds-eclipse'}>
                <img width={200} src={kaktusLoader} alt={'loader'} />
                <div />
              </div>
            </Typography>
          </Grid>
        </Grid>
      );
    }
    const qms = this.getRequiredAndActualQmsDocuments();
    return (
      <Grid container key={'bundles-container-page'} sx={{minHeight: '100%', maxHeight: '100%'}}>
        <Grid item container paddingX={5} key={'bundles-page-no-action-panel'} sx={{backgroundColor: 'white', width: 'calc(100% - 330px)'}}>
          <Grid item container marginTop={5} sx={{height: '90px'}} xs={12} key={'bundles-page-title-and-buttons'}>
            <Grid item container xs={12} alignContent={'flex-start'} key={'bundles-page-title'}>
              <BundleStatus bundle={bundle} t={this.props.t} />
              <Grid item container xs={12} alignItems={'center'} spacing={2}>
                <Grid item>
                  <Typography variant={'h3'} sx={{color: '#131C3E'}}>{
                    !loading ? `${bundle.bundleKid}` : 'Loading...'}
                  </Typography>
                </Grid>
                <BundleStatusIcons createdAt={this.state.bundle.createdAt} status={this.state.bundle.statusKey} t={this.props.t} currentBundleStatus={bundle.currentBundleStatus} />
              </Grid>
              {this.state.reorderedParts > 0 &&
                <Grid item container xs={12} alignItems={'center'} spacing={'5px'}>
                  <Grid item alignContent={'center'} sx={{marginBottom: '1px'}}>
                    <ReplayIcon sx={{fontSize: 'small', backgroundColor: '#C1EBF6', borderRadius: '1px', transform: 'scaleX(-1)'}} />
                  </Grid>
                  <Grid item alignContent={'center'}>
                    <Typography variant={'body2'} sx={{fontSize: '12px', display: 'flex', alignItems: 'center'}}>
                      {`${this.props.t('bundledetailspage:bs_this_bundle_contains').toUpperCase()} ${this.state.reorderedParts} ${this.props.t('bundledetailspage:bs_of')
                        .toUpperCase()} ${this.state.bundle.bundleOrderedPartConfigs.length} ${this.props.t('bundledetailspage:parts_already_produced_in_the_past').toUpperCase()}`}
                    </Typography>
                  </Grid>
                </Grid>}
            </Grid>
            <Grid item container xs={12} justifyContent={'flex-end'} alignItems={'flex-end'} spacing={1} key={'buttons'}>
              {this.state.bundle.statusKey === 'counter_offer_requested' &&
                <Grid item container xs={7} justifyContent={'flex-start'}>
                  <Box sx={{backgroundColor: '#e5f7fb', paddingY: '4px', paddingX: '16px', borderRadius: '5px'}}>
                    <Typography typography={'subtitle2'}>{this.props.t('bundledetailspage:bs_counteroffer_helper')}</Typography>
                  </Box>
                </Grid>
              }
              <Grid item container xs={1} justifyContent={'flex-end'}>
                <Tooltip
                  arrow
                  placement={'top'}
                  title={this.props.t('bundledetailspage:bs_button_expand')}
                >
                  <IconButton
                    onClick={() => {
                      this.setState({collapsedCards: this.state.collapsedCards.map(card => ({...card, collapse: !this.state.collapsedCards.every((card) => card.collapse)}))});
                    }}
                    sx={{
                      '&.MuiIconButton-root:hover': {backgroundColor: '#f3f3f3'},
                      height: 30,
                      width: 30,
                      border: 1,
                      borderColor: '#131C3E',
                    }}
                  >
                    {this.state.collapsedCards.every((card) => card.collapse) ?
                      <KeyboardDoubleArrowUpOutlinedIcon fontSize={'small'} sx={{color: '#131C3E', height: 20, width: 20}} /> :
                      <KeyboardDoubleArrowDownIcon fontSize={'small'} sx={{color: '#131C3E', height: 20, width: 20}} />}
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item container xs={2} justifyContent={'flex-end'}>
                <DownloadModal
                  bundleId={this.state.bundle.id}
                  loading={this.state.loading}
                  status={this.state.bundle.statusKey}
                  hasOrderConfirmationUploaded={this.state.bundle.bundleAttachments?.some((attachment: any) => attachment.kaktusAttachment.attachmentType.key === 'order_confirmation')}
                  supplier={this.state.bundle.supplier}
                  t={this.props.t}
                />
              </Grid>
              <Grid item container xs={2} justifyContent={'flex-end'}>
                <MessageModal t={this.props.t} bundleId={bundle.id} />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item container
            xs={12}
            key={'parts'}
            sx={{
              height: 'calc(100vh - 250px) !important',
              // Hide scrollbar for main container
              '&::-webkit-scrollbar': {display: 'none'},
              '-ms-overflow-style': 'none',
              'scrollbar-width': 'none',
            }}
            overflow={'scroll'}
            alignContent={'flex-start'}
          >
            {this.state.bundle.bundleOrderedPartConfigs.map((bundleOrderedPartConfig, index) =>
              <CollapsablePartDetails
                t={this.props.t}
                navigate={this.props.navigate}
                bundleOrderedPartConfig={bundleOrderedPartConfig}
                bundle={bundle}
                index={index}
                totalItems={this.state.bundle.bundleOrderedPartConfigs.length}
                key={`part-details-card-${index}`}
                collapse={this.state.collapsedCards.find(x => x.bopcId === bundleOrderedPartConfig.id)?.collapse || false}
                handleCollapse={(collapse) => {
                  this.setState({
                    collapsedCards: [
                      ...this.state.collapsedCards.filter(x => x.bopcId !== bundleOrderedPartConfig.id),
                      {bopcId: bundleOrderedPartConfig.id, collapse},
                    ],
                  });
                }}
                updateBundlePage={() => this.getDataAndSaveToState(bundle.id)}
              />)}
            <ZoomModal
              t={this.props.t}
              bundle={this.state.bundle}
            />
          </Grid>
        </Grid>
        <Grid item container sx={{backgroundColor: '#F9F9F9', width: '330px'}}>
          <ActionPanel
            qms={qms}
            t={this.props.t}
            navigate={this.props.navigate}
            bundle={bundle}
            updateBundlePage={() => this.getDataAndSaveToState(bundle.id)}
          />
        </Grid>
      </Grid>
    );
  }
}