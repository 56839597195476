import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {Button, Card, CardBody} from 'shards-react';
import {Link} from 'react-router-dom';
import {ScaleLoader} from 'react-spinners';
import {logEvent} from '../../utils/firebase';


class SmallStats extends React.Component {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
  }

  handleClick() {
    logEvent('dashboard_to_list_button_clicked');
    this.props.navigate(`/${this.props.redirect}`);
  }

  render() {
    const variation = '1';

    const {label, value} = this.props;

    const cardClasses = classNames('stats-small', variation && `stats-small--${variation}`);

    const cardBodyClasses = classNames(variation === '1' ? 'p-0 d-flex' : 'px-0 pb-0');

    const innerWrapperClasses = classNames(
      'd-flex',
      variation === '1' ? 'flex-column m-auto' : 'px-3',
    );

    const dataFieldClasses = classNames('stats-small__data', variation === '1' && 'text-center');

    const labelClasses = classNames(
      'stats-small__label',
      'text-uppercase',
      variation !== '1' && 'mb-1',
    );

    const valueClasses = classNames(
      'stats-small__value',
      'count',
      variation === '1' ? 'my-3' : 'm-0',
    );

    return (
      <Card
        small
        className={cardClasses}
        style={{height: '100%'}}
        onClick={(e) => this.handleClick(e)}
      >
        <CardBody className={cardBodyClasses}>
          <div className={innerWrapperClasses}>
            <div style={{alignItems: 'center'}} className={dataFieldClasses}>
              <span className={labelClasses}>{label}</span>
              <div
                style={{
                  height: '56px',
                  width: '103px',
                }}
              >
                {this.props.loading ? (
                  <ScaleLoader color={'#32bee1'} />
                ) : (
                  <h6 className={valueClasses}>
                    {
                      <Link style={{color: 'dimgrey'}} to={`/${this.props.redirect}`}>
                        {value}
                      </Link>
                    }
                  </h6>
                )}
              </div>
            </div>
          </div>
        </CardBody>
        <Button theme='info' onClick={(e) => this.handleClick(e)}>
          {this.props.buttonTitle}
        </Button>
      </Card>
    );
  }
}

SmallStats.propTypes = {
  /**
   * The Small Stats variation.
   */
  variation: PropTypes.string,
  /**
   * The label.
   */
  label: PropTypes.string,
  /**
   * The value.
   */
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * The percentage number or string.
   */
  percentage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Whether is a value increase, or not.
   */
  increase: PropTypes.bool,
  /**
   * The Chart.js configuration object.
   */
  chartConfig: PropTypes.object,
  /**
   * The Chart.js options object.
   */
  chartOptions: PropTypes.object,
  /**
   * The chart data.
   */
  chartData: PropTypes.array.isRequired,
  /**
   * The chart labels.
   */
  chartLabels: PropTypes.array,
};

SmallStats.defaultProps = {
  increase: true,
  percentage: 0,
  value: 0,
  label: 'Label',
  chartOptions: Object.create(null),
  chartConfig: Object.create(null),
  chartData: [],
  chartLabels: [],
};

export default SmallStats;
