import React from 'reactn';
import Swal from 'sweetalert2';
import ErrorPage from '../../views/Pages/ErrorPage';
import * as Sentry from '@sentry/browser';
import {withGlobal} from 'reactn';


type State = {
  hasError: boolean,
  eventId: string,
  href: string
}

class ErrorBoundary extends React.Component<any, State> {

  constructor(props) {
    super();
    this.state = {
      hasError: false,
      eventId: '',
      href: '',
    };
  }

  componentDidUpdate() {
    /* Compare current href to href when error occured to reset hasError state when page changed*/
    if (this.state.href !== window.location.href && this.state.hasError) {
      this.setState({hasError: false});
    }
  }

  componentDidCatch(error, errorInfo) {

    const excludedErrorNames = ['NotFoundError']

    if(!excludedErrorNames.includes(error.name)) {
      Sentry.setContext('ErrorObject', error)
      const eventId = Sentry.captureException(error, {extra: errorInfo});
      Sentry.setTag('ErrorBoundary', 'yes')
      this.setState({hasError: true, href: window.location.href, eventId});
    }
  }

  render() {

    if (this.state.hasError) {
      return <ErrorPage statusCode={''} sentryEventId={this.state.eventId} />;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
