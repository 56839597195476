import {Button, Checkbox, FormControlLabel, Grid, Typography} from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import React from 'react';
import {Trans} from 'react-i18next';
import {formatCurrency} from '../../../utils/formatHelpers';
import {useAtom} from 'jotai';
import {CounterOfferPrices} from '../../../global-atoms';


type Props = {
  t: Function,
  priceConfirmed: boolean,
  handlePriceConfirm: any,
  numberOfPartsInBundle: number,
  bundleOrderedPartConfigs: any[]
}

type FooterProps = {
  handleSubmit: Function,
  handleStepChange: Function,
  hasMultipleSteps: boolean
  priceConfirmed: boolean,
  t: Function
}

export const CounterOfferModalConfirmPrice = (props: Props) => {

  const [counterOfferPrices] = useAtom(CounterOfferPrices);

  const partsWithoutCounterOffer = props.bundleOrderedPartConfigs.filter(x => !counterOfferPrices.filter(x => x.active).map(cop => cop.bundleOrderedPartConfigId).includes(x.id));
  const totalForPartsWithoutCounterOffer = partsWithoutCounterOffer.reduce((acc, curr) => acc + parseFloat(curr.pricePerPiece) * curr.orderedPartConfig.batchSize, 0);
  const totalCounterOfferPrice = counterOfferPrices.filter(x => x.active).reduce((acc, curr) => acc + curr.counterOfferPrice * curr.batchSize, 0);
  const totalNewPriceWithCounterOffer = totalForPartsWithoutCounterOffer + totalCounterOfferPrice;

  return (
    <Grid container rowSpacing={1} marginTop={'5px'}>
      <Grid item xs={12} marginBottom={2}>
        <Typography typography={'h6'}>
          {props.t('modals:counter_offer_modal_title')}
        </Typography>
      </Grid>
      <Grid item xs={12} marginBottom={5}>
        <Typography typography={'body'}>
          <Trans i18nKey={'modals:counter_offer_modal_subtitle'} />
        </Typography>
      </Grid>

      <Grid item xs={12} marginBottom={0}>
        <Typography typography={'body'}>
          <Trans
            i18nKey={'modals:counter_offer_modal_offered_total_amount_of_positions'}
            values={{
              numberOfSubmittedParts: counterOfferPrices.filter(x => x.active).length,
              numberOfTotalParts: props.numberOfPartsInBundle,
            }}
          />
        </Typography>
      </Grid>
      <Grid item xs={12} marginBottom={5}>
        <Typography typography={'body'}>
          <Trans
            i18nKey={'modals:counter_offer_modal_offered_total_price'}
            values={{totalOfferedPrice: formatCurrency(totalNewPriceWithCounterOffer)}}
          />
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <FormControlLabel
          control={<Checkbox onChange={() => props.handlePriceConfirm(!props.priceConfirmed)} checked={props.priceConfirmed} />}
          label={'I understand that I can not edit or withdraw my counteroffer after submitting it.'}
        />
      </Grid>
    </Grid>
  );
};

export const CounterOfferModalConfirmPriceFooter = (props: FooterProps) => {
  return (
    <Grid item xs={12} container justifyContent='flex-end' alignItems='flex-end' height={'100%'}>
      <Grid item xs={6} />
      <Grid item xs={6} style={{justifyContent: 'flex-end', display: 'flex', paddingRight: 20, paddingBottom: 20}}>
        <Button
          disabled={!props.priceConfirmed}
          onClick={() => props.hasMultipleSteps ? props.handleStepChange('next') : props.handleSubmit()}
          variant={'contained'}
          color={'info'}
        >
          {props.hasMultipleSteps ? props.t('modals:next') : props.t('modals:submit')}
          <ArrowForwardIosIcon fontSize='small' style={{marginLeft: 10}} />
        </Button>
      </Grid>
    </Grid>
  );
};