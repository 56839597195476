import React from 'react';
import {Card, CardContent, Grid, Tooltip, Typography} from '@mui/material';
import {formatCurrency, formatDate} from '../../utils/formatHelpers';
import {ActionPanelModals} from './ActionPanelModals';
import {ActionPanelIcons} from './ActionPanelIcons';
import {useAtom} from 'jotai';
import {CounterOfferPrices} from '../../global-atoms';

type Props = {
  t: Function,
  navigate: Function,
  bundle: any
  qms: any
  updateBundlePage: Function
}

export const ActionPanel = (props: Props) => {

  const [counterOfferPrices] = useAtom(CounterOfferPrices);
  const {bundle} = props;
  const {deliveryDate, currentDeliveryDate, bundleKid: kaktusNumber, numberOfPartConfigs, price: totalPrice, counterOfferPrice, bundleOrderedPartConfigs, statusKey, createdAt} = bundle;
  const totalWeight = bundleOrderedPartConfigs.reduce((accumulator, bundleOrderedPartConfig) => accumulator + (parseFloat(bundleOrderedPartConfig.orderedPartConfig.orderedPart.orderedPartPartFeatures.find(oppf => oppf.partFeatureKey === 'weight')?.value || 0) * bundleOrderedPartConfig.orderedPartConfig.batchSize) / 1000, 0);
  const partsWithoutCounterOffer = bundleOrderedPartConfigs.filter(x => !counterOfferPrices.filter(x => x.active && x.counterOfferPrice).map(cop => cop.bundleOrderedPartConfigId).includes(x.id));
  const totalForPartsWithoutCounterOffer = partsWithoutCounterOffer.reduce((acc, curr) => acc + parseFloat(curr.pricePerPiece) * curr.orderedPartConfig.batchSize, 0);
  const totalCounterOfferPrice = counterOfferPrices.filter(x => x.active && x.counterOfferPrice).reduce((acc, curr) => acc + curr.counterOfferPrice * curr.batchSize, 0);
  const totalNewPriceWithCounterOffer = totalForPartsWithoutCounterOffer + totalCounterOfferPrice;

  return (
    <Grid item xs={12} display={'flex'} justifyContent={'flex-end'}>
      <Card sx={{borderRadius: '3px', width: '100%', paddingX: '15px'}}>
        <CardContent>
          <Grid container key={'card-content-container'} marginTop={5} spacing={1}>
            <Grid item container key={'title-content'} marginBottom={1}>
              <Grid item container xs={12} key={'title-and-icon-line'} alignItems={'center'} minWidth={30}>
                <Grid item xs={2} justifyContent={'flex-start'} key={'question'}>
                  <ActionPanelIcons createdAt={createdAt} status={statusKey} currentBundleStatus={props.bundle.currentBundleStatus} t={props.t} />
                </Grid>
                <Grid item container xs={10} key={'title'}>
                  <Grid item xs={12} display={'flex'} justifyContent={'flex-end'}>
                    <Tooltip
                      arrow
                      placement={'right'}
                      componentsProps={{tooltip: {sx: {maxWidth: '2000px', paddingX: '20px', paddingY: '10px'}}}}
                      title={
                        props.bundle.statusKey !== 'delayed' ? '' :
                          <>
                            <Typography sx={{color: '#FFF', fontSize: '12px', marginBottom: '10px'}}>{props.t('bundledetailspage:bs_tooltip_delay_reason')}</Typography>
                            <ul style={{marginLeft: '10px'}}>
                              {props.bundle.currentBundleStatus.bundleStatusFeatureValues
                                .filter(bundleStatusFeatureValue => bundleStatusFeatureValue.statusFeatureKey === 'delay_reason')
                                .map(bundleStatusFeatureValue => <li> {props.t(`commenttypes:${bundleStatusFeatureValue.value}`)}</li>)
                              }
                            </ul>
                          </>
                      }
                    >
                      <Typography
                        sx={{
                          fontSize: deliveryDate !== currentDeliveryDate ? '18px' : '22px',
                          fontWeight: 700,
                          color: deliveryDate !== currentDeliveryDate ? '#D7D7D7' : '#131C3E',
                          textDecoration: deliveryDate !== currentDeliveryDate ? 'line-through' : 'none',
                        }}
                      >
                        {formatDate(deliveryDate)}
                      </Typography>
                    </Tooltip>

                  </Grid>
                  <Grid item xs={12} display={'flex'} justifyContent={'flex-end'}>
                    {deliveryDate !== currentDeliveryDate ?
                      <Typography sx={{fontSize: '22px', fontWeight: 700, color: '#131C3E'}}>
                        {formatDate(currentDeliveryDate)}
                      </Typography> : <></>}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container xs={12} justifyContent={'flex-end'}>
                <Typography sx={{fontSize: '11px', color: '#5A6078', fontWeight: 600, textTransform: 'uppercase'}}>
                  {props.t('productionRequests:table_header_delivery_date')}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container key={'details-container'} marginTop={1} rowSpacing={2}>
              <Grid item xs={12} key={'details-container-first-line'}>
                <Card sx={{borderWidth: '0px 0px 0.3px 0px ', borderRadius: '0px', borderColor: '#5A6078'}}>
                  <Grid container xs={12}>
                    <Grid item container xs={6} justifyContent={'flex-start'}>
                      <Typography sx={{fontSize: '11px', color: '#5A6078', fontWeight: 600, textTransform: 'uppercase'}}>
                        {props.t('productionRequests:table_header_kaktus_number')}
                      </Typography>
                    </Grid>
                    <Grid item container xs={6} justifyContent={'flex-end'}>
                      <Typography sx={{fontSize: '11px', color: '#131C3E', fontWeight: 500}}>
                        {kaktusNumber}
                      </Typography>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>

              <Grid item xs={12} key={'details-container-second-line'}>
                <Card sx={{borderWidth: '0px 0px 0.3px 0px ', borderRadius: '0px', borderColor: '#5A6078'}}>
                  <Grid container xs={12}>
                    <Grid item container xs={6} justifyContent={'flex-start'}>
                      <Typography sx={{fontSize: '11px', color: '#5A6078', fontWeight: 600, textTransform: 'uppercase'}}>
                        {props.t('bundledetailspage:bs_action_panel_number_of_parts')}
                      </Typography>
                    </Grid>
                    <Grid item container xs={6} justifyContent={'flex-end'}>
                      <Typography color={'secondary'} sx={{fontSize: '11px', fontWeight: 500}}>
                        {numberOfPartConfigs}
                      </Typography>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>

              <Grid item xs={12} key={'details-container-third-line'}>
                <Card sx={{borderWidth: '0px 0px 0.3px 0px ', borderRadius: '0px', borderColor: '#5A6078'}}>
                  <Grid container xs={12}>
                    <Grid item xs={6}>
                      <Typography sx={{fontSize: '11px', color: '#5A6078', fontWeight: 600, textTransform: 'uppercase'}}>
                        {props.t('bundledetailspage:bs_action_panel_total_weight')}
                      </Typography>
                    </Grid>
                    <Grid item container xs={6} justifyContent={'flex-end'}>
                      <Typography sx={{fontSize: '11px', color: '#131C3E', fontWeight: 500}}>
                        {`${totalWeight.toFixed(2)} KG`}
                      </Typography>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            </Grid>
            <Grid item xs={12} container marginTop={3} marginBottom={5}>
              {((!!counterOfferPrice && ['counter_offer_submitted', 'counter_offer_accepted'].includes(statusKey)) ||
                  (statusKey === 'counter_offer_requested' && !!counterOfferPrices.filter(x => x.active && x.counterOfferPrice).length)) &&
                <Grid item container xs={12}>
                  <Grid item container xs={6} justifyContent={'flex-start'}>
                    <Typography sx={{fontSize: '14px', color: '#d7d7d7', fontWeight: 700, textTransform: 'uppercase', textDecoration: 'line-through'}}>
                      {props.t('bundledetailspage:bs_action_panel_total_price')}
                    </Typography>
                  </Grid>
                  <Grid item container xs={6} justifyContent={'flex-end'}>
                    <Typography sx={{fontSize: '14px', color: '#d7d7d7', fontWeight: 700, textTransform: 'uppercase', textDecoration: 'line-through'}}>
                      {`${formatCurrency(totalPrice)}`}
                    </Typography>
                  </Grid>
                </Grid>}
              <Grid item container xs={12} display={'flex'}>
                <Grid item container xs={6} justifyContent={'flex-start'}>
                  <Typography sx={{fontSize: '14px', color: '#131C3E', fontWeight: 700, textTransform: 'uppercase'}}>
                    {props.t('bundledetailspage:bs_action_panel_total_price')}
                  </Typography>
                </Grid>
                <Grid item container xs={6} justifyContent={'flex-end'}>
                  <Typography sx={{fontSize: '14px', color: '#131C3E', fontWeight: 700, textTransform: 'uppercase'}}>
                    {`${formatCurrency((props.bundle.statusKey === 'counter_offer_requested' && counterOfferPrices.filter(x => x.counterOfferPrice).length) ? totalNewPriceWithCounterOffer : counterOfferPrice || totalPrice)}`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <ActionPanelModals
              status={bundle.statusKey}
              updateBundlePage={props.updateBundlePage}
              bundleId={bundle.id}
              data={bundle}
              deliveryDate={bundle.deliveryDate}
              totalPrice={bundle.price}
              offeredTotalPrice={bundle.counterOfferPrice}
              offeredDeliveryDate={bundle.counterOfferDate}
              qms={props.qms}
              t={props.t}
            />
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

