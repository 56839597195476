import React from 'react';
import {useTranslation} from 'react-i18next';
import {logEvent} from '../../../utils/firebase';
import {Divider, Grid, IconButton, Tooltip, Typography} from '@mui/material';
import {Folder, List, Description, Send, Language, TableRows} from '@mui/icons-material';
import {downloadAttachment, downloadBundleAttachment, downloadDhlCsv, downloadOpenBookDataCsv, downloadProjectFiles} from '../../../services/kooper';
import {palette} from '../../../styles/theme/palette';

interface Props {
  setPage: Function,
  bundleId: number,
  loading: boolean,
  status: string,
  hasOrderConfirmationUploaded: boolean,
  supplier: any
}

export const DownloadModalMainPage = (props: Props) => {
  const {t} = useTranslation(['modals']);

  const isStatusBeforeAccepted = [
    'lost',
    'open',
    'rejected',
    'counter_offer_accepted',
    'counter_offer_requested',
    'counter_offer_submitted',
    'first_round',
    'second_round',
  ].includes(props.status);

  const isStatusBeforeShipped = !isStatusBeforeAccepted && !['partially_shipped', 'shipped'].includes(props.status);

  const isNonEuSupplier = [
    'China suppliers',
    'Asia',
    'South America',
  ].includes(props.supplier.supplierGroup);

  const buttons = {
    manufacturing: [
      {
        key: 'drawings_and_cad',
        icon: <Folder fontSize={'large'} />,
        action: () => downloadProjectFiles(props.bundleId),
        disabled: false,
      },
      {
        key: 'times_and_cost',
        icon: <List fontSize={'large'} />,
        action: () => downloadOpenBookDataCsv(props.bundleId),
        disabled: false,
      },
    ],
    kreatize_documents: [
      {
        key: 'order',
        icon: <Description fontSize={'large'} />,
        action: () => downloadAttachment(props.bundleId, 'order_confirmation'),
        disabled: isStatusBeforeAccepted,
      },
      {
        key: 'delivery_slip',
        icon: <Send fontSize={'large'} />,
        action: () => downloadAttachment(props.bundleId, 'delivery_slip'),
        disabled: isStatusBeforeShipped,
      },
      {
        key: 'dhl_csv',
        icon: <TableRows fontSize={'large'} />,
        action: () => downloadDhlCsv(props.bundleId),
        disabled: isStatusBeforeAccepted,
      },
      isNonEuSupplier &&
      {
        key: 'customs',
        icon: <Language fontSize={'large'} />,
        action: () => props.setPage('customs'),
        disabled: !['shipped', 'partially_delivered', 'delivered', 'invoice_paid', 'ordered', 'delayed'].includes(props.status),
      },
    ],
    supplier_documents: [
      {
        key: 'order_confirmation',
        icon: <Description fontSize={'large'} />,
        action: () => downloadBundleAttachment(props.bundleId, 'order_confirmation'),
        disabled: !props.hasOrderConfirmationUploaded,
      },
    ],
  };

  const rows = Object.keys(buttons);
  const maxColumns = Object.values(buttons).reduce((acc: number, curr: any) => {
    const filteredCurr = curr.filter(x => x !== false);
    if (filteredCurr.length > acc) {
      return filteredCurr.length;
    }
    return acc;
  }, 0);

  return (
    <Grid container height={'100%'}>
      {Object.entries(buttons).map(([key, buttons]) => {
        const filteredButtons: any = buttons.filter(x => x !== false);
        return (
          <>
            <Grid item xs={12} display={'flex'} justifyContent={'center'}>
              <Typography typography={'h6'}>{t(`download_modal_${key}`)}</Typography>
            </Grid>
            <Grid item container xs={12}>
              {filteredButtons.map(button => {
                return (
                  <Grid item xs={12 / maxColumns} key={button.key} display={'flex'} justifyContent={'center'}>
                    <Tooltip
                      title={t(button.disabled ? `${key}_${button.key}_tooltip_disabled` : `${key}_${button.key}_tooltip`)}
                      componentsProps={{
                        tooltip: {sx: {backgroundColor: button.disabled ? palette.error.main : palette.primary.main}},
                        arrow: {sx: {color: button.disabled ? palette.error.main : palette.primary.main}},
                      }}
                      arrow={true}
                    >
                      <span>
                        <IconButton
                          disabled={button.disabled}
                          aria-label={button.key}
                          style={{width: 120, height: 120}}
                          onClick={() => {
                            button.action();
                            logEvent(`download_${button.key}`);
                          }}
                          size={'large'}
                        >
                          <div>
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                              <div style={{width: 60, height: 60, backgroundColor: '#32bee1', borderRadius: 30, display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#FFF'}}>
                                {button.icon}
                              </div>
                            </div>

                            <Typography typography={'body'} color={button.disabled && '#bdbdbd'}>
                              {t(`download_modal_${button.key}`)}
                            </Typography>

                          </div>
                        </IconButton>
                        </span>
                    </Tooltip>
                  </Grid>
                );
              })}
            </Grid>

            {!(rows.length - 1 === rows.findIndex(x => x === key)) &&
              <Grid item xs={12}>
                <Divider />
              </Grid>
            }
          </>
        );
      })}
    </Grid>
  );
};
