import React, {Component} from 'react';
import AsyncSelect from 'react-select/async';
import {components} from 'react-select';
import SearchIcon from '@mui/icons-material/Search';
import i18n from 'i18next';


const t = (key) => i18n.t(key);

interface Props {
  getOptions: Function;
  redirect: Function;
}

class Search extends Component <Props, any> {

  getOptions = async (value) => {
    if (value.length > 1) {
      return this.props.getOptions(value);
    } else {
      return Promise.resolve([]);
    }
  };

  render() {
    const DropdownIndicator = props => {
      return (
        components.DropdownIndicator && (
          <components.DropdownIndicator {...props}>
            <SearchIcon />
          </components.DropdownIndicator>
        )
      );
    };
    const Option = props => {
      return (
        <div
          className={'search-option'}
          onClick={() => {
            this.props.redirect(props);
            props.clearValue();
          }}
        >
          <components.Option {...props} />
        </div>
      );
    };
    return (
      <div>
        <AsyncSelect
          className='custom-search'
          cacheOptions
          placeholder={t('common:cmn_search')}
          loadOptions={this.getOptions}
          components={{DropdownIndicator, Option}}
          defaultOptions
          noOptionsMessage={() => t('common:cmn_no_results_found')}
          onChange={(type, {action}) => {
            if (action === 'select-option') {
              this.props.redirect({value: type.value});
            }
          }}
        />
      </div>
    );
  }
};

export default Search;