import React, {Component} from 'react';
import {DropzoneArea} from 'mui-file-dropzone';
import {uploadFile} from '../../../services/kooper';
import {logEvent} from '../../../utils/firebase';
import {BaseModalStage, MuiBaseModal} from '../MuiBaseModal';
import {Button, Grid} from '@mui/material';


interface Props {
  bundleId: number,
  updateBundlePage: Function,
  t: Function
}

interface State {
  open: boolean,
  currentFile: any,
  stage: BaseModalStage
  dense: boolean,
}

export class UploadModal extends Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      open: false,
      currentFile: null,
      stage: 'content',
      dense: false,
    };
  }

  handleSubmit = () => {
    this.setState({stage: 'loading'});
    const form = new FormData();
    form.append('file', this.state.currentFile);
    uploadFile(this.props.bundleId, 'order_confirmation', form)
      .then(() => {
        logEvent('order_confirmation_uploaded');
        this.setState({stage: 'success'});
      })
      .catch(() => this.setState({stage: 'failure'}));
  };

  handleToggle = (state?: boolean) => {
    if (typeof state === 'boolean') {
      this.setState({
        open: state,
        stage: 'content',
      });
    } else {
      this.setState({
        open: !this.state.open,
        stage: 'content',
      });
    }
    if (this.state.open || state === false) {
      this.props.updateBundlePage();
    }
  };

  content = () => {
    const {t} = this.props;
    return (
      <React.Fragment>
        <DropzoneArea
          acceptedFiles={['application/pdf']}
          dropzoneText={t('modals:upload_modal_dropzone_text')}
          onChange={(files) => this.setState({currentFile: files[0]})}
          filesLimit={1}
          showPreviews
          showPreviewsInDropzone={false}
          showFileNames
          previewText={'.'}
          useChipsForPreview
          showAlerts={false}
          maxFileSize={25000000}
          fileObjects={null}
          onDelete={() => {}}
        />
      </React.Fragment>
    );
  };

  footer = () => {
    const isDisabled = !this.state.currentFile;

    return (
      <Grid item xs={12} justifyContent='center' alignItems='flex-end' height={'100%'} paddingX={10}>
        <Button
          fullWidth
          onClick={() => this.handleSubmit()}
          disabled={isDisabled}
          sx={{'&.MuiButton-root:disabled': {backgroundColor: '#00AFD345', color: '#FFF'}}}
        >
          {this.props.t('modals:upload_modal_upload_button')}
        </Button>
      </Grid>
    );
  };

  render() {
    const {t} = this.props;
    return (
      <MuiBaseModal
        stage={this.state.stage}
        button={
          <Button
            onClick={() => this.setState({open: true})}
            size={'small'}
            fullWidth
          >
            {t('bundledetailspage:bs_action_panel_button_upload_order_confirmation')}
          </Button>
        }
        onToggle={() => this.handleToggle()}
        open={this.state.open}
        content={this.content()}
        footer={this.footer()}
        height={'80vh'}
        successMessageTitle={t('modals:upload_modal_success_message')}
        errorMessageTitle={t('modals:upload_modal_failure_message')}
        header={t('modals:upload_modal_title')}
      />
    );
  }
}

