import {Card, Grid, Typography} from '@mui/material';


type Props = {
  title: string,
  subtitle: string,
  icon: any
}
export const AuctionPackageDetailsCard = (props: Props) => {
  return <Card sx={{width: '200px', height: '60px', padding: '10px'}}>
    <Grid container height={'100%'} alignItems={'center'}>
      <Grid item xs={3}>
        <img src={props.icon} style={{width: '24px', height: '24px', color: 'red'}} />
      </Grid>
      <Grid item xs={9}>
        <Typography color={'primary'} variant={'h3'} sx={{fontWeight: 800, fontSize: '12px'}}>
          {props.title.toUpperCase()}
        </Typography>
        <Typography color={'black'} sx={{fontWeight: 600, fontSize: '14px'}}>
          {props.subtitle.toUpperCase()}
        </Typography>
      </Grid>
    </Grid>
  </Card>;
};