import React, {Component} from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import {Button, Card, Grid, Tooltip, Typography} from '@mui/material';
import {DataGridPro} from '@mui/x-data-grid-pro';
import {getInvoiceData} from '../../services/kooper';
import {formatCurrency, formatDate} from '../../utils/formatHelpers';
import {NavLink} from 'react-router-dom';
import {UploadInvoiceModal} from '../../components/Modals/UploadInvoiceModal/UploadInvoiceModal';
import {Help} from '@mui/icons-material';

type State = {
  bundleOrderedPartConfigs: any[],
  loading: boolean,
  selectedBundleOrderedPartConfigIds: any[],
  uploadInvoiceModalOpen: boolean,
}

type Props = WithTranslation & {}

class Invoicing extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      bundleOrderedPartConfigs: [],
      loading: false,
      selectedBundleOrderedPartConfigIds: [],
      uploadInvoiceModalOpen: false,
    };
  }

  loadInvoiceData = async () => {
    this.setState({loading: true});

    try {
      // Get Invoicing Data for the current supplier
      const {data: bundleOrderedPartConfigs} = await getInvoiceData();
      this.setState({
        bundleOrderedPartConfigs,
        loading: false,
      });
    } catch (err) {
      console.error(err);
    }
  };

  async componentDidMount() {
    await this.loadInvoiceData();
  }

  handleUploadInvoiceModalClose = () => {
    this.setState({uploadInvoiceModalOpen: false}, this.loadInvoiceData);
  };

  render() {
    const {t} = this.props;

    return <Grid container height={'100%'} pl={2.5} pr={6.25} alignContent={'flex-start'}>
      <Grid item xs={12} sx={{height: 50}} mt={3.75} mb={2.5} container>
        <Grid item container xs={6} alignItems={'center'}>
          <Grid item xs={12}>
            <Typography color={'primary'} variant={'h3'} sx={{fontWeight: 700, fontSize: '14px'}}>
              {t('invoicing_dashboard').toUpperCase()}
            </Typography>
          </Grid>
          <Grid item xs={12} container spacing={1} alignItems={'center'}>
            <Grid item xs={'auto'}>
              <Typography variant={'h3'} sx={{color: '#131C3E'}}>
                {t('invoicing_dashboard_title')}
              </Typography>
            </Grid>
            <Grid item>
              <Tooltip title={t('invoicing_dashboard_description')} arrow>
                <Help />
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} alignItems={'center'} justifyContent={'flex-end'} container>
          <Grid item xs={'auto'}>
            <Button
              disabled={this.state.selectedBundleOrderedPartConfigIds.length === 0}
              variant={'contained'}
              color={'primary'}
              onClick={() => this.setState({uploadInvoiceModalOpen: true})}
            >
              {t('upload_invoices_for_selected_parts')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container xs={12} sx={{height: 'calc(100vh - 200px) !important'}} alignContent={'flex-start'}>
        <Card style={{padding: '20px 25px', height: 'calc(100vh - 200px)', width: '100%'}}>
          <DataGridPro
            rows={this.state.bundleOrderedPartConfigs}
            columns={[
              {field: 'bundleId', headerName: t('table_header_bundle_id'), flex: 1, renderCell: ({row}) => <NavLink to={`/bundle-suppliers/${row.bundleId}`}>{`KAKTUS-${row.bundleId}`}</NavLink>},
              {field: 'orderedPartConfig.orderedPart.name', headerName: t('table_header_part_name'), flex: 1, valueGetter: ({row}) => row.orderedPartConfig.orderedPart.name},
              {field: 'orderedPartConfig.orderedPart.drawingNr', headerName: t('table_header_part_drawing_nr'), flex: 1, valueGetter: ({row}) => row.orderedPartConfig.orderedPart.drawingNr},
              {field: 'pricePerPiece', headerName: t('table_header_price_per_piece'), flex: 1, valueFormatter: ({value}) => formatCurrency(value), valueGetter: ({row}) => row.counterOfferPrice || row.pricePerPiece},
              {field: 'orderedPartConfig.batchSize', headerName: t('table_header_quantity'), flex: 0.5, valueGetter: ({row}) => row.orderedPartConfig.batchSize},
              {field: 'totalPrice', headerName: t('table_header_total_price'), flex: 1, valueFormatter: ({value}) => formatCurrency(value), valueGetter: ({row}) => (row.counterOfferPrice || row.pricePerPiece) * row.orderedPartConfig.batchSize},
              {field: 'deliveryDate', headerName: t('table_header_delivery_date'), flex: 1, valueFormatter: ({value}) => formatDate(value)},
            ]}
            loading={this.state.loading}
            hideFooter
            checkboxSelection
            selectionModel={this.state.selectedBundleOrderedPartConfigIds}
            onSelectionModelChange={(selectionModel) => this.setState({selectedBundleOrderedPartConfigIds: selectionModel})}
            sx={{
              '.MuiDataGrid-row': {backgroundColor: 'white', marginBottom: '6px'},
              '.MuiDataGrid-row:hover': {
                boxShadow: '3px 3px 2px rgb(220,220,220)',
                borderStyle: 'solid',
                borderWidth: '0.5px',
                borderColor: '#DBDBDB',
                backgroundColor: 'white',
              },
              '.MuiDataGrid-columnHeaders': {borderBottom: 'none'},
            }}
          />
        </Card>
        {/*  Modal for uploading invoice for the selected parts */}
        <UploadInvoiceModal
          open={this.state.uploadInvoiceModalOpen}
          selectedBundleOrderedPartConfigIds={this.state.selectedBundleOrderedPartConfigIds}
          bundleOrderedPartConfigs={this.state.bundleOrderedPartConfigs}
          t={t}
          handleClose={this.handleUploadInvoiceModalClose}
        />
      </Grid>
    </Grid>;
  }
}

export default withTranslation(['invoicing'])(Invoicing);
