import React, {useState} from 'react';
import {DownloadModalMainPage} from './DownloadModalMainPage';
import {DownloadModalCustomsDetails} from './DownloadModalCustomsDetails';
import {Button} from '@mui/material';
import {MuiBaseModal} from '../MuiBaseModal';


interface Props {
  bundleId: number,
  loading: boolean,
  status: string,
  hasOrderConfirmationUploaded: boolean,
  supplier: any
  t: Function,
}

const DownloadModal = (props: Props) => {

  const [open, setOpen] = useState(false);
  const [page, setPage] = useState('overview');
  const handleToggle = () => {
    setOpen(!open);
    setPage('overview');
  };

  const content = () => {
    switch (page) {
      case 'overview':
        return <DownloadModalMainPage setPage={setPage} {...props} />;
      case 'customs':
        return <DownloadModalCustomsDetails {...props} />;

      default:
        return <></>;
    }
  };

  return (
    <MuiBaseModal
      stage={'content'}
      button={
        <Button
          disabled={['rejected', 'lost_but_would_have_rejected'].includes(props.status)}
          onClick={() => setOpen(true)}
          variant='outlined'
          color={'secondary'}
          fullWidth
        >
          {props.t('bundledetailspage:bs_button_download')}
        </Button>
      }
      open={open}
      onToggle={handleToggle}
      content={content()}
      height={'80vh'}
    />
  );
};

export default DownloadModal;
