import React, {useEffect, useState} from 'react';
import {downloadProjectFiles, getAuctionBundleById, upsertAuctionBid} from '../../services/kooper';
import {Box, Grid, Typography, Button, Tooltip} from '@mui/material';
import {formatDate} from '../../utils/formatHelpers';
import {MessageModal} from '../../components/Modals/MessageModal/MessageModal';
import CalendarIcon from '../../assets/icons/calendar.svg';
import MachineIcon from '../../assets/icons/blueMachine.svg';
import CountDownIcon from '../../assets/icons/countdown.svg';
import {AuctionPackageDetailsCard} from './AuctionPackageDetails.Card';
import {AuctionPackageDetailsTable} from './AuctionPackageDetails.Table';
import {SubmitAuctionBidModal} from '../../components/Modals/SubmitAuctionBidModal/SubmitAuctionBidModal';
import {formatToRelativeDateDifference} from '../../utils/datetimeHelpers';
import moment from 'moment';
import {AuctionPackageStatus} from '../../components/CombinatorialAuctionsPage/AuctionPackageStatus';
import {WelcomeToAuctionModal} from '../../components/Modals/WelcomeToAuctionModal/WelcomeToAuctionModal';
import kaktusLoader from '../../assets/icons/kaktusLoader.svg';
import {FolderZip, TableView} from '@mui/icons-material';
import {useGridApiRef} from '@mui/x-data-grid-pro';

type Props = {
  t: Function,
  navigate: Function,
}

export const AuctionPackageDetails = (props: Props) => {
  const [auctionBundle, setAuctionBundle] = useState<any>();
  const [initalLoading, setInitialLoading] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [countdown, setCountdown] = useState<any>('waiting');

  const apiRef = useGridApiRef();

  useEffect(() => {
    getAuctionBundle().then((res) => {
      const interval = setInterval(() => {
        setCountdown(formatToRelativeDateDifference(
          moment(res.auctionTeaser.createdAt)
            .add('hours', res.auctionTeaser.statusKey === 'first_round' ? 48 : 72)
            .toString()));
      }, 1000);
      return () => clearInterval(interval);
    });
  }, []);

  const getAuctionBundle = async () => {
    setLoading(true);
    const bundleId = Number.parseInt(window.location.pathname.split('/')[2]);
    const res = await getAuctionBundleById(bundleId);
    setAuctionBundle(res);
    setCountdown(formatToRelativeDateDifference(moment(res.auctionTeaser.createdAt).add('hours', ['first_round'].includes(res.auctionTeaser.statusKey) ? 48 : 72).toString()));
    setInitialLoading(false);
    setLoading(false);
    return res;
  };

  const handlePriceInput = async (bopcId, value: string) => {
    await upsertAuctionBid(auctionBundle.id, bopcId, value === '' ? null : value);
    await getAuctionBundle();
  };

  if (initalLoading) {
    return <div style={{width: '100%', height: '85%', display: 'flex', justifyContent: 'center', alignItems: 'center '}}>
      <div className={'lds-eclipse'}>
        <img width={200} src={kaktusLoader} alt={'loader'} />
        <div />
      </div>
    </div>;
  }

  return (<>
      {!auctionBundle.seenAt ? <WelcomeToAuctionModal auctionBundle={auctionBundle} handleUpdate={getAuctionBundle} t={props.t} /> : <></>}
      <Grid container key={'auction-package-container'} sx={{paddingRight: '50px', paddingLeft: '20px'}}>
        <Grid item container xs={12} key={'title'} sx={{marginY: '40px'}}>
          <Grid item xs={12}>
            <Typography color={'primary'} variant={'h3'} sx={{fontWeight: 700, fontSize: '14px'}}>
              {`${props.t('auctions:combi_auction_auction_id').toUpperCase()} ${auctionBundle.auctionId}`}
            </Typography>
          </Grid>
          <Grid item container xs={12} alignItems={'center'}>
            <Grid item container xs={12}>
              <Grid item alignContent={'center'}>
                <Typography variant={'h3'} sx={{color: '#131C3E'}}>
                  {`${props.t('auctions:auction_package')} ${auctionBundle.id}`}
                </Typography>
              </Grid>
              <Grid item alignSelf={'center'}>
                <AuctionPackageStatus statusKey={auctionBundle.statusKey} t={props.t} previouslySeen={!!auctionBundle.seenAt}></AuctionPackageStatus>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container xs={6} key={'buttons-and-info-cells'} marginBottom={'20px'} gap={'10px'}>
          <AuctionPackageDetailsCard
            title={props.t('auctions:combi_auction_card_delivery_date')}
            subtitle={formatDate(auctionBundle.deliveryDate)}
            icon={CalendarIcon}
          />
          <AuctionPackageDetailsCard
            title={props.t('auctions:combi_auction_card_total_positions')}
            subtitle={auctionBundle.numberOfPartConfigs}
            icon={MachineIcon}
          />
          {['first_round', 'second_round'].includes(auctionBundle?.auctionTeaser?.statusKey) &&
            <AuctionPackageDetailsCard
              title={props.t('auctions:combi_auction_card_countdown')}
              subtitle={auctionBundle?.auctionTeaser ? `${countdown.days}D ${countdown.hours}H ${countdown.minutes}M ${countdown.secs}S` : '-'}
              icon={CountDownIcon}
            />
          }
        </Grid>
        <Grid item container xs={6} justifyContent={'flex-end'} alignContent={'center'} marginBottom={'20px'} gap={'10px'}>
          <Box display={'flex'} alignContent={'center'}>
            <Box width={'190px'} marginRight={'10px'}>
              <Tooltip title={props.t('auctions:auction_package_download_csv_tooltip')}>
                <Button
                  onClick={() => apiRef.current.exportDataAsCsv({fileName: `${props.t('auctions:auction_package')} ${auctionBundle.id}`})}
                  variant={'outlined'}
                  color={'secondary'}
                  fullWidth
                >
                  {props.t('auctions:auction_package_download_csv')}&nbsp;<TableView fontSize={'small'} />
                </Button>
              </Tooltip>
            </Box>
            <Box width={'150px'} marginRight={'10px'}>
              <Tooltip title={props.t('auctions:auction_package_download_files_tooltip')}>
                <Button
                  onClick={() => downloadProjectFiles(auctionBundle?.id)}
                  variant={'outlined'}
                  color={'secondary'}
                  fullWidth
                >
                  {props.t('bundledetailspage:bs_button_download')}&nbsp;<FolderZip fontSize={'small'} />
                </Button>
              </Tooltip>
            </Box>
            <Box width={'150px'}>
              <MessageModal t={props.t} bundleId={auctionBundle.id} />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sx={{height: 'calc(100vh - 360px)'}}>
          <AuctionPackageDetailsTable
            t={props.t}
            auctionBundleOrderedPartConfigTeasers={auctionBundle?.auctionBundleOrderedPartConfigTeasers}
            bundleKid={auctionBundle?.bundleKid}
            loading={loading}
            handlePriceInput={handlePriceInput}
            totalFirstRoundPrice={auctionBundle.firstRoundPrice}
            totalSecondRoundPrice={auctionBundle.secondRoundPrice}
            statusKey={auctionBundle.statusKey}
            apiRef={apiRef}
          />
          {['first_round', 'second_round'].includes(auctionBundle.statusKey) &&
            <Box display={'flex'} justifyContent={'flex-end'} paddingY={2}>
              <SubmitAuctionBidModal auctionBundle={auctionBundle} handleUpdate={getAuctionBundle} t={props.t} />
            </Box>
          }
        </Grid>
      </Grid>
    </>
  );
};