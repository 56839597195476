import React from 'react';
import {updateBundleStatus} from '../../../services/kooper';
import {Button, Grid, Checkbox, FormControlLabel} from '@mui/material';
import {BaseModalStage, MuiBaseModal} from '../MuiBaseModal';
import {formatCurrency, formatDate} from '../../../utils/formatHelpers';
import CheckIcon from '@mui/icons-material/Check';
import Typography from '@mui/material/Typography';
import {InfoIcon} from '../../AnimatedIcons/AnimatedIcons';

interface Props {
  status: string
  bundleId: number,
  deliveryDate: string,
  totalPrice: number,
  offeredTotalPrice: number,
  offeredDeliveryDate: string,
  updateBundlePage: Function,
  t: Function,
  buttonDisabled?: boolean,
}

interface State {
  open: boolean,
  stage: BaseModalStage
  checkboxes: { key: string, checked: boolean }[]
}

export class AcceptModal extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      open: false,
      stage: 'content',
      checkboxes: [{key: 'price', checked: false}, {key: 'deliveryDate', checked: false}, {key: 'files', checked: false}, {key: 'comments', checked: false}],
    };
  }

  handleAccept = () => {
    this.setState({stage: 'loading'});
    updateBundleStatus(this.props.bundleId, 'ordered')
      .then(() => {
        this.setState({stage: 'success'});
      })
      .catch(() => this.setState({stage: 'failure'}));
  };

  handleChange = (checkbox: any) => {
    const x = this.state.checkboxes.map((x: any) => {
      if (x.key === checkbox.key) {
        x.checked = !x.checked;
      }
      return x;
    });
    this.setState({checkboxes: x});
  };

  handleToggle = () => {
    if ((this.state.open && this.state.stage === 'success') || (this.state.open && this.props.status === 'lost')) {
      this.props.updateBundlePage();
    }
    this.setState({
      stage: 'content',
      open: !this.state.open,
      checkboxes: this.state.checkboxes.map((x: any) => ({key: x.key, checked: false})),
    });
  };

  content = () => {
    const {props} = this;
    if (this.props.status !== 'lost') {
      return (
        <Grid item container xs={12} alignItems={'flex-start'} rowSpacing={2}>
          <Grid item xs={12}>
            <Typography typography={'body'}>{props.t('modals:accept_modal_body')}</Typography>
          </Grid>
          <Grid item container xs={12}>
            {this.state.checkboxes.map((checkbox: any) => {
                let label = props.t(`modals:accept_modal_${checkbox.key.toLowerCase()}`);
                if (checkbox.key === 'price') {
                  label = `${label}: ${formatCurrency(props.offeredTotalPrice || props.totalPrice)}`;
                }
                if (checkbox.key === 'deliveryDate') {
                  label = `${label}: ${formatDate(props.offeredDeliveryDate || props.deliveryDate)}`;
                }
                return (
                  <Grid item xs={12} key={checkbox.key}>
                    <FormControlLabel
                      control={<Checkbox checked={checkbox.checked} onChange={() => {this.handleChange(checkbox);}} />}
                      label={label}
                    />
                  </Grid>
                );
              },
            )}
          </Grid>
        </Grid>
      );
    }
    return (
      <Grid container justifyContent={'center'} height={'calc(100% - 60px)'}>
        <Grid item xs={12} justifyContent={'center'} display={'flex'} alignItems={'end'}>
          <InfoIcon />
        </Grid>
        <Grid item xs={12}>
          <Grid item xs={12} justifyContent={'center'} display={'flex'}>
            <Typography typography={'h6'}>
              {props.t('modals:accept_modal_lost_message_title')}
            </Typography>
          </Grid>
          <Grid item xs={12} justifyContent={'center'} display={'flex'}>
            <Typography typography={'body'}>
              {props.t('modals:accept_modal_lost_message_subtitle')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  footer = () => {
    if (this.props.status !== 'lost') {
      return (
        <Grid item xs={12} container justifyContent="flex-end" alignItems="flex-end" height={'100%'}>
          <Grid item xs={6} />
          <Grid item xs={6} style={{justifyContent: 'flex-end', display: 'flex', paddingRight: 20, paddingBottom: 20}}>
            <Button
              onClick={() => this.handleAccept()}
              variant={'contained'}
              color={'info'}
              disabled={this.state.checkboxes.filter((checkbox: any) => !checkbox.checked).length > 0}
            >
              {this.props.t('modals:accept_modal_accept_button')}
              <CheckIcon fontSize="small" style={{marginLeft: 10}} />
            </Button>
          </Grid>
        </Grid>
      );
    }
    return <></>;

  };

  render() {

    return (
      <MuiBaseModal
        stage={this.state.stage}
        button={
          <Button
            disabled={this.props.buttonDisabled}
            onClick={() => {
              this.setState({open: true});
              {this.props.status === 'lost' ? updateBundleStatus(this.props.bundleId, 'lost_but_would_have_accepted').catch(console.error) : '';}
            }}
            fullWidth
            sx={{'&.MuiButton-root:disabled': {backgroundColor: '#00AFD345', color: '#FFF'}}}
          >
            {this.props.t('modals:accept_modal_accept_button')}
          </Button>
        }
        open={this.state.open}
        onToggle={() => this.handleToggle()}
        content={this.content()}
        loadingMessageTitle={this.props.t('modals:accept_modal_loading_message')}
        successMessageTitle={this.props.t('modals:accept_modal_success_message')}
        errorMessageTitle={this.props.t('modals:accept_modal_failure_message')}
        header={this.props.t('modals:accept_modal_title')}
        height={'60vh'}
        width={'750px'}
        footer={this.state.stage === 'content' ? this.footer() : undefined}
      />
    );
  }
}
