import React, {Component} from 'react';
import {Col, Container, Row} from 'shards-react';
import PageTitle from 'components/Common/PageTitle';
import SmallStats from 'components/Common/SmallStats';
import {withTranslation} from 'react-i18next';
import {getDashboardData} from '../../services/kooper';


interface State {
  loading: boolean,

  open: number | null,
  counter_offer: number | null,
  accepted: number | null,
  ordered: number | null,
  delayed: number | null,
  complaint: number | null,
}

interface Props {
  t: Function,
  history: Function,
  navigate: Function
}

export class Dashboard extends Component <Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      loading: true,

      open: null,
      counter_offer: null,
      accepted: null,
      ordered: null,
      delayed: null,
      complaint: null,

    };
  }

  componentDidMount() {
    getDashboardData({statusKey: 'open'}).then((res: number) => this.setState({open: res}));
    getDashboardData({statusKey: 'ordered'}).then((res: number) => this.setState({ordered: res}));
    getDashboardData({statusKey: 'delayed'}).then((res: number) => this.setState({delayed: res}));
    getDashboardData({statusKey: 'complaint'}).then((res: number) => this.setState({complaint: res}));
    getDashboardData({AND: [{statusKey: {in: ['ordered', 'delayed']}}, {hasOrderConfirmation: false}]}).then((res: number) => this.setState({accepted: res}));
    getDashboardData({statusKey: {in: ['counter_offer_requested', 'counter_offer_submitted', 'counter_offer_accepted']}})
      .then((res: number) => this.setState({counter_offer: res}));
  }

  render() {
    const {t} = this.props;
    return (
      <div>
        <Container fluid className='main-content-container px-4'>
          <Row noGutters className='page-header py-4'>
            <PageTitle
              title={t('dashboard:page_title')}
              subtitle='Dashboard'
              className='text-sm-left mb-3'
            />
          </Row>

          <Row noGutters className='page-header py-4'>
            <PageTitle title={t('dashboard:requests_title')} subtitle='' className='text-sm-left mb-3' />
          </Row>
          <Row>
            <Col className='col-lg mb-4' md='6' sm='6'>
              <SmallStats
                navigate={this.props.navigate}
                label={t('route_bundles_open')}
                value={this.state.open}
                redirect='available'
                buttonTitle={t('dashboard:button_to_list')}
                loading={this.state.open === null}
                h='1'
              />
            </Col>

            <Col className='col-lg mb-4' md='6' sm='6'>
              <SmallStats
                navigate={this.props.navigate}
                label={t('route_bundles_counteroffer')}
                value={this.state.counter_offer}
                redirect='counter-offers'
                buttonTitle={t('dashboard:button_to_list')}
                loading={this.state.counter_offer === null}
              />
            </Col>
          </Row>

          <Row noGutters className='page-header py-4'>
            <PageTitle title={t('dashboard:orders_title')} subtitle={''} className='text-sm-left mb-3' />
          </Row>
          <Row>
            <Col className='col-lg mb-4' md='6' sm='6'>
              <SmallStats
                navigate={this.props.navigate}
                label={t('dashboard:bundles_by_status_waiting_for_oc')}
                value={this.state.accepted}
                redirect='in-production?statuses=[ordered,delayed]&filter=[hasOrderConfirmation=false]'
                buttonTitle={t('dashboard:button_to_list')}
                loading={this.state.accepted === null}
              />
            </Col>
            <Col className='col-lg mb-4' md='6' sm='6'>
              <SmallStats
                navigate={this.props.navigate}
                label={t('dashboard:bundles_by_status_ordered')}
                value={this.state.ordered}
                redirect='in-production?statuses=[ordered]'
                buttonTitle={t('dashboard:button_to_list')}
                loading={this.state.ordered === null}
              />
            </Col>
            <Col className='col-lg mb-4' md='6' sm='6'>
              <SmallStats
                navigate={this.props.navigate}
                label={t('dashboard:bundles_by_status_delayed')}
                value={this.state.delayed}
                redirect='in-production?statuses=[delayed]'
                buttonTitle={t('dashboard:button_to_list')}
                loading={this.state.delayed === null}
              />
            </Col>
            <Col className='col-lg mb-4' md='6' sm='6'>
              <SmallStats
                navigate={this.props.navigate}
                label={t('dashboard:bundles_by_status_complaint')}
                value={this.state.complaint}
                redirect='complaints'
                buttonTitle={t('dashboard:button_to_list')}
                loading={this.state.complaint === null}
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

// @ts-ignore
export default withTranslation(['sidebar', 'dashboard'])(Dashboard);
