import {Chip, Grid} from '@mui/material';

type Props = {
  statusKey: string,
  t: Function,
  previouslySeen: boolean,
}
export const AuctionPackageStatus = (props: Props) => {
  let color = 'primary';
  let text = props.statusKey;
  let variant = 'outlined';

  switch (props.statusKey) {
    case('auction_rejected'):
      color = 'error';
      break;

    case('won'):
      color = 'success';
      variant = 'filled';
      break;

    case('first_round_submitted'):
    case('second_round_submitted'):
      color = 'success';
      variant = 'filled';
      break;

    case('lost'):
      color = 'secondary';
      break;

    case('second_round'):
    case('first_round'):
      variant = 'filled';
      color = 'warning';
      break;

    default:
      break;
  }

  return (
    <>
      <Grid item sx={{minWidth: 20}}>
        <Chip
          color={color as any}
          variant={variant as any}
          sx={{
            '&.MuiChip-root': {height: 25, minWidth: 20},
            '.MuiChip-label': {paddingY: '10px', fontWeight: 400, fontSize: '12px'},
            border: `1px solid ${color}`,
            marginLeft: '10px',

          }}
          label={props.t(`auctions:auction_package_status_${(text)}`).toUpperCase()}
        />
      </Grid>
      {(props.previouslySeen && props.statusKey === 'first_round') || props.statusKey === 'second_round' && <Grid item sx={{minWidth: 20}}>
        <Chip
          color={'primary'}
          variant={'outlined'}
          sx={{
            '&.MuiChip-root': {height: 25, minWidth: 20},
            '.MuiChip-label': {paddingY: '10px', fontWeight: 400, fontSize: '12px'},
            border: `1px solid ${color}`,
            marginLeft: '10px',
          }}
          label={props.t(`auctions:auction_package_status_incomplete`).toUpperCase()}
        />
      </Grid>}
    </>
  );
};