import React, {Component} from 'react';
import {Card, IconButton, Box, Tab, Tabs, Chip, Grid, Tooltip, Skeleton} from '@mui/material';
import ArrowCircleRightSharpIcon from '@mui/icons-material/ArrowCircleRightSharp';
import {DataGridPro} from '@mui/x-data-grid-pro';
import {formatDate, formatCurrency} from 'utils/formatHelpers';
import BoxLogo from 'assets/img/kaktus.svg';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {logEvent} from '../../utils/firebase';
import {getFilteredBundles, getReorderedPartsPerBundle} from '../../services/kooper';

/* Type definitions */

interface State {
  loading: boolean,
  pagination: {
    currentPage: number,
    totalItemCount: number,
    totalPageCount: number,
    itemsPerPage: number,
  },
  currentTab: 'recommended' | 'all',
  data: any,
  header: Array<String>,
  reorderedParts: any[]
}

type Props = {
  statuses: any,
  t: Function,
  navigate: Function
}

/* Component */

export class ProductionRequestsTable extends Component <Props, State> {

  constructor(props: any) {
    super(props);
    this.state = {
      loading: true,
      pagination: {
        currentPage: 0,
        totalItemCount: 0,
        totalPageCount: 0,
        itemsPerPage: 0,
      },
      currentTab: 'recommended',
      data: [],
      header: [],
      reorderedParts: [],
    };
  }

  getBundles = async (page, limit = 25) => {
    const res = await getFilteredBundles(page, limit, this.props.statuses);
    const bundles = res.page;
    this.setState({pagination: res.pagination, loading: false});
    getReorderedPartsPerBundle(bundles.map(bundle => bundle.id)).then((response) => {
      const newReorderedParts = response.data;
      this.setState({reorderedParts: [...this.state.reorderedParts, ...newReorderedParts]});
    });
    return bundles;
  };

  handleFetchRows = async () => {
    this.setState({
      loading: true,
    });
    const bundles = await this.getBundles(this.state.pagination.currentPage + 1);
    this.setState({
      data: [...this.state.data, ...bundles],
      loading: false,
    });
  };

  showTab() {
    return (
      <Box>
        <Tabs
          sx={{marginLeft: 2}}
          textColor={'secondary'}
          value={this.state.currentTab}
          onChange={(e, value) => this.setState({currentTab: value})}
        >
          <Tab
            sx={{
              '&.Mui-selected': {
                outline: 'none',
              },
              '&.MuiTab-root': {paddingBottom: '0px'},
            }}
            value={'recommended'}
            label={this.props.t('tab_header_recommended') + ' (' + this.state.pagination.totalItemCount + ')'}
          />
          <Tab
            sx={{
              '&.Mui-selected': {
                outline: 'none',
              },
              '&.MuiTab-root': {paddingBottom: '0px'},
            }}
            value={'all'}
            label={this.props.t('tab_header_all')}
            onClick={() => logEvent('Click on all open requests tab')}
          />
        </Tabs>
      </Box>
    );
  }

  renderStatus(seenAt) {
    if (seenAt) {
      return (
        <Tooltip
          arrow
          placement={'top'}
          title={this.props.t('respond_open_request')}
        >
          <HelpOutlineIcon fontSize={'medium'} color="secondary" />
        </Tooltip>
      );
    }
    return (
      <Chip
        sx={{
          '&.MuiChip-root': {height: 20, width: 35, fontSize: 8},
          '.MuiChip-label': {paddingLeft: 0.7, paddingRight: 0.7, paddingTop: 0.3},
        }}
        label={this.props.t('chip_label_new')} color={'primary'}
      />);
  }

  showRecommendedRequests() {
    return (
      <Box sx={{width: '100%'}}>
        <DataGridPro
          disableSelectionOnClick
          onRowClick={(rowData) => {
            this.props.navigate(`/bundle-suppliers/${rowData.id}`);
            logEvent('bundle_opened_from_kaktus');
          }}
          hideFooter
          disableColumnMenu
          disableColumnResize
          onRowsScrollEnd={this.handleFetchRows}
          loading={this.state.loading}
          sx={{
            height: 56 + 58 * (this.state.data.length || 1),
            maxHeight: 'calc(100vh - 300px)',
            '.MuiDataGrid-row.Mui-selected': {pointerEvents: 'none'},
            '.MuiDataGrid-row': {backgroundColor: 'white', marginBottom: '6px'},
            '.MuiDataGrid-row:hover': {
              boxShadow: '3px 3px 2px rgb(220,220,220)',
              borderStyle: 'solid',
              borderWidth: '0.5px',
              borderColor: '#DBDBDB',
              backgroundColor: 'white',
            },
            '.MuiDataGrid-columnHeaders': {borderBottom: 'none'},
          }}
          initialState={{
            sorting: {
              sortModel: [{field: 'createdAt', sort: 'desc'}],
            },
          }}
          columns={[
            {
              headerName: this.props.t('table_header_status'),
              field: 'seenAt',
              headerAlign: 'center',
              align: 'center',
              flex: 4,
              sortable: true,
              renderCell: params => this.renderStatus(params.value),
            },
            {
              headerName: '',
              field: 'firstAction',
              align: 'center',
              flex: 1,
              sortable: true,
            },
            {
              headerName: this.props.t('table_header_kaktus_number'),
              field: 'id',
              flex: 7,
              sortable: true,
            },
            {
              headerName: this.props.t('table_header_in_kaktus_since'),
              field: 'bundleStatuses',
              flex: 7,
              valueGetter: (params) => params.value.find(item => item.statusKey === 'open')?.createdAt,
              renderCell: params => <>{formatDate(params.value)}</>,
            },
            {
              headerName: this.props.t('table_header_total_positions'),
              field: 'numberOfPartConfigs',
              flex: 7,
              sortable: true,
            },
            {
              headerName: this.props.t('table_header_positions_with_reorder'),
              field: 'reorderedParts',
              flex: 7,
              sortable: true,
              renderCell: params => <>{!this.state.reorderedParts.find(rp => rp.bundleId === params.row.id) ?
                <Skeleton
                  animation={'wave'}
                  width={50}
                  variant={'text'}
                  sx={{fontSize: '1rem'}}
                /> : this.state.reorderedParts.find(rp => rp.bundleId === params.row.id)?.reorderedParts.length}</>,
            },
            {
              headerName: this.props.t('table_header_delivery_date'),
              field: 'currentDeliveryDate',
              flex: 7,
              valueFormatter: params => formatDate(params.value),
            },
            {
              headerName: this.props.t('table_header_price'),
              field: 'price',
              flex: 3,
              sortable: true,
              renderCell: params => <>{formatCurrency(params.row.counterOfferPrice || params.row.price)}</>,
            },
            {
              headerName: '',
              field: 'secondAction',
              flex: 7,
              sortable: false,
              align: 'center',
              renderCell: params =>
                <IconButton
                  onClick={() =>
                    (this.props.navigate(`/bundle-suppliers/${params.row.id}`),
                        logEvent('bundle_opened_from_kaktus')
                    )}
                >
                  <ArrowCircleRightSharpIcon color="primary" />
                </IconButton>,
            },
          ]}
          rows={this.state.data}
        />
      </Box>
    );
  }

  showAllOpenRequests() {
    return (
      <Grid sx={{width: '100%', height: 'calc(100vh - 300px)'}} container display={'flex'} justifyContent={'center'}>
        <Grid item xs={12} display={'flex'} justifyContent={'center'} alignItems={'flex-end'}>
          <img alt={'loading'} src={BoxLogo} className={'loading-logo'} />
        </Grid>

        <Grid item xs={12} display={'flex'} justifyContent={'center'} alignItems={'flex-start'} color={'#131C3E'}>
          {this.props.t('coming_soon')}
        </Grid>
      </Grid>
    );
  }

  render() {
    return (
      <>
        {this.showTab()}
        <Card style={{overflow: 'scroll', padding: '20px 25px', maxHeight: 'calc(100vh - 250px)'}}>
          {this.state.currentTab === 'recommended' ? this.showRecommendedRequests() : this.showAllOpenRequests()}
        </Card>
      </>
    );
  }
}
