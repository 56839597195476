import moment from 'moment';

/**
 * Get the array of available bundleSupplierCommentTypes from the bundleStatus response
 * @param {object} statusObj - The bundleSupplierStatus object returned by khapi
 * @param {string} bundleSupplierStatus - The bundleSupplierStatus where you want to get the available commentTypes
 * @param {boolean} [isPartnerQuote] - Remove 'price_too_low' and 'cant_hold_delivery_date' if this is set to true
 */
export const getCommentTypesForStatus = (statusObj, bundleSupplierStatus, isPartnerQuote) => {
  const currentBundleSupplierStatus = statusObj.find(
    (status) => status.key === bundleSupplierStatus,
  );
  //prettier-ignore
  let bundleSupplierStatusCommentTypes = currentBundleSupplierStatus['available_bundle_supplier_status_comment_types'];
  bundleSupplierStatusCommentTypes.forEach((reason) => (reason.checked = false));

  if (bundleSupplierStatus === 'rejected' && isPartnerQuote) {
    // Remove the reasons price_too_low & cant_hold_delivery_time for partner-quotes & counter-offers
    bundleSupplierStatusCommentTypes = bundleSupplierStatusCommentTypes.filter(
      (reason) => reason.key !== 'price_too_low' && reason.key !== 'cant_hold_delivery_time',
    );
  }

  //Move 'other' reason to the bottom of the array
  const otherReason = bundleSupplierStatusCommentTypes.find((reason) => reason.key === 'other');
  bundleSupplierStatusCommentTypes.splice(0, 1);
  bundleSupplierStatusCommentTypes.push(otherReason);

  return bundleSupplierStatusCommentTypes;
};

/**
 * Check if a bundle has been quoted
 * @param bundleSupplierObject
 * @returns {boolean}
 */
export const checkIfBundleHasBeenQuoted = (bundleSupplierObject) => {
  const selectedPartPriceArray: number[] = [];
  Object.values(bundleSupplierObject.partner_quotes)
    .forEach((pq: any) => {
      const filteredPartPrices = Object.values(pq.partner_part_prices)
        .filter((pp: any) => pp.part_price.selected === true);
      filteredPartPrices.forEach((pp: any) => selectedPartPriceArray.push(pp.price));
    });
  return !selectedPartPriceArray.includes(0);
};

export const formatPrice = (number) => {
  return new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
  }).format(number);
};

export const addWorkingHoursToDate = (date: moment.MomentInput, workingHours: number) => {
  const momentDate = moment(date);

  // If the date is a weekend, set it to the next Monday
  if ([6, 7].includes(momentDate.isoWeekday())) {
    momentDate.set({
      week: momentDate.isoWeek() + 1,
      day: 1,
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
  }

  for (let hours = 1; hours <= workingHours; hours++) {
    momentDate.add(1, 'hour');

    // If we now end up on a Saturday, add 2 days to get to Monday
    if (momentDate.isoWeekday() === 6) {
      momentDate.add(2, 'days');
    }
  }

  return momentDate;
};

export const subtractWorkingHoursFromDate = (date: moment.MomentInput, workingHours: number) => {
  const momentDate = moment(date);

  // If the date is a weekend, set it to the previous Friday
  if ([6, 7].includes(momentDate.isoWeekday())) {
    momentDate.set({
      week: momentDate.isoWeek(),
      day: 6,
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
  }

  for (let hours = 1; hours <= workingHours; hours++) {
    momentDate.subtract(1, 'hour');

    // If we now end up on a Sunday, subtract 2 days to get to Friday
    if (momentDate.isoWeekday() === 7) {
      momentDate.subtract(2, 'days');
    }
  }

  return momentDate;
};
