import {MuiBaseModal} from '../MuiBaseModal';
import {Button, Grid, Typography} from '@mui/material';
import {useState, useEffect} from 'react';
import {insertSeenAt, updateBundleStatus} from '../../../services/kooper';


type Props = {
  t: Function,
  auctionBundle: any,
  handleUpdate: Function
}
export const WelcomeToAuctionModal = (props: Props) => {
  const [open, setOpen] = useState(false);
  const [stage, setStage] = useState<any>('content');

  useEffect(() => {
    if (props.auctionBundle.statusKey === 'first_round') {
      handleToggle();
    }
  }, []);

  const handleToggle = () => {
    insertSeenAt(props.auctionBundle.id);
    setOpen(!open);
    props.handleUpdate();
  };

  const handleCancel = async () => {
    setStage('loading');
    try {
      await updateBundleStatus(props.auctionBundle.id, 'rejected');
      setStage('success');
    }
    catch(e) {
      setStage('failure');
    }
  };

  const content = () => {
    return (
      <Grid container>
        <Grid item xs={12} display={'flex'} justifyContent={'center'} marginTop={3}>
          <Typography variant={'h6'}>Welcome to Auction Round 1</Typography>
        </Grid>
        <Grid item xs={12} display={'flex'} justifyContent={'center'} marginTop={3}>
          <Typography variant={'body1'} align={'center'}>
            {props.t('modals:welcome_to_auction_modal_message')}
          </Typography>
        </Grid>
        <Grid item xs={12} marginBottom={1} marginTop={4}>
          <Button fullWidth onClick={handleToggle}>{props.t('modals:welcome_to_auction_modal_start_auction')}</Button>
        </Grid>
        <Grid item xs={12}>
          <Button fullWidth color={'secondary'} variant={'outlined'} onClick={handleCancel}>{props.t('modals:welcome_to_auction_modal_cancel')}</Button>
        </Grid>
      </Grid>
    );
  };

  return <MuiBaseModal
    open={open}
    onToggle={handleToggle}
    content={content()}
    height={'440px'}
    width={'500px'}
    stage={stage}
  />;
};
