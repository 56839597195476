import {Col, Container, Row} from 'shards-react';
import PageTitle             from 'components/Common/PageTitle';
import React, {Component}    from 'react';
import {withTranslation}     from 'react-i18next';
import SelfServiceCards      from '../../components/SelfService/SelfServiceTable';


class SelfService extends Component {

  render() {
    document.title = 'KREATIZE Kaktus';
    return (
      <Container fluid className='main-content-container px-4'>
        <Row noGutters className='page-header py-4'>
          <PageTitle
            sm='4'
            title={this.props.t('bundletable:page_title_self_service')}
            subtitle={this.props.t('bundletable:page_subtitle_self_service')}
            className='text-sm-left'
          />
        </Row>
        <Row>
          <Col>
            <SelfServiceCards {...this.props} />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withTranslation()(SelfService);
