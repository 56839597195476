import {MuiBaseModal} from '../MuiBaseModal';
import {ArrowForward} from '@mui/icons-material';
import {Button, Grid, Typography} from '@mui/material';
import {useState} from 'react';
import kaktusImg from '../../../assets/img/kaktus.png';
import {updateBundleStatus} from '../../../services/kooper';


type Props = {
  t: Function,
  auctionBundle: any,
  handleUpdate: Function,
}
export const SubmitAuctionBidModal = (props: Props) => {
  const [open, setOpen] = useState(false);
  const [stage, setStage] = useState<any>('content');
  const [successMessage, setSuccessMessage] = useState('');

  const isDisabled = () => {
    if (props.auctionBundle.statusKey === 'second_round') {
      return props.auctionBundle.auctionBundleOrderedPartConfigTeasers.some((bopc: any) => parseFloat(bopc.firstRoundPrice) < parseFloat(bopc.secondRoundPrice));
    }
    return false;
  };

  const handleReject = async () => {
    setStage('loading');
    try {
      await updateBundleStatus(props.auctionBundle.id, 'auction_rejected');
      setStage('success');
      setSuccessMessage(props.t('modals:submit_bid_modal_reject_success_message'));
    }
    catch(e) {
      setStage('failure');
    }
  };

  const handleSubmit = async () => {
    setStage('loading');
    try {
      await updateBundleStatus(props.auctionBundle.id, props.auctionBundle.statusKey === 'first_round' ? 'first_round_submitted' : 'second_round_submitted');
      setStage('success');
      setSuccessMessage(props.t('modals:submit_bid_modal_submit_success_message'));
    }
    catch(e) {
      setStage('failure');
    }
  };

  const handleToggle = () => {
    setOpen(!open);
    props.handleUpdate();
  };

  const content = () => {
    const isIncomplete = props.auctionBundle.auctionBundleOrderedPartConfigTeasers.some((bopc) => props.auctionBundle.statusKey === 'first_round' ? !bopc.firstRoundPrice : !bopc.secondRoundPrice);

    return (
      <Grid container>
        <Grid item xs={12} display={'flex'} justifyContent={'center'}>
          <img src={kaktusImg} alt={'KAKTUS'} style={{width: '172px', height: '120px'}} />
        </Grid>
        <Grid item xs={12} display={'flex'} justifyContent={'center'} marginTop={3}>
          <Typography variant={'h6'}>{props.t('modals:submit_auction_bid_modal_submit_my_bid')}</Typography>
        </Grid>
        <Grid item xs={12} display={'flex'} justifyContent={'center'} marginTop={3}>
          <Typography variant={'body1'} align={'center'}>
            {isIncomplete ?
              props.t('modals:submit_auction_bid_modal_some_positions_have_no_bid') : props.t('modals:submit_auction_bid_modal_mind_prices_are_official')
            }
          </Typography>
        </Grid>
        <Grid item xs={12} marginBottom={1} marginTop={isIncomplete ? 3 : 4}>
          {isIncomplete ?
            <Button fullWidth onClick={handleToggle}>{props.t('modals:submit_auction_bid_modal_continue_bid')}</Button> :
            <Button fullWidth onClick={handleSubmit}>{props.t('modals:submit_auction_bid_modal_submit_bid')}</Button>
          }
        </Grid>
        <Grid item xs={12}>
          <Button fullWidth color={'secondary'} variant={'outlined'} onClick={handleReject}>
            {props.t('modals:submit_auction_bid_modal_reject_auction')}
          </Button>
        </Grid>
      </Grid>
    );
  };

  return <MuiBaseModal
    button={<Button
      color={'error'}
      endIcon={<ArrowForward />}
      onClick={handleToggle}
      disabled={isDisabled()}
    >
      {props.t('modals:submit_auction_bid_modal_submit_bid')}
    </Button>}
    open={open}
    onToggle={handleToggle}
    content={content()}
    height={'440px'}
    width={'500px'}
    stage={stage}
    successMessageTitle={successMessage}
  />;
};