import * as firebase from 'firebase/app';
import {getAnalytics, setUserProperties, logEvent as FbLogEvent} from 'firebase/analytics';
import {getPerformance} from 'firebase/performance';


let analytics;
export const initializeFirebase = (userEmail) => {
  if (!process.env.REACT_APP_FIREBASE_APP_ID) {
    return;
  }

  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
  };

  firebase.initializeApp(firebaseConfig);
  analytics = getAnalytics();
  setUserProperties(analytics, {user_email: userEmail});
  getPerformance();
};

export const logEvent = (title, params) => {
  if (analytics) {
    return FbLogEvent(analytics, title, params);
  }
};

