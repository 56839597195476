import ViewerPDF from '../../BundleDetailsPage/ViewerPDF';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import {Grid, Paper, Zoom} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import {Close} from '@mui/icons-material';
import React, {useEffect} from 'react';
import {constructPdfDonwloadLink} from '../../../services/kooper';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Typography from '@mui/material/Typography';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {ZoomModal_CurrentBundleOrderedPartConfigId, ZoomModal_MousePosition, ZoomModal_Open} from '../../../global-atoms';
import {useAtom} from 'jotai';
import {SidePanelInfo} from './SidePanelInfo';


type Props = {
  t: Function,
  bundle: any
}

export const ZoomModal = (props: Props) => {

  const [open, setOpen] = useAtom(ZoomModal_Open);
  const [currentBundleOrderedPartConfigId, setCurrentBundleOrderedPartConfigId] = useAtom(ZoomModal_CurrentBundleOrderedPartConfigId);
  const [mousePosition] = useAtom(ZoomModal_MousePosition);
  const currentBopc = props.bundle.bundleOrderedPartConfigs.find(x => x.id === currentBundleOrderedPartConfigId);
  const handleNextOrPrevious = (direction: 'next' | 'previous') => {
    const currentIndex = props.bundle.bundleOrderedPartConfigs.findIndex(bopc => bopc.id === currentBopc.id);
    const isLastPart = currentIndex + 1 === props.bundle.bundleOrderedPartConfigs.length;
    if (direction === 'next') {
      const nextBopc = props.bundle.bundleOrderedPartConfigs[isLastPart ? 0 : currentIndex + 1];
      setCurrentBundleOrderedPartConfigId(nextBopc.id);
    } else {
      const isFirstPart = currentIndex === 0;
      setCurrentBundleOrderedPartConfigId(props.bundle.bundleOrderedPartConfigs[isFirstPart ? props.bundle.bundleOrderedPartConfigs.length - 1 : currentIndex - 1].id);
    }
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.code === 'ArrowRight') {
        handleNextOrPrevious('next');
      }
      if (event.code === 'ArrowLeft') {
        handleNextOrPrevious('previous');
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [currentBundleOrderedPartConfigId]);

  if (!currentBundleOrderedPartConfigId) return <></>;
  return (
    <Modal open={open} sx={{height: '100vh', minWidth: '600px'}}>
      <Zoom in={open} timeout={500} style={{transformOrigin: `${mousePosition.x}px ${mousePosition.y}px`}}>
        <Paper sx={{height: '100%', borderRadius: '0px'}}>
          <IconButton sx={{position: 'absolute', top: '0.5%', right: '0.5%'}} size={'small'} onClick={() => setOpen(false)}>
            <Close />
          </IconButton>
          <Grid container xs={12}>
            <Grid item container xs={12} key={'viewer-panel'} sx={{height: 'calc(100vh - 50px)'}}>
              <Grid item key={'viewer'} sx={{height: '100%', width: 'calc(100vw - 350px)', minWidth: '280px'}}>
                <ViewerPDF
                  file={constructPdfDonwloadLink(props.bundle.id, currentBundleOrderedPartConfigId) as any}
                  currentPartName={currentBopc.orderedPartConfig.orderedPart.name}
                />
              </Grid>
              <Grid item container sx={{width: '348px', backgroundColor: '#F9F9F9'}}>
                <SidePanelInfo currentBopc={currentBopc} t={props.t}></SidePanelInfo>
              </Grid>
            </Grid>
            <Grid item container key={'buttons'} xs={12} sx={{height: '50px', alignItems: 'center', paddingX: '30px', backgroundColor: '#F9F9F9'}}>
              <Grid item xs={4} sx={{display: 'flex', justifyContent: 'flex-start'}}>
                <Button
                  variant='outlined' size={'small'}
                  startIcon={<ArrowBackIcon />}
                  sx={{minWidth: '200px'}}
                  onClick={() => handleNextOrPrevious('previous')}
                >
                  {props.t('modals:zoom_modal_previous_part')}
                </Button>
              </Grid>
              <Grid item xs={4} sx={{display: 'flex', justifyContent: 'center'}}>
                <Typography variant={'subtitle2'} color={'primary'} sx={{fontSize: '14px'}}>
                  {`${props.t('modals:zoom_modal_part')} ${props.bundle.bundleOrderedPartConfigs.findIndex(bopc => bopc.id === currentBopc.id) + 1}/${props.bundle.bundleOrderedPartConfigs.length} `}
                </Typography>
              </Grid>
              <Grid item xs={4} sx={{display: 'flex', justifyContent: 'flex-end'}}>
                <Button
                  variant='outlined'
                  size={'small'}
                  sx={{minWidth: '200px'}}
                  endIcon={<ArrowForwardIcon />}
                  onClick={() => handleNextOrPrevious('next')}
                >
                  {props.t('modals:zoom_modal_next_part')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Zoom>
    </Modal>
  );
};









