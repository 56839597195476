import React from 'react';
import {Col, Container, Row} from 'shards-react';
import PageTitle from 'components/Common/PageTitle';
import {ProductionRequestsTable} from '../../components/ProductionRequestsTable/ProductionRequestsTable';


const AvailableBundles = (props: any) => {
  return (
    <Container fluid className='main-content-container px-4'>
      <Row noGutters className='page-header py-4'>
        <PageTitle
          sm='4'
          title={props.t('page_title_open')}
          subtitle={props.t('page_subtitle_open')}
          className='text-sm-left'
        />
      </Row>
      <Row>
        <Col>
          <ProductionRequestsTable
            t={props.t}
            {...props}
            statuses={['open']}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default AvailableBundles;
