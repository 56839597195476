import {Box, Checkbox} from '@mui/material';
import React from 'react';


type Props = {
  isRowSelectable: boolean,
  rowHovered: boolean,
  rowSelected: boolean,
  setRowSelected: Function,
  bopcId: number,
  index: number
}

export const CollapsablePartDetailsIdCheckbox = (props: Props) => {
  return props.isRowSelectable && (props.rowHovered || props.rowSelected) ?
    <Checkbox
      sx={{width: '20px', height: '20px', padding: 0, margin: 0}}
      checked={props.rowSelected}
      onChange={() => {
        props.setRowSelected(!props.rowSelected);
      }}
    />
    :
    <Box display={'flex'} justifyContent={'center'} sx={{borderRadius: '1px', width: '20px', height: '20px', backgroundColor: '#F9F9F9'}}>
      {props.index + 1}
    </Box>;

};