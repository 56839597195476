import React from 'react';
import {ProSidebar, Menu, MenuItem} from 'react-pro-sidebar';
import routes from 'routes.js';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {Badge} from '@mui/material';
import './Sidebar.scss';
import {useGlobal} from 'reactn';

const Sidebar = (props: any) => {
  const {t} = useTranslation(['sidebar']);
  const [user] = useGlobal('userInfo');

  return (
    <ProSidebar
      collapsed={props.collapsed}
      toggled={props.toggled}
      onToggle={props.handleSidebarToggle}
      breakPoint="md"
      onMouseEnter={() => props.handleSidebarCollapsed(false)}
      onMouseLeave={() => props.handleSidebarCollapsed(true)}
    >
      <Menu iconShape="round" popperArrow>
        {routes
          .map((route: any) => {
            const company = user.impersonatedCompany || user.company;
            if (route.key === 'route_combinatorial_auctions' && !company.features.some(feature => feature.companyFeatureKey === 'auctioning_enabled' && feature.value === 'true')) return <></>;
            if (route.key === 'route_rfq' && !company.features.some(f => f.companyFeatureKey === 'rfq_enabled' && f.value === 'true')) return <></>;
            const pathName = window.location.pathname;
            return (
              <MenuItem
                key={route.name}
                //@ts-ignore
                icon={<Badge badgeContent={<span style={{fontSize: 9}}>{t(route.badgeContent)}</span>} invisible={!route.badgeContent} color={'error'}>{route.icon}</Badge>}
                active={pathName === route.layout + route.path}
                suffix={route.suffix}
                onClick={() => route.function && route.function()}
              >
                {t(route.key)}
                {route.component && <Link to={route.layout + route.path} />}
              </MenuItem>
            );
          })}
      </Menu>
    </ProSidebar>
  );
};

export default Sidebar;