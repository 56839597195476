import React, {Component} from 'react';
import {Card, Chip, Grid, IconButton, Typography} from '@mui/material';
import {withTranslation} from 'react-i18next';
import {getPartnerPartConfigQuotes} from '../../services/kooper';
import Loading from '../../components/Loading/Loading';
import {ArrowCircleRightSharp, QueryBuilderOutlined} from '@mui/icons-material';
import {addWorkingHoursToDate} from '../../utils/helpers';

type State = {
  partnerQuotes: any[],
  completedPartnerQuoteIds: number[],
  partnerQuoteTimes: { days: number, hours: number, minutes: number, seconds: number }[]
  loading: boolean,
}
type Props = {
  t: Function,
  navigate: Function,
}

class RFQ extends Component<Props, State> {
  timer: any;

  constructor(props) {
    super(props);

    this.timer = null;

    this.state = {
      partnerQuotes: [],
      completedPartnerQuoteIds: [],
      partnerQuoteTimes: [],
      loading: true,
    };
  }

  async componentDidMount() {
    // Get RFQ data for the current supplier
    const partnerQuotes = await getPartnerPartConfigQuotes({
      distinct: ['partnerQuoteId'],
      select: {partnerQuote: true},
      orderBy: {partnerQuoteId: 'desc'},
    });

    const {page: completedPartnerQuotes} = await getPartnerPartConfigQuotes({
      distinct: ['partnerQuoteId'],
      select: {partnerQuote: true},
      orderBy: {partnerQuoteId: 'desc'},
      where: {statusKey: {not: 'quoting_in_progress'}},
    });

    const completedPartnerQuoteIds = completedPartnerQuotes.map(({partnerQuote}) => partnerQuote.id) as number[];

    const partnerQuoteTimes = partnerQuotes.page.reduce((acc, {partnerQuote}) => {
      acc[partnerQuote.id] = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
      return acc;
    }, {});

    // Calculate the time left for each RFQ
    this.timer = setInterval(() => {
      const now = new Date();
      partnerQuotes.page
        .filter(({partnerQuote}) => partnerQuote.statusKey !== 'quoting_completed' && !completedPartnerQuoteIds.some(id => id === partnerQuote.id))
        .forEach(({partnerQuote}) => {
          const diff = addWorkingHoursToDate(partnerQuote.createdAt, 72).toDate().getTime() - now.getTime();

          if (diff <= 0) {
            partnerQuoteTimes[partnerQuote.id] = {
              days: 0,
              hours: 0,
              minutes: 0,
              seconds: 0,
            };

            return;
          }

          partnerQuoteTimes[partnerQuote.id] = {
            days: Math.floor(diff / (1000 * 60 * 60 * 24)),
            hours: Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
            minutes: Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60)),
            seconds: Math.floor((diff % (1000 * 60)) / 1000),
          };
        });
      this.setState({partnerQuoteTimes});
    }, 1000);

    this.setState({
      completedPartnerQuoteIds,
      partnerQuotes: partnerQuotes.page.map(({partnerQuote}) => partnerQuote),
      loading: false,
    });
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  render() {
    const {t, navigate} = this.props;

    return (
      <Grid container sx={{height: '100%'}} alignContent={'flex-start'} pl={2.5} pr={6.25}>
        {/* Page Title */}
        <Grid item xs={12} sx={{height: 50}} mt={3.75} mb={2.5}>
          <Grid item container xs={6} alignItems={'center'}>
            <Grid item xs={12}>
              <Typography color={'primary'} variant={'h3'} sx={{fontWeight: 700, fontSize: '14px'}}>
                {t('rfq:rfq_dashboard').toUpperCase()}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'h3'} sx={{color: '#131C3E'}}>
                {t('rfq:rfq_dashboard_title')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {/* Page Content */}
        <Grid item container xs={12} sx={{height: 'calc(100vh - 180px) !important'}} overflow={'scroll'} alignContent={'flex-start'} spacing={2}>
          {
            this.state.loading
              ? (<><Loading pageHeight={'100%'} /></>)
              : (<>
                {
                  this.state.partnerQuotes.map((quote) => {
                    const quotingCompleted = quote.statusKey === 'quoting_completed' || this.state.completedPartnerQuoteIds.some(id => id === quote.id);
                    return (
                      <Grid item xs={12}>
                        <Card sx={{padding: 2}}>
                          <Grid container alignItems={'center'} justifyItems={'center'} sx={{height: '100%'}}>
                            <Grid item container xs={6} spacing={3}>
                              <Grid item xs={'auto'}><Typography fontWeight={600} fontSize={17} color={'#101C3E'}>{t('rfq:request_for_offer_id')} {quote.id}</Typography></Grid>
                              <Grid item xs={'auto'}>
                                <Chip
                                  size={'small'}
                                  color={!quotingCompleted ? 'warning' : 'primary'}
                                  label={t(`rfq:partner_quote_status_${(quotingCompleted ? 'quoting_completed' : quote.statusKey)}`).toUpperCase()}
                                />
                              </Grid>
                            </Grid>
                            <Grid container item xs={6} justifyContent={'flex-end'} alignItems={'center'}>
                              {!quotingCompleted &&
                                <Grid item xs={'auto'}>
                                  <Card sx={{width: '200px', height: '60px', padding: '10px'}}>
                                    <Grid container height={'100%'} alignItems={'center'}>
                                      <Grid item xs={3}>
                                        <QueryBuilderOutlined sx={{width: 30, height: 30}} color={'secondary'} />
                                      </Grid>
                                      <Grid item xs={9}>
                                        <Typography color={'primary'} variant={'h3'} sx={{fontWeight: 800, fontSize: '12px'}}>
                                          {t('auctions:combi_auction_card_countdown').toUpperCase()}
                                        </Typography>
                                        <Typography color={'black'} sx={{fontWeight: 600, fontSize: '14px'}}>
                                          {this.state.partnerQuoteTimes[quote.id]?.days}D {this.state.partnerQuoteTimes[quote.id]?.hours}H {this.state.partnerQuoteTimes[quote.id]?.minutes}M {this.state.partnerQuoteTimes[quote.id]?.seconds}S
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Card>
                                </Grid>
                              }
                              <Grid item xs={'auto'}>
                                <IconButton onClick={() => navigate(`/rfq/${quote.id}`)}><ArrowCircleRightSharp color={'primary'} /></IconButton>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Card>
                      </Grid>
                    );
                  })
                }
              </>)
          }
        </Grid>
      </Grid>
    );
  }
}

export default withTranslation(['common', 'rfq'])(RFQ);
