import {Chip, Grid, IconButton, InputAdornment, Link, TextField, Tooltip, Typography} from '@mui/material';
import {formatCurrency, formatDate} from '../../utils/formatHelpers';
import {downloadBundleOrderedPartConfigFiles, openPdf} from '../../services/kooper';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import {DataGridPro} from '@mui/x-data-grid-pro';
import React from 'react';
import {ErrorOutline, OpenInNew} from '@mui/icons-material';


type Props = {
  auctionBundleOrderedPartConfigTeasers: any[],
  totalFirstRoundPrice: number,
  totalSecondRoundPrice: number,
  loading: boolean,
  handlePriceInput: Function
  t: Function,
  statusKey: string,
  bundleKid: string,
  apiRef: React.MutableRefObject<any>
}
export const AuctionPackageDetailsTable = (props: Props) => {

  const renderPostProcessPills = (params) => {
    return <Grid container sx={{height: '50px', overflow: 'scroll', padding: '3px'}}>
      {params.row.bundleOrderedPartConfigProcessTeasers?.filter(x => x.processClass === 'post_process')
        .map(value =>
          <Grid item margin={'2px'}>
            <Chip
              label={props.t(`processes:${value.processKey}`)}
              sx={{
                height: '20px',
                backgroundColor: '#B1E7D5',
                fontSize: '12px',
                fontWeight: '400',
                color: '#131C3E',
              }}
            />
          </Grid>,
        )
      }
    </Grid>;
  };

  const columns = [
    {
      headerName: '#',
      field: 'rowNumber',
      width: 50,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) => {
        if (params.row.id === 'total') return '';
        return props.auctionBundleOrderedPartConfigTeasers.indexOf(params.row) + 1;
      },
      renderCell: (params) => <Typography sx={{fontSize: '12px'}}>{params.value}</Typography>,
    },
    {
      headerName: props.t('auctions:auction_package_details_header_part_name'),
      field: 'partName',
      width: 170,
      colSpan: (args) => args.row.id === 'total' ? 4 : 1,
      align: 'left',
      renderCell: (params) => params.row.id !== 'total' && <Typography sx={{fontSize: '12px'}}>
        <a href={'#'} onClick={() => openPdf(params.row.bundleId, params.row.id)}>
          {params.value} <OpenInNew fontSize={'inherit'} />
        </a>
      </Typography>,
    },
    {
      headerName: props.t('auctions:auction_package_details_header_batch'),
      field: 'batchSize',
      width: 100,
      align: 'left',
      renderCell: (params) => <Typography sx={{fontSize: '12px'}}>{params.value}</Typography>,

    },
    {
      headerName: props.t('auctions:combi_auction_delivery_date'),
      field: 'deliveryDate',
      width: 200,
      align: 'left',
      renderCell: (params) =>
        <Typography sx={{fontSize: '12px'}}>{params.value ? formatDate(params.value) : <></>}</Typography>,
    },
    {
      headerName: props.t('auctions:auction_package_details_header_material'),
      field: 'materialNames',
      width: 170,
      valueGetter: (params: any) => params.row.materialNames?.join('/'),
    },
    {
      headerName: props.t('auctions:auction_package_details_header_post_processes'),
      field: 'postProcesses',
      width: 270,
      valueGetter: (params) => params.row.bundleOrderedPartConfigProcessTeasers?.filter(x => x.processClass === 'post_process').map(x => props.t(`processes:${x.processKey}`)).join(', '),
      renderCell: renderPostProcessPills,
    },
    {
      headerName: props.t('auctions:auction_package_details_header_documentation'),
      field: 'documentation',
      valueGetter: (params) => {
        if (params.row.id === 'total') return props.t('auctions:auction_package_total_amount').toUpperCase();
        return params.row.requiredDocuments?.map((requiredDocument) => props.t(`modals:qms_modal_${requiredDocument}`)).join(', ');
      },
      renderCell: (params: any) => {
        if (params.row.id === 'total') return <Typography>{props.t('auctions:auction_package_total_amount').toUpperCase()} </Typography>;
        return <ul style={{margin: 0}}>
          {params.row.requiredDocuments?.map((requiredDocument) => <li>{props.t(`modals:qms_modal_${requiredDocument}`)}</li>)}
        </ul>;
      },
      width: 225,
    },
    {
      headerName: ['second_round', 'closed', 'second_round_submitted'].includes(props.statusKey) ?
        props.t('auctions:auction_package_details_header_your_bid_first_round')
        : props.t('auctions:auction_package_details_header_your_bid'),
      field: 'bid',
      width: 200,
      colSpan: (args) => args.row.id === 'total' && props.statusKey === 'first_round' ? 2 : 1,
      valueGetter: (params) => {
        if (params.row.id === 'total') return formatCurrency(props.totalFirstRoundPrice);
        return formatCurrency(params.row.firstRoundPrice);
      },
      renderCell: (params: any) => {
        if (params.row.id === 'total') return <Typography sx={{marginLeft: '10px'}}>{props.loading ? props.t('auctions:auction_package_calculating') : formatCurrency(props.totalFirstRoundPrice)}</Typography>;
        if (props.statusKey === 'first_round') {
          return <TextField
            size={'small'}
            placeholder={'Price per Piece'}
            onBlur={(e) => props.handlePriceInput(params.row.id, e.target.value)}
            defaultValue={props.auctionBundleOrderedPartConfigTeasers?.find((item) => item.id === params.row.id)?.firstRoundPrice}
          />;
        }
        return <Typography sx={{marginLeft: '10px'}}>{formatCurrency(params.row.firstRoundPrice)}</Typography>;
      },
    },
  ];

  if (['second_round', 'second_round_submitted', 'won'].includes(props.statusKey)) {
    columns.push(...[
      {
        headerName: props.t('auctions:auction_package_details_header_your_bid_second_round'),
        field: 'secondBid',
        width: 200,
        valueGetter: (params) => {
          if (params.row.id === 'total') return formatCurrency(props.totalSecondRoundPrice);
          return formatCurrency(params.row.secondRoundPrice);
        },
        renderCell: (params: any) => {
          if (params.row.id === 'total') return <Typography sx={{marginLeft: '10px'}}>{props.loading ? 'Calculating...' : formatCurrency(props.totalSecondRoundPrice)}</Typography>;
          if (props.statusKey === 'second_round') {
            return <TextField
              size={'small'}
              placeholder={'Price per Piece'}
              onBlur={(e) => props.handlePriceInput(params.row.id, e.target.value)}
              defaultValue={props.auctionBundleOrderedPartConfigTeasers?.find((item) => item.id === params.row.id)?.secondRoundPrice}
              error={parseFloat(params.row.firstRoundPrice) < parseFloat(params.row.secondRoundPrice)}
              InputProps={{
                endAdornment: parseFloat(params.row.firstRoundPrice) < parseFloat(params.row.secondRoundPrice) ?
                  <InputAdornment position={'end'}>
                    <Tooltip title={props.t('auctions:auction_second_round_price_too_high_error_tooltip')}><ErrorOutline color={'error'} /></Tooltip>
                  </InputAdornment> :
                  null,
              }}
            />;
          }
          return <Typography sx={{marginLeft: '10px'}}>{formatCurrency(params.row.secondRoundPrice)}</Typography>;
        },
      },
      {
        headerName: props.t('auctions:auction_package_details_header_lowest_bid'),
        headerAlign: 'center',
        field: 'minFirstRoundPrice',
        width: 150,
        align: 'center',
        valueFormatter: (params) => params.value ? formatCurrency(params.value) : '',
        renderCell: (params) => {
          if (params.row.id === 'total') return <></>;
          return <Typography>{formatCurrency(params.value)}</Typography>;
        },
      },
      {
        headerName: props.t('auctions:auction_package_details_header_average_bid'), headerAlign: 'center',
        field: 'avgFirstRoundPrice',
        width: 150,
        align: 'center',
        valueFormatter: (params) => params.value ? formatCurrency(params.value) : '',
        renderCell: (params) => {
          if (params.row.id === 'total') return <></>;
          return <Typography>{formatCurrency(params.value)}</Typography>;
        },
      },
      {
        headerName: props.t('auctions:auction_package_details_header_highest_bid'), headerAlign: 'center',
        field: 'maxFirstRoundPrice',
        width: 150,
        align: 'center',
        valueFormatter: (params) => params.value ? formatCurrency(params.value) : '',
        renderCell: (params) => {
          if (params.row.id === 'total') return <></>;
          return <Typography>{formatCurrency(params.value)}</Typography>;
        },
      },
    ] as any);
  }

  if (['won'].includes(props.statusKey)) {
    columns.push(
      {
        headerName: props.t('auctions:auction_package_details_header_bundle'),
        headerAlign: 'center',
        field: 'bundle',
        width: 150,
        align: 'center',
        disableExport: true,
        renderCell: params => {
          if (params.row.id === 'total') return <></>;
          if (params.row.wonBundleId) return <Link href={`/bundle-suppliers/${params.row.wonBundleId}`}>{`KAKTUS-${params.row.wonBundleId}`}</Link>;
          return <Chip label={'Lost'} variant={'outlined'} />;
        },
      } as any);
  }

  columns.push({
    headerName: props.t('auctions:auction_package_details_header_download'),
    field: 'download',
    flex: 1,
    minWidth: 100,
    align: 'center',
    headerAlign: 'center',
    disableExport: true,
    renderCell: (params: any) => {
      if (params.row.id === 'total') return <></>;
      return <IconButton onClick={() => downloadBundleOrderedPartConfigFiles(params.row.bundleId, params.row.id)}> <FileDownloadOutlinedIcon sx={{color: '#00afd3'}} /> </IconButton>;
    },
  } as any);

  columns.forEach((column: any) => column.sortable = false);

  return <DataGridPro
    apiRef={props.apiRef}
    disableSelectionOnClick
    hideFooter
    disableColumnMenu
    disableColumnResize
    rowHeight={70}
    pinnedColumns={{right: ['download'], left: ['rowNumber', 'partName']}}
    sx={{
      overflow: 'hidden',
      border: '0.5px solid #D1D5DB',
      '.MuiDataGrid-cell': {
        fontSize: '12px',
        fontWeight: 400,
        whiteSpace: 'normal !important',
        wordWrap: 'break-word !important',
        height: '50px',
        overflow: 'scroll',
      },
      '.MuiDataGrid-cellContent': {
        height: '50px',
        overflow: 'scroll',
        padding: '5px',
      },
      '.MuiDataGrid-row': {backgroundColor: '#FFF'},
      '.MuiDataGrid-columnHeader': {backgroundColor: '#F3F4F6', fontSize: '15px'},
      '.MuiDataGrid-columnHeaderTitle': {fontWeight: 400},
      '.MuiDataGrid-pinnedColumnHeaders': {boxShadow: 0},
      '.MuiDataGrid-pinnedColumns': {boxShadow: 0},

    }}
    columns={columns as any}
    rows={[...props.auctionBundleOrderedPartConfigTeasers, {id: 'total', value: 2}] as any}
  />;
};