import React from 'react';
import {Button, Grid, Paper, TextField, Typography} from '@mui/material';
import {format} from 'date-fns';
import {StaticDatePicker} from '@mui/x-date-pickers';
import moment from 'moment';
import {ArrowBackIos, ArrowForwardIos} from '@mui/icons-material';


type ContentProps = {
  t: Function,
  handleNewDate: Function
  handleStepChange: Function,
  newDate: Date
}

type FooterProps = {
  handleSubmit: Function,
  handleStepChange: Function,
  t: Function
}

export const DelayModalSelectDateContent = (props: ContentProps) => {
  return (
    <React.Fragment>
      <Grid container alignItems={'flex-start'}>
        <Grid item xs={12}>
          <Typography typography={'h6'}>
            {props.t('modals:delay_modal_title')}
          </Typography>
        </Grid>
        <Grid item xs={12} marginBottom={5}>
          <Typography typography={'body'}>
            {props.t('modals:delay_modal_select_date_subtitle')}
          </Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField label={'New Date'} disabled value={format(props.newDate, 'dd.MM.yyyy')} />
        </Grid>
        <Grid item xs={12} md={6} sx={{'.css-hlj6pa-MuiDialogActions-root': {display: 'none'}}} display={'flex'} justifyContent={'center'}>
          <Paper sx={{borderRadius: '10px', overflow: 'hidden', width: 330, height: 300}} variant={'outlined'}>
            <StaticDatePicker
              onChange={(newValue: any) => {props.handleNewDate(newValue);}} // @ts-ignore
              value={props.newDate}
              showToolbar={false}
              minDate={moment(new Date()).add(1, 'day').toDate()}
              renderInput={() => <></>}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={3} />
      </Grid>
    </React.Fragment>
  );
};

export const DelayModalSelectDateFooter = (props: FooterProps) => {
  return (
    <Grid item xs={12} container alignItems='flex-end' height={'100%'}>
      <Grid item xs={6} style={{justifyContent: 'flex-start', display: 'flex', paddingLeft: 20, paddingBottom: 20}}>
        <Button
          onClick={() => props.handleStepChange('previous')}
          variant={'outlined'}
          color={'secondary'}
        >
          <ArrowBackIos fontSize='small' style={{marginRight: 10}} />
          {props.t('modals:previous')}
        </Button>
      </Grid>
      <Grid item xs={6} style={{justifyContent: 'flex-end', display: 'flex', paddingRight: 20, paddingBottom: 20}}>
        <Button
          onClick={() => props.handleSubmit()}
          variant={'contained'}
          color={'warning'}
        >
          {props.t('modals:reject_modal_offer_submit_button')}

          <ArrowForwardIos fontSize='small' style={{marginLeft: 10}} />
        </Button>
      </Grid>
    </Grid>
  );
};

