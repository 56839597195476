import Typography from '@mui/material/Typography';
import {TextField} from '@mui/material';
import {formatCurrency} from '../../../utils/formatHelpers';
import {useAtom} from 'jotai';
import {CounterOfferPrices} from '../../../global-atoms';


type Props = {
  rowSelected: boolean,
  pricePerPiece: number,
  counterOfferPrice: number,
  bundleStatusKey: string,
  bopcId: number,
  batchSize: number
}

export const CollapsablePartDetailsTotalPrice = (props: Props) => {
  const [counterOfferPrices] = useAtom(CounterOfferPrices);
  
  if (!!props.counterOfferPrice || props.bundleStatusKey === 'counter_offer_requested' && props.rowSelected) {
    const enteredCounterOfferPrice = counterOfferPrices.find(x => x.bundleOrderedPartConfigId === props.bopcId)?.counterOfferPrice;
    return <>
      <Typography variant='subtitle2' sx={{fontSize: '12px', fontWeight: 600, color: '#d7d7d7', marginRight: '5px', textDecoration: 'line-through'}}>
        {`${formatCurrency(props.pricePerPiece * props.batchSize)}`}
      </Typography>

      <Typography variant='subtitle2' sx={{fontSize: '12px', fontWeight: 600, marginRight: '5px'}}>
        {`${formatCurrency((enteredCounterOfferPrice || props.counterOfferPrice) * props.batchSize)}`}
      </Typography>
    </>;
  }

  return (
    <Typography variant='subtitle2' sx={{fontSize: '12px', fontWeight: 600, marginRight: '5px'}}>
      {`${formatCurrency(props.pricePerPiece * props.batchSize)}`}
    </Typography>
  );
};
