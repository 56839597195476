import React, {useEffect, useState} from 'react';
import {getAuctionBundles} from '../../services/kooper';
import {Grid, Typography} from '@mui/material';
import {CollapsableAuctionDetails} from '../../components/CombinatorialAuctionsPage/CollapsableAuctionDetails';
import kaktusLoader from '../../assets/icons/kaktusLoader.svg';
import {AuctionPackageDetailsCard} from './AuctionPackageDetails.Card';
import {formatToRelativeDateDifference} from '../../utils/datetimeHelpers';
import moment from 'moment/moment';
import CountDownIcon from '../../assets/icons/countdown.svg';

type Props = {
  t: Function,
  navigate: Function
}

export const CombinatorialAuctions = (props: Props) => {
  const [auctions, setAuctions] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [countdown, setCountdown] = useState<any>('waiting');
  const [activeAuction, setActiveAuction] = useState<boolean>(false);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalItemCount: 0,
    totalPageCount: 0,
    itemsPerPage: 0,
  });

  useEffect(() => {
    getAuctionBundleList(1).then((res) => {
      const activeAuction = res.filter(item => ['first_round', 'second_round'].includes(item.auctionTeaser.statusKey));
      if (activeAuction.length > 0) {
        setActiveAuction(true);
        const interval = setInterval(() => {
          setCountdown(formatToRelativeDateDifference(
            moment(activeAuction[0].auctionTeaser.createdAt)
              .add('hours', activeAuction[0].auctionTeaser.statusKey === 'first_round' ? 48 : 72)
              .toString()));
        }, 1000);
        return () => clearInterval(interval);
      }
      setActiveAuction(false);
    });
  }, []);

  const getAuctionBundleList = async (page: Number, limit?: 25) => {
    setLoading(true);
    //auction id that are present in bundles
    const filter = {
      distinct: ['auctionId'], select: {auctionId: true, auctionTeaser: true}, orderBy: [{id: 'desc'}],
    };
    const res = await getAuctionBundles(page, limit, filter);
    setAuctions(res.page);
    setPagination(res.pagination);
    setLoading(false);
    return res.page;
  };

  if (countdown === 'waiting' && activeAuction) {
    return <div style={{width: '100%', height: '85%', display: 'flex', justifyContent: 'center', alignItems: 'center '}}>
      <div className={'lds-eclipse'}>
        <img width={200} src={kaktusLoader} alt={'loader'} />
        <div />
      </div>
    </div>;
  }

  return (
    <Grid container key={'auction-container-page'} sx={{minHeight: '100%', maxHeight: '100%', paddingRight: '50px', paddingLeft: '20px', alignContent: 'flex-start'}}>
      <Grid item container xs={12} key={'title'} sx={{height: '50px', marginTop: '30px', marginBottom: '20px'}}>
        <Grid item container xs={6} alignItems={'center'}>
          <Grid item xs={12}>
            <Typography color={'primary'} variant={'h3'} sx={{fontWeight: 700, fontSize: '14px'}}>
              {props.t('auctions:combi_auction_dashboard').toUpperCase()}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'h3'} sx={{color: '#131C3E'}}>
              {props.t('auctions:combi_auction_page_title')}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container xs={6} key={'card'} sx={{justifyContent: 'flex-end'}}>
          {activeAuction &&
            <Grid item container justifyContent={'flex-end'}>
              <AuctionPackageDetailsCard
                title={props.t('auctions:combi_auction_card_countdown')}
                subtitle={`${countdown.days}D ${countdown.hours}H ${countdown.minutes}M ${countdown.secs}S`}
                icon={CountDownIcon}
              />
            </Grid>
          }
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        sx={{
          height: 'calc(100vh - 180px) !important',
          // Hide scrollbar for main container
          '&::-webkit-scrollbar': {display: 'none'},
          '-ms-overflow-style': 'none',
          'scrollbar-width': 'none',
        }}
        overflow={'scroll'}
        alignContent={'flex-start'}
        key={'cards-container'}
      >
        {loading ?
          <div style={{width: '100%', height: '85%', display: 'flex', justifyContent: 'center', alignItems: 'center '}}>
            <div className={'lds-eclipse'}>
              <img width={200} src={kaktusLoader} alt={'loader'} />
              <div />
            </div>
          </div> :
          auctions.map((auction, index) =>
            <CollapsableAuctionDetails
              index={index}
              t={props.t}
              navigate={props.navigate}
              auctionId={auction.auctionId}
              auctionStatusKey={auction.auctionTeaser.statusKey}
            />,
          )}
      </Grid>
    </Grid>
  );
};
