import React, {Component} from 'react';
import {Collapse, Grid, IconButton} from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import Typography from '@mui/material/Typography';
import WarningOutlinedIcon from '@mui/icons-material/WarningOutlined';
import {QmsModal} from '../Modals/QmsModal/QmsModal';
import {downloadBundleOrderedPartConfigFiles} from '../../services/kooper';

interface State {
  loading: boolean,
  collapse: boolean
}

type Props = {
  t: Function,
  navigate: Function,
  bundle: any
  bundleOrderedPartConfig: any
  index: number,
  collapse: boolean,
  updateBundlePage: Function
}

export class CollapsedPart extends Component <Props, State> {

  render() {
    const bundleIsAccepted = ['complaint', 'delayed', 'delivered', 'ordered', 'partially_delivered', 'shipped', 'invoice_sent', 'invoice_paid'].includes(this.props.bundle.statusKey);
    const {requiredDocuments, orderedPartConfig} = this.props.bundleOrderedPartConfig;
    const attachments = [...orderedPartConfig.orderedPartConfigOrderedAttachments, ...orderedPartConfig.orderedPart.orderedPartAttachments];
    let numberOfAttachments = attachments?.filter(oa => oa.orderedAttachment.attachmentType.key !== 'technical_drawing').length || 0;
    const numberOfBlankedDrawings = attachments?.filter(oa => oa.orderedAttachment.attachmentType.key === 'blanked_technical_drawing').length;
    if (numberOfBlankedDrawings > 1) {
      numberOfAttachments -= numberOfBlankedDrawings - 1;
    }

    return (
      <Collapse in={this.props.collapse}>
        <Grid container>
          <Grid item container xs={12} key={'grey-part'} sx={{backgroundColor: '#F9F9F9', paddingLeft: '15px', paddingRight: '15px', paddingBottom: '10px', borderTop: '2px solid #DBDBDB'}}>
            <Grid item key={'first-column'} sx={{backgroundColor: '#F9F9F9', width: '40px'}} />
            <Grid item container key={'second-column'} sx={{backgroundColor: '#F9F9F9', paddingTop: 2, width: '203px'}}>
              <Grid item xs={12} key={'folder-row'} justifyContent={'flex-start'}>
                <IconButton onClick={() => downloadBundleOrderedPartConfigFiles(this.props.bundle.id, this.props.bundleOrderedPartConfig.id)}>
                  <FolderIcon color={'primary'} />
                </IconButton>
                <Typography sx={{fontWeight: 500, fontSize: '10px'}}>
                  {this.props.t('bundledetailspage:bs_collapsed_drawing_and_cad')}
                </Typography>
                <Typography sx={{fontWeight: 300, fontSize: '8px'}}>
                  {`${numberOfAttachments} ${this.props.t('bundledetailspage:bs_collapsed_files')}`}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container key={'third-flexible-column'} sx={{width: 'calc(100% - 243px)'}}>
              <Grid item container xs={3.5} key={'processes-column'} sx={{backgroundColor: '#F9F9F9', borderLeft: '2px solid #FFF', paddingX: '15px', paddingTop: 2}}>
                <Grid item xs={12} container key={'main-processes-container'}>
                  <Grid item xs={12} key={'main-processes-title'}>
                    <Typography variant="subtitle1">
                      {this.props.t('bundledetailspage:bs_collapsed_main_processes')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} key={'main-processes-body'} marginTop={1}>
                    <ul style={{marginLeft: '22px', overflowWrap: 'break-word'}}>
                      {orderedPartConfig.orderedPartConfigProcesses.filter((opcp) => opcp.process.class === 'process').map((opcp) =>
                        <li>
                          <Typography variant={'subtitle2'}>{this.props.t(`processes:${opcp.process.key}`)}</Typography>
                          <ul style={{paddingLeft: '20px', listStyleType: 'disc'}}>
                            {opcp.orderedPartConfigProcessFeatureValues.map((featureValue) => {
                              const unit = featureValue.processFeature.unitKey;
                              return <li key={featureValue.processFeatureTypeKey}>
                                <Typography variant={'subtitle2'}>
                                  {`${this.props.t(`processFeatures:${featureValue.processFeatureTypeKey}`)}: ${featureValue.value} ${!unit || unit === 'pieces' ? '' : this.props.t(`units:${unit}`)}`}
                                </Typography>
                              </li>;
                            })}
                          </ul>
                        </li>,
                      )}
                    </ul>
                  </Grid>
                </Grid>
                {orderedPartConfig.orderedPartConfigProcesses.filter((opcp) => opcp.process.class === 'post_process').length > 0 && (
                  <Grid item xs={12} container key={'post-processes-container'}>
                    <Grid item xs={12} key={'post-processes-title'}>
                      <Typography variant="subtitle1">
                        {this.props.t('bundledetailspage:bs_collapsed_post_processes')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} key={'post-processes-body'} marginTop={1}>
                      <ul style={{marginLeft: '22px', overflowWrap: 'break-word'}}>
                        {orderedPartConfig.orderedPartConfigProcesses.filter((opcp) => opcp.process.class === 'post_process').map((opcp) =>
                          <li>
                            <Typography variant={'subtitle2'}>{this.props.t(`processes:${opcp.process.key}`)}</Typography>
                            <ul style={{paddingLeft: '20px', listStyleType: 'disc'}}>
                              {opcp.orderedPartConfigProcessFeatureValues.map((featureValue) => {
                                const unit = featureValue.processFeature.unitKey;
                                return <li key={featureValue.processFeatureTypeKey}>
                                  <Typography variant={'subtitle2'}>{`${this.props.t(`processFeatures:${featureValue.processFeatureTypeKey}`)}: ${featureValue.value} ${!unit || unit === 'pieces' ? '' : this.props.t(`units:${unit}`)}`}</Typography>
                                </li>;
                              })}
                            </ul>
                          </li>)}
                      </ul>
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Grid
                item container xs={8.5}
                key={'documentation-column'}
                sx={{backgroundColor: '#F9F9F9', borderLeft: '2px solid #FFF', paddingTop: 2, paddingLeft: '10px'}}
                alignContent={'flex-start'}
              >
                <Grid item xs={12} key={'documentation-title'}>
                  <Typography variant={'subtitle1'}>
                    {this.props.t('bundledetailspage:bs_collapsed_documentation')}
                  </Typography>
                </Grid>
                <Grid item xs={12} key={'documentation-body'} sx={{marginTop: 1}}>
                  <ul style={{marginLeft: '22px', overflowWrap: 'break-word'}}>
                    {requiredDocuments.map((key) =>
                      <li>
                        <Typography variant={'subtitle2'}>{this.props.t(`modals:qms_modal_${key}`)}</Typography>
                      </li>)}
                  </ul>
                  {bundleIsAccepted &&
                    <QmsModal
                      bundleOrderedPartConfigId={this.props.bundleOrderedPartConfig.id}
                      attachments={this.props.bundleOrderedPartConfig.attachments}
                      requiredQms={this.props.bundleOrderedPartConfig.requiredDocuments}
                      bundleId={this.props.bundle.id}
                      t={this.props.t}
                      updateBundlePage={this.props.updateBundlePage}
                    />}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container xs={12} key={'alert-part'} alignItems={'center'} paddingLeft={2} spacing={2} sx={{height: '70px'}}>
            <Grid item>
              <WarningOutlinedIcon sx={{color: '#FFB800'}} />
            </Grid>
            <Grid item>
              <Typography sx={{fontStyle: 'italic', color: '#111827', fontWeight: 400, fontSize: '9px'}}>
                {this.props.t('bundledetailspage:bs_collapsed_alert')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Collapse>
    );
  }
}
