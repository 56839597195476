import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Button, Card, CardBody} from 'shards-react';
import {SyncLoader} from 'react-spinners';
import {withTranslation} from 'react-i18next';
import {formatCurrency, formatDate} from 'utils/formatHelpers';
import {getBundleList} from '../../services/kooper';
import './BundleTable.css';
import 'react-pagination-js/dist/styles.css';
import {Grid, Box} from '@mui/material';
import {logEvent} from '../../utils/firebase';
import {DataGrid} from '@mui/x-data-grid';

/* Type definitions */

interface State {
  loading: boolean,
  pagination: {
    currentPage: number,
    totalItemCount: number,
    totalPageCount: number,
    itemsPerPage: number
  },
  data: any,
  header: Array<String>,
  statuses: any
}

type Props = any

/* Component */

export class BundleTable extends Component <Props, State> {

  constructor(props: any) {
    super(props);
    this.state = {
      loading: true,
      pagination: {
        currentPage: 0,
        totalItemCount: 0,
        totalPageCount: 0,
        itemsPerPage: 0,
      },
      data: [],
      header: [
        'table_header_request_id',
        'table_header_number_of_parts',
        'table_header_price',
        'table_header_deliverydate',
        'table_header_status',
        '',
      ],
      statuses: props.statuses,
    };
  }

  getBundlesAndSetState = (page: number, limit = 25) => {
    this.setState({loading: true});
    getBundleList(page, limit, this.props.statuses, this.props.filter, this.props.order)
      .then(res => {
        this.setState({data: res.page, pagination: res.pagination, loading: false});
      });
  };

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (!(prevProps.statuses.length === this.props.statuses.length && prevProps.statuses.every((value: any, index: any) => value === this.props.statuses[index]))) {
      this.getBundlesAndSetState(1, 25);
    }
  }

  componentDidMount() {
    this.getBundlesAndSetState(1);
  }

  showTable() {
    const {currentPage, totalItemCount} = this.state.pagination;
    return (
      <Box>
        <DataGrid
          page={currentPage - 1}
          paginationMode={'server'}
          pageSize={25}
          rowCount={totalItemCount}
          rowsPerPageOptions={[25]}
          disableSelectionOnClick
          onPageChange={page => this.getBundlesAndSetState(page + 1)}
          loading={this.state.loading}
          sx={{
            height: 'calc(100vh - 250px)',
            maxHeight: 'calc(100vh - 250px)',
            '.MuiTablePagination-displayedRows': {
              marginBottom: 0,
            },
            '.MuiDataGrid-footerContainer': {
              justifyContent: 'center',
            },
          }}

          rows={this.state.data}
          columns={[
            {
              field: 'id',
              headerName: this.props.t('table_header_request_id'),
              flex: 1,
              sortable: true,
              hideable: false,
              filterable: true,
              renderCell: params => <Link
                style={{color: 'black', textDecoration: 'underline'}}
                to={`/bundle-suppliers/${params.row.id}`}
                onClick={() => logEvent('bundle_opened_from_kaktus')}
              >
                {params.row.id}
              </Link>,
            },
            {
              field: 'numberOfPartConfigs',
              headerName: this.props.t('table_header_number_of_parts'),
              flex: 1,
              sortable: true,
              hideable: false,
              filterable: true,
            },
            {
              field: 'price',
              headerName: this.props.t('table_header_price'),
              flex: 1,
              sortable: true,
              hideable: false,
              filterable: true,
              valueGetter: params => parseFloat(params.row.counterOfferPrice || params.row.price),
              renderCell: params => <>{formatCurrency(params.value)}</>,
            },
            {
              field: 'deliveryDate',
              headerName: this.props.t('table_header_deliverydate'),
              flex: 1,
              sortable: true,
              hideable: false,
              filterable: true,
              type: 'date',
              valueGetter: params => params.row.counterOfferDate || params.row.deliveryDate,
              renderCell: params => <>{formatDate(params.row.counterOfferDate || params.row.deliveryDate)}</>,
            },

            {
              field: 'statusKey',
              headerName: this.props.t('table_header_status'),
              sortable: true,
              hideable: false,
              filterable: true,
              flex: 1,
              renderCell: params => <>{this.props.t('status:' + (params.row.statusKey === 'lost_but_would_have_accepted' ? 'lost' : params.row.statusKey))}</>,
            },
            {
              field: 'toRequest',
              headerName: '',
              flex: 1,
              sortable: true,
              hideable: false,
              filterable: false,
              renderCell: params => <>
                <Button
                  pill
                  theme='info'
                  id={params.row.id}
                  style={{display: 'flex', justifyContent: 'center', alignItems: 'top', maxWidth: '105px'}}
                  onClick={() => {
                    logEvent('bundle_opened_from_kaktus');
                    this.props.navigate(`/bundle-suppliers/${params.row.id}`);
                  }}
                >
                  {this.props.t('table_button_to_request')}
                </Button>
              </>,
            },
          ]}
        />
      </Box>
    );
  }

  showLoading = () => {
    return (
      <Grid container sx={{display: 'flex', justifyContent: 'center', height: 'calc(100vh - 250px)', alignContent: 'center'}}>
        <SyncLoader color={'#72bcdf'} loading={this.state.loading} />
      </Grid>
    );
  };

  render() {
    return (
      <Card small className='mb-4 pt-2'>
        <CardBody className='p-0 pb-3'>
          {this.state.loading ? this.showLoading() : this.showTable()}
        </CardBody>
      </Card>
    );
  }
}

// @ts-ignore
export default withTranslation(['bundletable', 'status'])(BundleTable);
