import React from 'react';
import {ThreeStateCheckboxSwitch} from './SelfServiceTable';
import {Card, CardBody} from 'shards-react';


export const SelfServiceProductionRequirements = (props: any) => {
  const {t} = props;
  return (
    <Card small className='mb-4 pt-2'>
      <CardBody>
        <table className='table mb-0'>
          <thead className={'bg-light'}>
            <td>{t('processClasses:production_requirement')}</td>

            <td style={{display: 'flex', justifyContent: 'flex-end'}}>
              <div>{t('bundletable:available')}</div>
            </td>
          </thead>
          {props.processes.map((process: any) => {
            return (
              <tr key={process.key}>
                <td>{t(`processes:${process.key}`)}</td>
                <td style={{display: 'flex', justifyContent: 'flex-end'}}>
                  <ThreeStateCheckboxSwitch
                    checked={process.active}
                    onEnable={() => props.handleToggle(process, true)}
                    onDisable={() => props.handleToggle(process, false)}
                  />
                </td>
              </tr>
            );
          })}
        </table>
      </CardBody>
    </Card>
  );
};