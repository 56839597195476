import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import {AppBar, IconButton, Toolbar, Hidden} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import {getCompanies, startImpersonation, stopImpersonation, searchBundles} from '../../../../services/kooper';
import {logout} from 'services/auth';
import ChangePasswordModal from 'components/Modals/PasswordModal/ChangePasswordModal';
import Search from 'components/Search/Search';
import UserMenu from 'components/UserMenu/UserMenu';
import LanguageToggle from 'components/LanguageToggle/LanguageToggle';
import ImpersonationModal from 'components/ImpersonationModal/ImpersonationModal';
import logo from 'assets/img/logo.png';
import {logEvent} from '../../../../utils/firebase';

import './TopBar.scss';

class TopBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resetPasswordModalOpen: false,
    };
  }

  hasImpersonationRights = () => {
    const {user} = this.props;
    return user && user.roles.find(role => role.name === 'impersonation');
  };

  getOptions = searchValue => {
    logEvent('search_used');
    return searchBundles(searchValue)
      .then(data => data.page.map(bundle => ({label: bundle.bundleKid, value: bundle.id})))
      .catch(console.error);
  };

  render() {
    const {t, user} = this.props;
    return (
      <AppBar color="default" position="fixed">
        <Toolbar>
          <Hidden mdUp>
            <IconButton
              onClick={() => this.props.handleSidebarToggle(!this.props.sidebarToggled)}
              size="large"
            >
              <MenuIcon />
            </IconButton>
          </Hidden>

          <NavLink to={'/'}>
            <img className={'top-bar-logo'} src={logo} alt="Kreatize" />
          </NavLink>

          <Hidden smDown>

            <div className="px-4" style={{width: '60%'}}>
              <Search
                getOptions={(searchValue) => this.getOptions(searchValue)}
                redirect={searchObject => window.location.href = `/bundle-suppliers/${searchObject.value}`}
              />
            </div>

            {user && user.impersonating && (
              <div style={{display: 'flex', justifyContent: 'flex-start', width: '100%'}}>
              <span role={'img'} aria-label={'!'}>
                ⚠️ {t('common:you_are_currently_impersonating')}:
                <br />
                {user && user.impersonatedCompany.companyName}
              </span>
              </div>
            )}
          </Hidden>


          <div style={{display: 'flex', justifyContent: 'flex-end', width: '100%', alignItems: 'center'}}>
            <LanguageToggle />

            {this.hasImpersonationRights() && (
              <ImpersonationModal
                user={user}
                getCompanies={(searchValue) => getCompanies(searchValue)}
                startImpersonation={(assetId) => startImpersonation(assetId)}
                stopImpersonation={() => stopImpersonation()}
              />
            )}
            <ChangePasswordModal
              open={this.state.resetPasswordModalOpen}
              onToggle={() => this.setState({resetPasswordModalOpen: false})}
            />
            <UserMenu
              user={user}
              logout={() => {
                logEvent('logout');
                logout();
              }}
              redirectToProfilePage={() => this.setState({resetPasswordModalOpen: true})}
            />
          </div>
        </Toolbar>
      </AppBar>
    );
  }
}

export default withTranslation('common')(TopBar);
