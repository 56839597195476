import React, {Component} from 'react';
import {Button, Col, Row} from 'shards-react';
import {DropzoneArea} from 'mui-file-dropzone';
import BaseModal from '../BaseModal';
import {deleteBopcAttachment, downloadBopcFiles, uploadBopcFile} from '../../../services/kooper';
import {camelToSnakeCase} from '../../../utils/textHelpers';
import './QmsModal.css';
import _ from 'lodash';
import {Chip} from '@mui/material';
import Loader from 'react-spinners/RotateLoader';
import CheckIcon from '@mui/icons-material/Check';
import {logEvent} from '../../../utils/firebase';
import {toast} from 'react-hot-toast';

interface Props {
  bundleOrderedPartConfigId: number,
  t: Function,
  requiredQms: string[],
  attachments: any[],
  updateBundlePage: Function,
  bundleId: number
}

interface State {
  open: boolean,
  files: any,
  stage?: string,
  dense?: boolean,
}

export class QmsModal extends Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      open: false,

      files: [],

      stage: 'content',
      dense: false,
    };
  }

  componentDidMount() {
    const attachments = this.props.attachments || [];
    const filesCopy = [...this.state.files];
    for (const _attachment of attachments) {
      const attachmentType = _attachment.kaktusAttachment.attachmentType.key;

      const currentFileType = filesCopy.find(x => x.key === attachmentType);
      if (currentFileType) {
        currentFileType.files.push(_attachment);
      } else {
        filesCopy.push({key: attachmentType, files: [_attachment]});
      }
    }
    this.setState({files: filesCopy});

  }

  handleSubmit = (name: any, files: any[]) => {
    // @ts-ignore
    const fileNames = files.map(x => x.name);
    const filesFromState = this.state.files.find((x: any) => x.key === name)?.files.map((x: any) => x.name) || [];

    const toBeCreated = [...new Set(_.difference(fileNames, filesFromState))];

    if (toBeCreated.length) {
      this.setState({stage: 'loading'});

      const form = new FormData();

      toBeCreated.forEach((fileName: any) => {
        const file = files.find(x => x.name === fileName);
        form.append('files', file);
      });

      uploadBopcFile(this.props.bundleId, this.props.bundleOrderedPartConfigId, camelToSnakeCase(name), form)
        .then(({data}) => {
          logEvent('qms_files_upload');
          const filesCopy = [...this.state.files];
          let currentTypeFiles = filesCopy.find(x => x.key === name);
          if (!currentTypeFiles) {
            filesCopy.push({key: name, files: data});
          } else {
            currentTypeFiles.files = [...currentTypeFiles.files, ...data];
          }
          this.setState({stage: 'success', files: filesCopy});
        })

        .catch((err) => {
          if (err?.response?.data.message === 'A unique key constraint failed.') {
            toast.error(this.props.t('modals:error_duplicate_file'));

          } else {
            toast.error(err ? err.message : 'Error');
          }

          this.setState({stage: 'failure'});
          files = [];
        })
        .finally(() => {
          this.setState({stage: 'content'});
        });
    }
  };

  handleDelete = (file: any, type: any) => {
    deleteBopcAttachment(this.props.bundleId, this.props.bundleOrderedPartConfigId, file.id).then(() => {
      const filesCopy = [...this.state.files];
      let currentFileType = filesCopy.find(x => x.key === type);
      currentFileType.files = currentFileType.files.filter((x: any) => x.kaktusAttachment.id !== file.id);
      this.setState({files: filesCopy});

    });

  };

  handleToggle = (state?: boolean) => {
    if (typeof state === 'boolean') {
      this.setState({
        open: state,
        stage: 'content',
      });
    } else {
      this.setState({
        open: !this.state.open,
        stage: 'content',
      });
    }
    if (this.state.open) {
      this.props.updateBundlePage();
    }
  };

  loader = () => <div><Loader /></div>;

  dropZoneWithButton = (type: any) => {
    const {t} = this.props;
    // @ts-ignore
    const files = this.state.files.find(x => x.key === type)?.files || [];
    return (
      <Col key={type} style={{padding: '20px', paddingBottom: 0}}>
        <Row>

          <DropzoneArea
            acceptedFiles={['application/pdf', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel']}
            dropzoneText={t(`modals:qms_modal_${camelToSnakeCase(type)}`)}
            onChange={(files) => this.handleSubmit(type, files)}
            showPreviews={false}
            initialFiles={files}
            showPreviewsInDropzone={false}
            showFileNames={false}
            filesLimit={100}
            useChipsForPreview={false}
            showAlerts={false}
            dropzoneProps={{disabled: false}}
            dropzoneClass={files?.length && 'dropzone-green'}
            onDropRejected={() => toast.error(t('modals:qms_modal_file_upload_error'))}
            maxFileSize={25000000}
            // @ts-ignore
            Icon={this.state.stage === 'loading' ? this.loader : !!files.length && CheckIcon}

          />

        </Row>
        {!!files?.length &&
          <React.Fragment>
            <Row>
              <div style={{marginTop: '20px'}}>
                <div style={{marginBottom: '10px'}}>{t('modals:qms_modal_uploaded_documents')}</div>
                {files.map((({kaktusAttachment}: any) => {
                  return (
                    <Chip
                      key={kaktusAttachment.name}
                      style={{marginRight: '5px', marginBottom: '5px'}}
                      label={kaktusAttachment.name}
                      onDelete={() => this.handleDelete(kaktusAttachment, type)}
                    />
                  );
                }))}

              </div>
            </Row>
          </React.Fragment>
        }

      </Col>
    );
  };

  content = () => {
    const {requiredQms} = this.props;

    return (
      <React.Fragment>
        <Row>
          {requiredQms.map(type => this.dropZoneWithButton(type))}
        </Row>
        <Row style={{marginTop: '20px'}}>
          <Button block onClick={() => downloadBopcFiles(this.props.bundleId, this.props.bundleOrderedPartConfigId)}>Download all</Button>
        </Row>
      </React.Fragment>
    );
  };

  allRequiredDocumentsUploaded = () => {
    const required = this.props.requiredQms;
    const actual = this.state.files;

    let allUploaded: boolean[] = [];
    for (const _key of required) {
      const currentFileEntry = actual.find((x: any) => x.key === _key);
      if (currentFileEntry?.files.length > 0) {
        allUploaded.push(true);
      }
    }
    return allUploaded.length === required.length;

  };

  render() {
    const {t} = this.props;
    const allUploaded = this.allRequiredDocumentsUploaded();
    return (
      <BaseModal
        name={'QmsModal'}
        stage={'content'}
        open={this.state.open}
        content={this.content()}
        successMessage={t('modals:upload_modal_success_message')}
        failureMessage={t('modals:upload_modal_failure_message')}
        infoMessage={t('modals:upload_modal_info_message')}
        buttonTheme={allUploaded ? 'success' : 'danger'}
        buttonText={allUploaded ? t('bundledetailspage:bs_button_documetation_edit') : t('bundledetailspage:bs_button_documetation_upload')}
        modalTitle={t('bundledetailspage:bs_button_documetation_upload')}
        onToggle={this.handleToggle}
        buttonStyle={{height: '25px', fontSize: '13px', width: '200px'}}
      />
    );
  }
}

// @ts-ignore
export default QmsModal;
