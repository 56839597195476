import React from 'react';
import {AcceptModal} from '../Modals/AcceptModal/AcceptModal';
import {RejectModal} from '../Modals/RejectModal/RejectModal';
import {DelayModal} from '../Modals/DelayModal/DelayModal';
import {ShippedModal} from '../Modals/ShippedModal/ShippedModal';
import {Grid} from '@mui/material';
import {UploadModal} from '../Modals/UploadModal/UploadModal';
import {CounterOfferModal} from '../Modals/CounterOfferModal/CounterOfferModal';

interface Props {
  status: string,
  bundleId: number,
  deliveryDate: string,
  totalPrice: number,
  offeredTotalPrice: number,
  offeredDeliveryDate: string,
  data: any,
  qms: any
  t: Function
  updateBundlePage: Function,
}

export const ActionPanelModals = (props: Props) => {
  const showContent = () => {
    const orderedConfirmationUploaded = props.data.bundleAttachments?.some((attachment: any) => attachment.kaktusAttachment.attachmentType.key === 'order_confirmation');
    const bundleFromAuction = !!props.data.parentBundle?.auctionId;
    let status = props.status;

    switch (status) {
      case 'open':
      case 'counter_offer_accepted':
        return (
          <>
            <Grid item xs={12} marginBottom={1}>
              <AcceptModal {...props} />
            </Grid>
            <Grid item xs={12}>
              <RejectModal {...props} buttonDisabled={bundleFromAuction} textButton={'bundledetailspage:bs_action_panel_button_reject'} />
            </Grid>
          </>
        );

      case 'rejected':
      case 'lost_but_would_have_rejected':
        return (
          <>
            <Grid item xs={12} marginBottom={1}>
              <AcceptModal {...props} buttonDisabled={true}
              />
            </Grid>
            <Grid item xs={12}>
              <RejectModal {...props} buttonDisabled={true} textButton={'bundledetailspage:bs_action_panel_button_reject'}
              />
            </Grid>
          </>
        );

      case 'counter_offer_requested':
        return (
          <>
            <Grid item xs={12} marginBottom={1}>
              <CounterOfferModal  {...props} />
            </Grid>
            <Grid item xs={12}>
              <RejectModal {...props} textButton={'bundledetailspage:bs_action_panel_button_reject_counter_offer'} />
            </Grid>
          </>
        );

      case 'ordered':
      case 'delayed':
        return (
          <>
            {!orderedConfirmationUploaded &&
              <Grid item xs={12} marginBottom={1}>
                <UploadModal {...props} />
              </Grid>
            }
            <Grid item xs={12} marginBottom={1}>
              <ShippedModal {...props} />
            </Grid>
            <Grid item xs={12}>
              <DelayModal {...props} />
            </Grid>
          </>
        );

      case 'lost':
        if (new Date(props.data.createdAt) > new Date(process.env.REACT_APP_RELEASE_TIMESTAMP as string)) {
          return (
            <>
              <Grid item xs={12} marginBottom={1}>
                <AcceptModal {...props} />
              </Grid>
              <Grid item xs={12}>
                <RejectModal {...props} textButton={'bundledetailspage:bs_action_panel_button_reject'} />
              </Grid>
            </>
          );
        }
        return <></>;
      // in case counter_offer_submitted shipped and delivered
      default:
        return <></>;
    }
  };

  return (
    <Grid item container display={'flex'} justifyContent={'center'} xs={12} spacing={1}>
      {showContent()}
    </Grid>);
};


