import {Chip, Grid} from '@mui/material';


type Props = {
  statusKey: string,
  t: Function
}
export const AuctionStatus = (props: Props) => {
  let color = 'white';

  switch (props.statusKey) {
    case('second_round'):
    case('first_round'):
      color = 'warning';
      break;

    case('closed'):
      color = 'primary';
      break;

    default:
      return <></>;
  }
  return (
    <Grid item sx={{minWidth: 20}}>
      <Chip
        color={color as any}
        sx={{
          '&.MuiChip-root': {height: 25, minWidth: 20, width: 120, fontSize: 15},
          '.MuiChip-label': {paddingLeft: 0.7, paddingRight: 0.7, fontWeight: 500, fontSize: '12px'},
          backgroundColor: color,
          marginLeft: '10px',
        }}
        label={props.t(`auctions:combi_auction_status_${(props.statusKey)}`).toUpperCase()}
      />
    </Grid>);
};