import React from 'react';
import TimerIcon from '../../assets/icons/timer.svg';
import TruckIcon from '../../assets/icons/truck.svg';
import QuestionIcon from '../../assets/icons/question.svg';
import InProductionIcon from '../../assets/icons/blueMachine.svg';
import HandsIcon from '../../assets/icons/hands.svg';
import DeliveredIcon from '../../assets/icons/delivered.svg';
import {Grid, IconButton, Tooltip, Typography} from '@mui/material';
import RejectedIcon from '../../assets/icons/fingerDown.svg';
import EyeIcon from '../../assets/icons/eye.svg';
import AccessTimeIcon from '@mui/icons-material/AccessTime';


interface Props {
  status: string,
  currentBundleStatus: any,
  createdAt: string,
  t: Function
}

export const ActionPanelIcons = (props: Props) => {

  let status = props.status;
  let imageSrc;

  switch (status) {
    case 'open':
      imageSrc = QuestionIcon;
      break;

    case 'rejected':
    case 'lost_but_would_have_rejected':
      imageSrc = RejectedIcon;
      break;

    case 'counter_offer_requested':
      imageSrc = TimerIcon;
      break;

    case 'counter_offer_accepted':
      imageSrc = HandsIcon;
      break;

    case 'shipped':
      imageSrc = TruckIcon;
      break;

    case 'ordered':
      imageSrc = InProductionIcon;
      break;

    case 'delivered':
      imageSrc = DeliveredIcon;
      break;

    case 'counter_offer_submitted':
      imageSrc = EyeIcon;
      break;

    case 'lost':
      if (new Date(props.createdAt) > new Date(process.env.REACT_APP_RELEASE_TIMESTAMP as string)) {
        imageSrc = QuestionIcon;
      }
      break;

    case 'delayed':
      return (
        <Tooltip
          arrow
          placement={'left'}
          componentsProps={{tooltip: {sx: {maxWidth: '2000px', paddingX: '20px', paddingY: '10px'}}}}
          title={
            <>
              <Typography sx={{color: '#FFF', fontSize: '12px', marginBottom: '10px'}}>{props.t('bundledetailspage:bs_tooltip_delay_reason')}</Typography>
              <ul style={{marginLeft: '10px'}}>
                {props.currentBundleStatus.bundleStatusFeatureValues
                  .filter(bundleStatusFeatureValue => bundleStatusFeatureValue.statusFeatureKey === 'delay_reason')
                  .map(bundleStatusFeatureValue => <li> {props.t(`commenttypes:${bundleStatusFeatureValue.value}`)}</li>)
                }
              </ul>
            </>
          }
        >
          <IconButton sx={{'&.MuiIconButton-root:hover': {backgroundColor: 'transparent'}, padding: 0}}>
            <AccessTimeIcon sx={{height: '24px', color: '5A6078'}} />
          </IconButton>
        </Tooltip>
      );
    default:
      return <></>;
  }
  return (
    <img src={imageSrc} style={{width: '24px', height: '24px'}} />
  );
};


