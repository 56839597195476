import React from 'react';
import {useTranslation} from 'react-i18next';
import {downloadCustomsDocuments} from '../../../services/kooper';
import {ArrowBackIos, ArrowForwardIos, GetApp} from '@mui/icons-material';
import {snakeToCamelCase} from '../../../utils/textHelpers';
import {ClipLoader} from 'react-spinners';
import validator from 'validator';
import {Autocomplete, Grid, TextField, Tooltip, Button} from '@mui/material';
import {logEvent} from '../../../utils/firebase';


export const DownloadModalCustomsDetails = (props) => {
  const {t} = useTranslation(['modals']);
  const inputFields = [
    {key: 'contact_name', placeholder: 'Max Mustermann', type: 'string', value: '', isValid: false},
    {key: 'company_name', placeholder: 'Company GmbH', type: 'string', value: '', isValid: false},
    {key: 'address_line_1', placeholder: 'Street 1', type: 'string', value: '', isValid: false},
    {key: 'address_line_2', placeholder: '', type: 'string', value: '', isValid: true},
    {key: 'zip', placeholder: '12345', type: 'string', value: '', isValid: false},
    {key: 'city', placeholder: 'Berlin', type: 'string', value: '', isValid: false},
    {key: 'country', placeholder: 'Germany', type: 'string', value: '', isValid: false},
    {key: 'contact_email', placeholder: 'max@company.com', type: 'email', value: '', isValid: false},
    {key: 'contact_phone', placeholder: '+49 1234 567890', type: 'phone', value: '', isValid: false},
    {key: 'dispatch_method', placeholder: '', type: 'select', value: 'air', isValid: true},
  ];
  if (['ordered', 'delayed'].includes(props.status)) {
    inputFields.push(...[
      {key: 'shipping_provider', placeholder: '', type: 'string', value: '', isValid: false},
      {key: 'package_type', placeholder: '', type: 'select', value: '', isValid: false},
    ]);
  }

  const dispatchMethods = [
    {label: t('modals:download_modal_customs_ship'), key: 'ship'},
    {label: t('modals:download_modal_customs_air'), key: 'air'},
  ];

  const packageTypes = [
    {key: 'package', label: t('modals:shipped_modal_package')},
    {key: 'pallet', label: t('modals:shipped_modal_pallet')},
    {key: 'pallet_cage', label: t('modals:shipped_modal_pallet_cage')},
  ];

  const [fields, setFields] = React.useState(inputFields);
  const [page, setPage] = React.useState('instructions');
  const [buttonLoading, setButtonLoading] = React.useState(false);

  const formatInputFields = () => {
    const data = {};
    for (const _field of fields) {
      data[snakeToCamelCase(_field.key)] = (_field.key === 'address_line_2' && !_field.value) ? '-' : _field.value;
    }
    return data;
  };

  const handleChange = (value, key) => {
    const fieldsCopy = [...fields];
    let currEntry = fieldsCopy[fieldsCopy.findIndex(x => x.key === key)];
    currEntry.isValid = !value || validateInput(currEntry.type, value);
    fieldsCopy[fieldsCopy.findIndex(x => x.key === key)] = {...currEntry, value};
    setFields(fieldsCopy);
  };

  const handleDownload = async () => {
    setButtonLoading(true);
    await downloadCustomsDocuments(props.bundleId, formatInputFields());
    setButtonLoading(false);
  };

  const validateInput = (type, value) => {
    switch (type) {
      case 'string':
        return validator.isAlphanumeric(value, 'de-DE', {ignore: /[ .,()\-{}%&/]/g});
      case 'email':
        return validator.isEmail(value);
      case 'phone':
        return !!value.match(/^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/);
      default:
        return true;
    }
  };

  const content = () => {

    switch (page) {
      case 'instructions':
        return (
          <React.Fragment>
            <Grid item xs={12}>
              <h5>{t('customs_details_instructions_title')}</h5>
              <div style={{marginLeft: 25, marginTop: 10}}>
                <ol>
                  <li style={{marginBottom: 10}}>
                    {t('customs_details_instructions_1')}
                    <ul style={{marginLeft: 25}}>
                      <li>3 x {t('customs_details_proforma_invoice')}</li>
                      <li>1 x {t('customs_details_packaging_list')}</li>
                      <li>1 x {t('customs_details_order_confirmation')}</li>
                      <li>1 x {t('customs_details_delivery_slip')}</li>
                    </ul>
                  </li>
                  <li style={{marginBottom: 10}}>{t('customs_details_instructions_2')}</li>
                  <li style={{marginBottom: 10}}>{t('customs_details_instructions_3')}</li>
                </ol>
              </div>
            </Grid>
            <Grid item xs={12} container justifyContent='flex-end' alignItems='flex-end'>
              <Grid item xs={6} />
              <Grid item xs={6} style={{justifyContent: 'flex-end', display: 'flex', paddingRight: 20}}>
                <Button onClick={() => setPage('form')}>
                  {t('modals:next')}
                  <ArrowForwardIos fontSize='small' style={{marginLeft: 10}} />
                </Button>
              </Grid>
            </Grid>
          </React.Fragment>
        );
      case 'form':
        const form = () => {
          return fields.filter(x => !['package_type', 'dispatch_method'].includes(x.key)).map(input => {
            return (
              <Grid container marginY={1}>
                <Grid item xs={4}>
                  {t(`download_modal_customs_${input.key}`)}:
                </Grid>
                <Grid item xs={8}>
                  <Tooltip open={!!input.value && !input.isValid} title={`${t(`modals:invalid_${input.type}`)}`} arrow placement={'top-start'}>
                    <TextField
                      id={input.key}
                      placeholder={input.placeholder}
                      size={'small'}
                      InputLabelProps={{shrink: false}}
                      onChange={(e) => handleChange(e.target.value, input.key)}
                      value={input.value}
                      error={!!input.value && !input.isValid}
                      type={'email'}
                      fullWidth
                    />
                  </Tooltip>
                </Grid>
              </Grid>
            );
          });
        };

        return (
          <React.Fragment>
            <Grid container>
              <h5>{t('customs_details_form_title')}</h5>
              <div style={{width: '100%', marginTop: 10}}>
                {form()}
                {['ordered', 'delayed'].includes(props.status) &&
                  <Grid item container xs={12} marginY={1}>
                    <Grid item xs={4}>
                      {t(`download_modal_customs_package_type`)}:
                    </Grid>
                    <Grid item xs={8}>
                      <Autocomplete
                        size={'small'}
                        options={packageTypes}
                        disablePortal
                        onInputChange={(e, value) => handleChange(value, 'package_type')}
                        renderInput={(params) =>
                          <TextField
                            {...params}
                          />}
                      />
                    </Grid>
                  </Grid>
                }
                <Grid item container xs={12}>
                  <Grid item xs={4}>
                    {t(`download_modal_customs_dispatch_method`)}:
                  </Grid>
                  <Grid item xs={8}>
                    <Autocomplete
                      size={'small'}
                      options={dispatchMethods}
                      disablePortal
                      onInputChange={(e, value) => handleChange(value, 'dispatch_method')}
                      defaultValue={dispatchMethods[1]}
                      renderInput={(params) =>
                        <TextField
                          {...params}
                          InputLabelProps={{shrink: false}}
                        />}
                    />
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item xs={12} container alignItems='flex-end' marginTop={5}>
              <Grid item xs={6} style={{justifyContent: 'flex-start', display: 'flex', paddingLeft: 20}}>
                <Button
                  color={'secondary'}
                  onClick={() => setPage('instructions')}
                >
                  <ArrowBackIos fontSize='small' style={{marginRight: 10}} />
                  {t('modals:previous')}
                </Button>
              </Grid>
              <Grid item xs={6} style={{justifyContent: 'flex-end', display: 'flex', paddingRight: 20}}>
                <Button
                  color={'info'}
                  onClick={() => setPage('download')}
                  disabled={!!fields.filter(x => x.key !== 'address_line_2' && !x.value || !x.isValid).length}
                >
                  {t('modals:next')}
                  <ArrowForwardIos fontSize='small' style={{marginLeft: 10}} />
                </Button>
              </Grid>
            </Grid>
          </React.Fragment>
        );

      case 'download':
        return (
          <Grid container>
            <Grid item xs={12}>
              <h5>{t('customs_details_download_title')}</h5>
              {t('customs_details_download_content')}
            </Grid>

            <Grid item xs={12} container alignItems='flex-end' marginTop={5}>
              <Grid item xs={6} style={{justifyContent: 'flex-start', display: 'flex', paddingLeft: 20}}>
                <Button
                  color={'secondary'}
                  variant={'contained'}
                  onClick={() => setPage('form')}
                >
                  <ArrowBackIos fontSize='small' style={{marginRight: 10}} />
                  {t('modals:previous')}
                </Button>
              </Grid>
              <Grid item xs={6} style={{justifyContent: 'flex-end', display: 'flex', paddingRight: 20}}>
                <Button
                  color={'success'}
                  variant={'contained'}
                  onClick={() => {
                    logEvent('customs_modal_download');
                    handleDownload();
                  }}
                >
                  {buttonLoading ? <ClipLoader size={20} color={'#FFF'} /> : <>Download <GetApp fontSize={'small'} style={{marginLeft: '10px'}} /> </>}
                </Button>
              </Grid>
            </Grid>
          </Grid>

        );
      default:
        return <></>;
    }
  };

  return content();
};

