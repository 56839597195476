import React from 'react';
import {Viewer} from '@react-pdf-viewer/core';
import {toolbarPlugin, ToolbarSlot} from '@react-pdf-viewer/toolbar';
import {Worker} from '@react-pdf-viewer/core';
import {Grid} from '@mui/material';
import {fullScreenPlugin} from '@react-pdf-viewer/full-screen';
import {scrollModePlugin} from '@react-pdf-viewer/scroll-mode';
import Loading from '../Loading/Loading';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
import {getFilePlugin} from '@react-pdf-viewer/get-file';


interface Props {
  file: Uint8Array;
  currentPartName?: string;
}

const ViewerPDF: React.FC<Props> = ({file, currentPartName}) => {
  const toolbarPluginInstance = toolbarPlugin();
  const {Toolbar} = toolbarPluginInstance;
  const fullScreenPluginInstance = fullScreenPlugin();
  const {EnterFullScreen} = fullScreenPluginInstance;
  const scrollModePluginInstance = scrollModePlugin();
  const getFilePluginInstance = getFilePlugin({
    fileNameGenerator: () => {
      return `${currentPartName}.pdf` || '';
    },
  });
  const {Download} = getFilePluginInstance;

  return (
    <Grid container sx={{display: 'flex', flexDirection: 'column', height: '100%', minWidth: '100px', backgroundColor: '#FFF'}}>
      <Grid item container sx={{height: '40px', alignItems: 'center', backgroundColor: '#F9F9F9', borderBottom: '#131C3E', display: 'flex', padding: '4px'}}>
        <Toolbar>
          {(props: ToolbarSlot) => {
            const {Print, Zoom, ZoomIn, ZoomOut} = props;
            return (
              <Grid container xs={12} sx={{minWidth: '32px'}}>
                <Grid item xs={12} container sx={{justifyContent: 'flex-start', alignItems: 'center'}}>
                  <Grid item sx={{paddingX: '2px'}}>
                    <Print />
                  </Grid>
                  <Grid item sx={{paddingX: '2px'}}>
                    <Download />
                  </Grid>
                  <Grid item sx={{paddingX: '2px'}}>
                    <EnterFullScreen />
                  </Grid>
                  <Grid item sx={{paddingLeft: '20px', alignContent: 'center'}}>
                    <ZoomOut />
                  </Grid>
                  <Grid item sx={{paddingX: '2px'}}>
                    <Zoom />
                  </Grid>
                  <Grid item sx={{paddingRight: '20px'}}>
                    <ZoomIn />
                  </Grid>
                </Grid>
              </Grid>
            );
          }}
        </Toolbar>
      </Grid>
      <Grid item container sx={{height: 'calc(100% - 40px)', overflowY: 'scroll', display: 'flex', alignItems: 'center'}}>
        <Grid item container>
          <Worker workerUrl='https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js'>
            {file ?
              <Viewer
                fileUrl={file as any}
                plugins={[toolbarPluginInstance, fullScreenPluginInstance, scrollModePluginInstance, getFilePluginInstance]}
                defaultScale={0.9}
              />
              :
              <Loading />}
          </Worker>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ViewerPDF;