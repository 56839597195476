import {Button, Checkbox, FormControlLabel, FormGroup, Grid, Typography} from '@mui/material';
import React from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';


type ContentProps = {
  statusFeatureSelectOptions: any,
  handleChange: Function,
  handleStepChange: Function,
  t: Function
}

type FooterProps = {
  statusFeatureSelectOptions: any,
  handleStepChange: Function,
  t: Function
}

export const DelayModalSelectReasonsContent = (props: ContentProps) => {
  const showDelayReasons = (columnNumber) => {

    const numberOfItemsInFirstColumn = Math.ceil(props.statusFeatureSelectOptions.length / 2);
    return props.statusFeatureSelectOptions
      .filter((x, index) => columnNumber === 1 ? index < numberOfItemsInFirstColumn : index >= numberOfItemsInFirstColumn)
      .map((reason) => {
        return (
          <FormControlLabel
            checked={reason.checked}
            key={reason.key}
            onChange={() => props.handleChange(reason.key)}
            control={<Checkbox />}
            label={props.t(`commenttypes:${reason.key}`)}
          />
        );
      });
  };

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Typography typography={'h6'}>
          {props.t('modals:delay_modal_title')}
        </Typography>
      </Grid>
      <Grid item xs={12} marginBottom={5}>
        <Typography typography={'body'}>
          {props.t('modals:delay_modal_body')}
        </Typography>
      </Grid>
      <Grid item xs={6} style={{paddingLeft: 30}}>
        <FormGroup>
          {showDelayReasons(1)}
        </FormGroup>
      </Grid>
      <Grid item xs={6} style={{paddingLeft: 30}}>
        <FormGroup>
          {showDelayReasons(2)}
        </FormGroup>
      </Grid>
    </React.Fragment>
  );
};

export const DelayModalSelectReasonsFooter = (props: FooterProps) => {
  return (
    <Grid item xs={12} container justifyContent='flex-end' alignItems='flex-end' height={'100%'}>
      <Grid item xs={6} />
      <Grid item xs={6} style={{justifyContent: 'flex-end', display: 'flex', paddingRight: 20, paddingBottom: 20}}>
        <Button
          disabled={!props.statusFeatureSelectOptions.filter(x => x.checked).length}
          onClick={() => props.handleStepChange('next')}
          variant={'contained'}
          color={'info'}
        >
          {props.t('modals:next')}
          <ArrowForwardIosIcon fontSize='small' style={{marginLeft: 10}} />
        </Button>
      </Grid>
    </Grid>
  );
};