import {Box, Card, Collapse, Grid, IconButton, Stack, Tabs, Typography} from '@mui/material';
import {DataGridPro} from '@mui/x-data-grid-pro';
import React, {useEffect, useState} from 'react';
import {getAuctionBundles} from '../../services/kooper';
import {formatCurrency, formatDate} from '../../utils/formatHelpers';
import ArrowCircleRightSharpIcon from '@mui/icons-material/ArrowCircleRightSharp';
import ThumbsUpIcon from '../../assets/icons/thumbsUp.svg';
import {AuctionPackageStatus} from './AuctionPackageStatus';

type Props = {
  t: Function,
  collapse: boolean,
  auctionId: number,
  navigate: Function,
  auctionStatusKey: string
}

export const CollapsedAuction = (props: Props) => {

  const [bundles, setBundles] = useState<any>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (props.collapse) {
      getAuctionBundleList();
    }
  }, [props.collapse]);

  const getAuctionBundleList = async () => {
    setLoading(true);
    const filter = {
      where: {auctionId: props.auctionId},
    };
    const res = await getAuctionBundles(1, 25, filter);
    setBundles(res.page);
    setLoading(false);
    return res.page;
  };

  return (
    <Collapse in={props.collapse}>
      <Grid container sx={{backgroundColor: '#fff'}}>
        <Box sx={{width: '100%'}}>
          <DataGridPro
            autoPageSize
            disableSelectionOnClick
            hideFooter
            disableColumnMenu
            disableColumnResize
            loading={loading}
            sx={{
              '.MuiDataGrid-row': {
                borderTopStyle: '1px',
              },
              '.MuiDataGrid-cell': {
                fontSize: '14px', fontWeight: 500,
              },
              '.MuiDataGrid-columnHeader': {color: '#32BEE1', fontSize: '15px'},
              '.MuiDataGrid-columnHeaderTitle': {fontWeight: 400},
              width: '100%',
              height: 56 + 52 * (bundles.length || 1),
            }}

            columns={[
              {
                headerName: props.t('auctions:combi_auction_name'),
                field: 'id',
                headerAlign: 'left',
                align: 'left',
                flex: 9,
                sortable: false,
                renderCell: params => {
                  return <>
                    <Typography sx={{fontSize: '14px', fontWeight: 500, marginRight: '10px'}}>
                      {`${props.t('auctions:auction_package')} ${params.row.id}`}
                    </Typography>
                    <AuctionPackageStatus
                      statusKey={params.row.statusKey}
                      t={props.t}
                      previouslySeen={!!params.row.seenAt}
                    />
                  </>
                },
              },
              {
                headerName: props.t('auctions:combi_auction_total_positions'),
                field: 'numberOfPartConfigs',
                headerAlign: 'center',
                align: 'center',
                flex: 4,
                sortable: false,
              },
              {
                headerName: props.t('auctions:combi_auction_delivery_date'),
                field: 'deliveryDate',
                headerAlign: 'center',
                align: 'center',
                flex: 4,
                sortable: false,
                valueFormatter: params => formatDate(params.value),
              },
              {
                headerName: props.t('auctions:combi_auction_total_round_one'),
                field: 'firstRoundPrice',
                headerAlign: 'center',
                align: 'center',
                flex: 4,
                sortable: false,
                renderCell: params => (
                  <>
                    {params.row.statusKey == 'first_round' ?
                      <Typography sx={{fontStyle: 'italic'}}> {props.t('auctions:combi_auction_submit_your_bid')}</Typography> :
                      params.row.firstRoundPrice ? formatCurrency(params.row.firstRoundPrice) : '-'}
                  </>
                ),
              },
              {
                headerName: props.t('auctions:combi_auction_total_round_two'),
                field: 'secondRoundPrice',
                headerAlign: 'center',
                align: 'center',
                flex: 4,
                sortable: false,
                renderCell: params => (
                  <>
                    {
                      params.row.statusKey == 'second_round' ?
                        <Typography sx={{fontStyle: 'italic'}}> {props.t('auctions:combi_auction_submit_your_bid')}</Typography> :
                        params.row.secondRoundPrice ? formatCurrency(params.row.secondRoundPrice) : '-'
                    }
                  </>
                ),
              },
              {
                headerName: props.t('auctions:combi_auction_final_bid'),
                field: 'finalBidPrice',
                headerAlign: 'center',
                align: 'center',
                flex: 4,
                sortable: false,
                renderCell: params => (
                  <>
                    {params.row.wonPrice ? formatCurrency(params.row.wonPrice) : '-'}
                    {params.row.statusKey == 'win' &&
                      <div style={{display: 'flex', justifyContent: 'center', marginLeft: '10px'}}>
                        <img style={{height: '15px'}} src={ThumbsUpIcon} />
                      </div>
                    }
                  </>
                ),
              },
              {
                headerName: '',
                field: 'goToAuctionPackage',
                flex: 1,
                sortable: false,
                align: 'center',
                renderCell: params =>
                  <IconButton onClick={() => props.navigate(`/auctions/${params.row.id}`)}>
                    <ArrowCircleRightSharpIcon color={'primary'} />
                  </IconButton>,
              },
            ]}
            rows={bundles}
          />
        </Box>
      </Grid>
    </Collapse>
  );
};