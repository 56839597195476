import {Box, Grid, Tooltip} from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';

type Props = {
  t: Function,
  partName: string,
  articleNr: string,
  drawingNr: string,
  materialIsUrl: boolean,
  materialName: string,
  materialNames: string[],
  reorderedOpcs: any,
  orderedPartPartFeatures: { partFeatureKey: string, value: string }[]
}

export const getPartDimensions = (partPartFeatures: {partFeatureKey: string, value: string, valueAuto?: string}[]) => {
  const isRotatory = partPartFeatures.some(x => x.partFeatureKey === 'outer_diameter' && (x.value?.trim().length || x.valueAuto?.trim().length));

  let x = partPartFeatures.find(x => x.partFeatureKey === 'width'),
    y = partPartFeatures.find(x => x.partFeatureKey === 'height'),
    z = partPartFeatures.find(x => x.partFeatureKey === 'length');

  if (isRotatory) {
    x = partPartFeatures.find(x => x.partFeatureKey === 'outer_diameter');
    y = partPartFeatures.find(x => x.partFeatureKey === 'outer_diameter');
    z = partPartFeatures.find(x => x.partFeatureKey === 'length');
  }

  if (!x || !y || !z) {
    return ' - ';
  }

  return `${x.value?.trim() || x.valueAuto?.trim()} mm x ${y.value?.trim() || y.valueAuto?.trim()} mm x ${z.value?.trim() || y.valueAuto?.trim()} mm`;
};

export const CollapsablePartDetailsPartInfo = (props: Props) => {
  return <Grid item container xs={12} alignItems={'center'} display={'flex'} height={'100%'}>
    <Grid item container xs={12} alignItems={'center'}>
      <Grid item display={'block'} width={'100%'}>
        <Box display={'flex'} alignItems={'center'}>
          <Typography variant="subtitle1">
            {props.t('bundledetailspage:bs_partlist_body_name')}:
          </Typography>
          <Typography marginLeft={1} variant="subtitle2" noWrap>{props.partName} </Typography>
          {props.reorderedOpcs.length > 0 &&
            <Tooltip
              arrow
              placement={'top-start'}
              title={
                <Typography sx={{color: '#FFF'}}>
                  {`${props.t('bundledetailspage:bs_tooltip_part_produced_in_bundle')}:`}
                  <br />
                  <br />
                  {props.reorderedOpcs
                    .slice(0, 5)
                    .map((ropc: any) => (
                      <ul style={{marginLeft: '15px'}}>
                        <li>
                          <a
                            style={{color: '#FFF'}}
                            href={`/bundle-suppliers/${ropc.bundleId}`}
                          >
                            {`KAKTUS-${ropc.bundleId}`}
                          </a>
                        </li>
                      </ul>
                    ))}
                </Typography>
              }
            >
              <Typography
                sx={{
                  backgroundColor: '#C1EBF6',
                  marginLeft: '10px',
                  paddingX: '8px',
                  borderRadius: '1px',
                  display: 'flex',
                  alignItems: 'center',
                }}
                variant="subtitle2"
              >
                {props.t('bundledetailspage:bs_partlist_reorder').toUpperCase()}
              </Typography>
            </Tooltip>
          }
        </Box>
      </Grid>
    </Grid>
    <Grid item container alignItems={'flex-start'}>
      <Grid item display={'block'} width={'100%'}>
        <Box display={'flex'} alignItems={'center'}>
          <Typography variant="subtitle1">
            {props.t('bundledetailspage:bs_partlist_body_articlenumber')}:
          </Typography>
          <Typography marginLeft={1} variant="subtitle2" noWrap>{props.articleNr} </Typography>
        </Box>
      </Grid>
    </Grid>
    <Grid item container alignItems={'flex-start'}>
      <Grid item display={'block'} width={'100%'}>
        <Box display={'flex'} alignItems={'center'}>
          <Typography variant="subtitle1">
            {props.t('bundledetailspage:bs_partlist_body_drawingnumber')}:
          </Typography>
          <Typography marginLeft={1} variant="subtitle2" noWrap>{props.drawingNr} </Typography>
        </Box>
      </Grid>
    </Grid>
    <Grid item container alignItems={'flex-start'}>
      <Grid item display={'block'} width={'100%'}>
        <Box display={'flex'} alignItems={'center'}>
          <Typography variant="subtitle1">
            {props.t('bundledetailspage:bs_partlist_body_material')}:
          </Typography>
          {props.materialIsUrl ?
            <Typography marginLeft={1} variant="subtitle2">
              <a href={props.materialName} target={'_blank'}>{'Link'}</a>
            </Typography> :
            <Tooltip arrow title={props.materialNames.join(' / ')}>
              <Typography marginLeft={1} variant="subtitle2">
                {props.materialName}
              </Typography>
            </Tooltip>}
        </Box>
      </Grid>
    </Grid>
    <Grid item container alignItems={'flex-start'}>
      <Grid item display={'block'} width={'100%'}>
        <Box display={'flex'} alignItems={'center'}>
          <Typography variant="subtitle1">
            {props.t('bundledetailspage:bs_partlist_body_xyz')}:
          </Typography>
          <Typography marginLeft={1} variant="subtitle2" noWrap>{getPartDimensions(props.orderedPartPartFeatures)} </Typography>
        </Box>
      </Grid>
    </Grid>
    <Grid item container alignItems={'flex-start'}>
      <Grid item display={'block'} width={'100%'}>
        <Box display={'flex'} alignItems={'center'}>
          <Typography variant="subtitle1">
            {props.t('bundledetailspage:bs_partlist_weight')}:
          </Typography>
          <Typography marginLeft={1} variant="subtitle2">
            {`${(props.orderedPartPartFeatures.find(oppf => oppf.partFeatureKey === 'weight')?.value as unknown as number / 1000).toFixed(2)} KG`}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  </Grid>;
};