import {Card, Grid} from '@mui/material';
import Typography from '@mui/material/Typography';
import {formatCurrency} from '../../../utils/formatHelpers';
import React from 'react';
import {getPartDimensions} from '../../BundleDetailsPage/CollapsablePartDetails/CollapsablePartDetails.PartInfo';


type Props = {
  currentBopc: any,
  t: Function
}

export const SidePanelInfo = (props: Props) => {

  return (
    <Grid container key={'side-panel'}>
      <Grid item container key={'details-container'} sx={{height: '100px', marginTop: '100px', marginX: '30px'}}>
        <Grid item xs={12} key={'part-name'}>
          <Card sx={{borderWidth: '0px 0px 0.3px 0px ', borderRadius: '0px', borderColor: '#5A6078', backgroundColor: '#F9F9F9'}}>
            <Grid container xs={12}>
              <Grid item container xs={5} sx={{justifyContent: 'flex-start', alignContent: 'flex-end'}}>
                <Typography sx={{fontSize: '11px', color: '#5A6078', fontWeight: 600, textTransform: 'uppercase'}}>
                  {props.t('modals:zoom_modal_side_panel_info_part_name')}
                </Typography>
              </Grid>
              <Grid item container xs={7} justifyContent={'flex-end'}>
                <Typography sx={{fontSize: '11px', color: '#131C3E', fontWeight: 500}}>
                  {props.currentBopc.orderedPartConfig.orderedPart.name}
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} key={'material-name'}>
          <Card sx={{borderWidth: '0px 0px 0.3px 0px ', borderRadius: '0px', borderColor: '#5A6078', backgroundColor: '#F9F9F9', marginTop: '10px'}}>
            <Grid container xs={12}>
              <Grid item container xs={5} sx={{justifyContent: 'flex-start', alignContent: 'flex-end'}}>
                <Typography sx={{fontSize: '11px', color: '#5A6078', fontWeight: 600, textTransform: 'uppercase'}}>
                  {props.t('modals:zoom_modal_side_panel_info_material_name')}
                </Typography>
              </Grid>
              <Grid item container xs={7} justifyContent={'flex-end'}>
                <Typography sx={{fontSize: '11px', color: '#131C3E', fontWeight: 500}}>
                  {props.currentBopc.orderedPartConfig.materialName}
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} key={'Quantity'}>
          <Card sx={{borderWidth: '0px 0px 0.3px 0px ', borderRadius: '0px', borderColor: '#5A6078', backgroundColor: '#F9F9F9', marginTop: '10px'}}>
            <Grid container xs={12}>
              <Grid item container xs={5} sx={{justifyContent: 'flex-start', alignContent: 'flex-end'}}>
                <Typography sx={{fontSize: '11px', color: '#5A6078', fontWeight: 600, textTransform: 'uppercase'}}>
                  {props.t('modals:zoom_modal_side_panel_info_quantity')}
                </Typography>
              </Grid>
              <Grid item container xs={7} justifyContent={'flex-end'}>
                <Typography sx={{fontSize: '11px', color: '#131C3E', fontWeight: 500}}>
                  {props.currentBopc.orderedPartConfig.batchSize}
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} key={'dimensions'}>
          <Card sx={{borderWidth: '0px 0px 0.3px 0px ', borderRadius: '0px', borderColor: '#5A6078', backgroundColor: '#F9F9F9', marginTop: '10px'}}>
            <Grid container xs={12}>
              <Grid item container xs={5} sx={{justifyContent: 'flex-start', alignContent: 'flex-end'}}>
                <Typography sx={{fontSize: '11px', color: '#5A6078', fontWeight: 600, textTransform: 'uppercase'}}>
                  {props.t('modals:zoom_modal_side_panel_info_dimensions')}
                </Typography>
              </Grid>
              <Grid item container xs={7} justifyContent={'flex-end'}>
                <Typography sx={{fontSize: '11px', color: '#131C3E', fontWeight: 500}}>
                  {getPartDimensions(props.currentBopc.orderedPartConfig.orderedPart.orderedPartPartFeatures)}
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>

        <Grid item xs={12} key={'weight'}>
          <Card sx={{borderWidth: '0px 0px 0.3px 0px ', borderRadius: '0px', borderColor: '#5A6078', backgroundColor: '#F9F9F9', marginTop: '10px'}}>
            <Grid container xs={12}>
              <Grid item container xs={5} sx={{justifyContent: 'flex-start', alignContent: 'flex-end'}}>
                <Typography sx={{fontSize: '11px', color: '#5A6078', fontWeight: 600, textTransform: 'uppercase'}}>
                  {props.t('modals:zoom_modal_side_panel_info_weight')}
                </Typography>
              </Grid>
              <Grid item container xs={7} justifyContent={'flex-end'}>
                <Typography sx={{fontSize: '11px', color: '#131C3E', fontWeight: 500}}>
                  {`${(props.currentBopc.orderedPartConfig.orderedPart.orderedPartPartFeatures.find(oppf => oppf.partFeatureKey === 'weight')?.value / 1000).toFixed(2)} KG`}
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item container key={'price'} xs={12} display={'flex'} sx={{marginTop: '20px'}}>
          <Grid item container xs={6} justifyContent={'flex-start'}>
            <Typography sx={{fontSize: '13px', color: '#131C3E', fontWeight: 700, textTransform: 'uppercase'}}>
              {props.t('modals:zoom_modal_side_panel_price_per_piece')}
            </Typography>
          </Grid>
          <Grid item container xs={6} justifyContent={'flex-end'}>
            <Typography sx={{fontSize: '13px', color: '#131C3E', fontWeight: 700, textTransform: 'uppercase'}}>
              {`${formatCurrency(props.currentBopc.pricePerPiece)}`}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>

  );
};