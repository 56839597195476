import React, {useEffect} from 'react';
import {CardContent, Grid, IconButton} from '@mui/material';
import {Card} from '@mui/material';
import Typography from '@mui/material/Typography';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import {CollapsedPart} from '../CollapsedPart';
import KeyboardDoubleArrowUpOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowUpOutlined';
import Thumbnail from '../../Thumbnails/Thumbnails';
import {constructBundleOrderedPartConfigStlURL, constructBundleOrderedPartConfigThumbnailURL, getReorderedOpcs} from '../../../services/kooper';
import {useAtom} from 'jotai';
import {CounterOfferPrices} from '../../../global-atoms';
import {CollapsablePartDetailsIdCheckbox} from './CollapsablePartDetails.IdCheckbox';
import {CollapsablePartDetailsPricePerPiece} from './CollapsablePartDetails.PricePerPiece';
import {CollapsablePartDetailsTotalPrice} from './CollapsablePartDetails.TotalPrice';
import {CollapsablePartDetailsPartInfo} from './CollapsablePartDetails.PartInfo';

type Props = {
  t: Function,
  bundle: any
  bundleOrderedPartConfig: any
  index: number,
  totalItems: number,
  collapse: boolean,
  updateBundlePage: Function,
  handleCollapse: Function,
  navigate: Function
}

export const CollapsablePartDetails = (props: Props) => {

  const [counterOfferPrices, setCounterOfferPrices] = useAtom(CounterOfferPrices);
  const [rowSelected, setRowSelected] = React.useState(false);
  const [rowHovered, setRowHovered] = React.useState(false);
  const [reorderedOpcs, setReorderedOpcs] = React.useState<any[]>([]);

  /* Select first row when page is loaded*/
  useEffect(() => {
    if (isRowSelectable && props.index === 0) {
      setRowSelected(true);
    }
  }, []);

  /* Set active = false in the counter-offer prices if row is not selected */
  useEffect(() => {
    const currEntry = counterOfferPrices.find(x => x.bundleOrderedPartConfigId === props.bundleOrderedPartConfig.id) || {
      bundleOrderedPartConfigId: props.bundleOrderedPartConfig.id,
      active: false,
    };
    currEntry.active = !currEntry.active;  // @ts-ignore
    setCounterOfferPrices([...counterOfferPrices.filter(x => x.bundleOrderedPartConfigId !== props.bundleOrderedPartConfig.id), currEntry]);
  }, [rowSelected]);

  /* Reset counteroffer prices when user goes to a new bundle*/
  useEffect(() => {
    setCounterOfferPrices([]);
  }, [props.bundle.id]);

  useEffect(() => {
    getReorderedOpcs(props.bundle.id, props.bundleOrderedPartConfig.id).then((res) => {
      setReorderedOpcs(res);
    });
  }, [props.bundle.id]);

  const {bundleOrderedPartConfig, t, bundle} = props;
  const {batchSize, orderedPartConfigOrderedAttachments, materialName, materialIsUrl} = bundleOrderedPartConfig.orderedPartConfig;
  const {name: partName, articleNr, drawingNr, orderedPartPartFeatures} = bundleOrderedPartConfig.orderedPartConfig.orderedPart;
  const {pricePerPiece, materialNames, counterOfferPrice} = bundleOrderedPartConfig;
  const {orderedPartAttachments} = bundleOrderedPartConfig.orderedPartConfig.orderedPart;
  const attachments = [...orderedPartConfigOrderedAttachments, ...orderedPartAttachments];
  let numberOfAttachments = attachments?.filter(oa => oa.orderedAttachment.attachmentType.key !== 'technical_drawing').length || 0;
  const numberOfBlankedDrawings = attachments?.filter(oa => oa.orderedAttachment.attachmentType.key === 'blanked_technical_drawing').length;
  if (numberOfBlankedDrawings > 1) {
    numberOfAttachments -= numberOfBlankedDrawings - 1;
  }
  const isRowSelectable = ['counter_offer_requested'].includes(props.bundle.statusKey);
  const orderedPartNames = bundle.bundleOrderedPartConfigs?.map(bopc => bopc.orderedPartConfig.orderedPart.name);
  return (
    <Grid item xs={12} sx={{position: 'relative'}}>
      <Card
        sx={{
          borderRadius: props.totalItems === 1 ? '7px 7px 7px 7px' : props.index === 0 ? '7px 7px 0px 0px' : props.index === props.totalItems - 1 ? '0px 0px 7px 7px' : '0px',
          borderColor: '#131C3E',
          borderWidth: props.index === 0 || props.totalItems === 1 ? '1px 1px 1px 1px' : '0px 1px 1px 1px',
          backgroundColor: rowSelected ? 'rgba(0,175,211,0.1)' : '#FFF',
        }}
      >
        <CardContent
          sx={{height: '185px', display: 'flex', paddingRight: '40px'}}
          onMouseEnter={() => setRowHovered(true)}
          onMouseLeave={() => setRowHovered(false)}
        >
          <Grid container>
            <Grid item alignItems={'center'} display={'flex'} width={'40px'}>
              <CollapsablePartDetailsIdCheckbox
                isRowSelectable={isRowSelectable}
                rowHovered={rowHovered}
                rowSelected={rowSelected}
                setRowSelected={setRowSelected}
                bopcId={props.bundleOrderedPartConfig.id}
                index={props.index}
              />
            </Grid>
            <Grid item container alignItems={'center'} display={'flex'} width={'220px'}>
              <Thumbnail
                className={'thumbnail'}
                t={t}
                height={'130px'}
                width={'184px'}
                src={constructBundleOrderedPartConfigThumbnailURL(bundleOrderedPartConfig.bundleId, bundleOrderedPartConfig.id)}
                stlSrc={constructBundleOrderedPartConfigStlURL(bundleOrderedPartConfig.bundleId, bundleOrderedPartConfig.id)}
                id={`bopc-${bundleOrderedPartConfig.orderedPartConfig.id}-thumbnail`}
                partConfigId={bundleOrderedPartConfig.orderedPartConfig.id}
                partName={props.bundleOrderedPartConfig.orderedPartConfig.orderedPart.name}
                bundleOrderedPartConfig={props.bundleOrderedPartConfig}
                orderedPartNames={orderedPartNames}
                bundle={props.bundle}
                index={props.index}
                numberOfBlankedDrawings={numberOfAttachments}
                style={{maxHeight: '100%', maxWidth: '100%'}}
                bundleOrderedPartConfigs={bundle.bundleOrderedPartConfigs}
              />
            </Grid>

            <Grid item container sx={{width: 'calc(100% - 260px)'}}>
              <Grid item container xs={4}>
                <CollapsablePartDetailsPartInfo
                  t={props.t}
                  partName={partName}
                  articleNr={articleNr}
                  drawingNr={drawingNr}
                  materialIsUrl={materialIsUrl}
                  materialName={materialName}
                  materialNames={materialNames}
                  reorderedOpcs={reorderedOpcs}
                  orderedPartPartFeatures={orderedPartPartFeatures}
                />
              </Grid>
              <Grid item container xs={8}>
                <Grid item container xs={12} alignItems={'center'}>
                  <Grid item container xs={4} alignItems={'center'} display={'flex'}>
                    <Grid item xs={12} textAlign={'right'}>
                      <Typography variant="subtitle1">
                        {t('bundledetailspage:bs_partlist_header_quantity')}
                      </Typography>
                      <Typography variant="subtitle2" sx={{fontSize: '12px', fontWeight: 600}}>
                        {batchSize}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item container xs={4} alignItems={'center'}>
                    <Grid item xs={12} textAlign={'right'}>
                      <Typography variant="subtitle1" sx={{marginRight: props.bundle.statusKey === 'counter_offer_requested' ? '5px' : 0}}>
                        {t('bundledetailspage:bs_partlist_header_price_per_unit')}
                      </Typography>
                      <CollapsablePartDetailsPricePerPiece
                        rowSelected={rowSelected}
                        pricePerPiece={pricePerPiece}
                        counterOfferPrice={counterOfferPrice}
                        bundleStatusKey={props.bundle.statusKey}
                        bopcId={props.bundleOrderedPartConfig.id}
                        batchSize={props.bundleOrderedPartConfig.orderedPartConfig.batchSize}
                      />
                    </Grid>
                  </Grid>
                  <Grid item container xs={4} alignItems={'center'} display={'flex'} height={'100%'}>

                    <Grid item xs={12} textAlign={'right'}>
                      <Typography variant="subtitle1">
                        {t('bundledetailspage:bs_partlist_header_total_price')}
                      </Typography>
                      <CollapsablePartDetailsTotalPrice
                        rowSelected={rowSelected}
                        pricePerPiece={pricePerPiece}
                        counterOfferPrice={counterOfferPrice}
                        bundleStatusKey={props.bundle.statusKey}
                        bopcId={props.bundleOrderedPartConfig.id}
                        batchSize={props.bundleOrderedPartConfig.orderedPartConfig.batchSize}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        <CollapsedPart
          t={t}
          bundle={bundle}
          navigate={props.navigate}
          bundleOrderedPartConfig={props.bundleOrderedPartConfig}
          index={props.index}
          collapse={props.collapse}
          updateBundlePage={props.updateBundlePage}
        />
      </Card>
      <div style={{position: 'absolute', left: '50%', zIndex: 1, transform: 'translate(-50%,0)', bottom: '-14px'}}>
        <IconButton
          onClick={() => {
            props.handleCollapse(!props.collapse);
          }}
          sx={{
            '&.MuiIconButton-root:hover': {backgroundColor: '#f9f9f9'},
            height: 30,
            width: 30,
            border: 1,
            borderColor: '#131C3E',
            backgroundColor: '#FFF',
          }}
        >
          {props.collapse ?
            <KeyboardDoubleArrowUpOutlinedIcon fontSize={'small'} sx={{color: '#131C3E', height: 20, width: 20}} /> :
            <KeyboardDoubleArrowDownIcon fontSize={'small'} sx={{color: '#131C3E', height: 20, width: 20}} />}
        </IconButton>
      </div>
    </Grid>
  );
};

