import axios from 'axios';

const sentry = axios.create({
  timeout: 100000,
  baseURL: 'https://sentry.kreatize.com',
});

sentry.interceptors.request.use((request) => {
  request.headers = request.headers || {};
  request.headers.Authorization = `DSN ${process.env.REACT_APP_SENTRY_DSN}`;
  return request;
});

export const submitUserFeedback = async (message: string, eventId: string, userInfo: { firstName: string, lastName: string, email: string }) => {
  return sentry.post(`/api/0/projects/kreatize/kaktus/user-feedback/`, {
    event_id: eventId,
    name: `${userInfo.firstName} ${userInfo.lastName}`,
    email: userInfo.email,
    comments: message,
  });
};
