import React from 'react';


/**
 * Convert \n linebreaks into <br/> break tags
 * @param text - String that contains \n break tags
 * @returns {*} -> An Array of React.Fragments that are split by <br/>'s
 */
export const insertLineBreakes = (text) => {
  return text.split('\n').map((item, key) => {
    return <React.Fragment key={key}>{item}<br /></React.Fragment>;
  });
};

export const camelToSnakeCase = str => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

export const snakeToCamelCase = str => str.replace(/(_\w)/g, (matches) => matches[1].toUpperCase());