import React from 'react';
import {sendContactEmail} from '../../../services/kooper';
import {logEvent} from '../../../utils/firebase';
import {BaseModalStage, MuiBaseModal} from '../MuiBaseModal';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import {Button, Grid, TextField, Typography} from '@mui/material';


interface State {
  open: boolean,
  stage: BaseModalStage
  message: string
}

interface Props {
  t: Function,
  bundleId: number,
}

export class MessageModal extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      open: false,
      stage: 'content',
      message: '',
    };
  }

  handleSubmit = () => {
    this.setState({stage: 'loading'});
    sendContactEmail(this.props.bundleId, this.state.message)
      .then(() => {
        logEvent('contact_modal_message_sent');
        this.setState({stage: 'success'});

      })
      .catch(() => this.setState({stage: 'failure'}));
  };

  handleToggle = (state?: any) => {
    if (typeof state === 'boolean') {
      this.setState({
        open: state,
        stage: 'content',
      });
    } else {
      this.setState({
        open: !this.state.open,
        stage: 'content',
      });
    }
  };

  addLineBreaks(string: string) {
    return string.split('<br />').map((text, index) => (
      <React.Fragment key={`${text}-${index}`}>
        {text}
        <br />
      </React.Fragment>
    ));
  }

  content = () => {
    const {t} = this.props;
    return (
      <Grid container rowSpacing={4}>
        <Grid item xs={12}>
          <Typography>{this.addLineBreaks(t('modals:message_modal_body'))}</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            multiline
            fullWidth
            placeholder={t('modals:message_modal_text_input')}
            onChange={(e: any) => this.setState({message: e.target.value})}
            value={this.state.message}
            minRows={5}
          />
        </Grid>
      </Grid>
    );
  };

  footer = () => {
    return (
      <Grid item xs={12} container justifyContent='flex-end' alignItems='flex-end' height={'100%'}>
        <Grid item xs={6} />
        <Grid item xs={6} style={{justifyContent: 'flex-end', display: 'flex', paddingRight: 20, paddingBottom: 20}}>
          <Button
            color={'primary'}
            disabled={this.state.message.length < 1}
            onClick={() => this.handleSubmit()}
            endIcon={<SendRoundedIcon />}
          >
            {this.props.t('modals:message_modal_submit_button')}
          </Button>
        </Grid>
      </Grid>
    );
  };

  render() {
    const {t} = this.props;
    return (
      <MuiBaseModal
        stage={this.state.stage}
        button={
          <Button
            onClick={() => this.setState({open: true})}
            color={'primary'}
            fullWidth
            endIcon={<SendRoundedIcon sx={{fontSize: 'medium', color: 'white', marginLeft: 1}}></SendRoundedIcon>}
          >
            {this.props.t('bundledetailspage:bs_button_contact')}
          </Button>
        }
        footer={this.footer()}
        open={this.state.open}
        onToggle={() => this.handleToggle()}
        content={this.content()}
        height={'80vh'}
        successMessageTitle={t('modals:message_modal_success_message')}
        errorMessageTitle={t('modals:message_modal_failure_message')}
        header={t('modals:message_modal_title')}
      />
    );
  }
}