import {Button, Col, Row} from 'shards-react';
import Select             from 'react-select';
import React, {useEffect} from 'react';
import {logEvent}         from '../../utils/firebase';


interface SelectObject {
  label: string,
  value: string
}

interface FilterObject {
  search?: {
    value: string,
    label: string
  },
  key?: {
    value: string,
    label: string
  },
  orderBy?: {
    value: string,
    label: string
  }
}

export const FilterBar = (props: any) => {

  const [filter, setFilter] = React.useState<FilterObject>({});

  useEffect(() => {
    if (filter.search) {
      // @ts-ignore
      if (!props.searchOptions.some(x => x.value === filter.search.value)) {
        // @ts-ignore
        setFilter({search: null});
      }
    }
  }, [props.searchOptions]);

  const handleChange = (type: string, value: SelectObject) => {

    if (!value) {
      const newFilter = {...filter};
      // @ts-ignore
      delete newFilter[type];
      setFilter(newFilter);
    }
    else {
      setFilter({...filter, ...{[type]: value}});
    }
  };

  const handleApply = () => {
    logEvent('self_service_filter_used');
    const urlParams = new URLSearchParams();
    Object.entries(filter).filter(([key, value]) => !!value).forEach(([key, value]) => urlParams.append(key, value.value));

    if (!!urlParams.toString()) {
      window.location.hash = `?${urlParams.toString()}`;
    }
    else {
      window.location.hash = '';
    }
  };

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      borderColor: 'red',
      '&:hover': {
        borderColor: 'red',
      },
    }),
  };

  const buttonDisabled = filter.key && !filter.orderBy || !filter.key && filter.orderBy;

  return (
    <Row>
      <Col lg={4}>
        <Row>
          <Col style={{display: 'flex'}}>
            <div style={{alignSelf: 'center', width: '100px'}}>Filter:</div>
            <div style={{width: '300px', marginLeft: '50px'}}>
              <Select
                options={props.searchOptions}
                onChange={((value: any) => handleChange('search', value))}
                value={filter.search}
                isClearable
                isDisabled={!!filter.orderBy || !!filter.key}
              />
            </div>
          </Col>
        </Row>

      </Col>
      <Col>
        <Button
          onClick={() => handleApply()}
          disabled={buttonDisabled}
        >
          {!filter.search && !filter.orderBy && !filter.key ? props.t('selfService:button_clear_filter') : props.t('selfService:button_apply_filter')}
        </Button>
      </Col>
    </Row>
  );
};
