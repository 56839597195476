import React from 'react';
import {getStatusFeatureSelectOptions, updateBundleStatus} from '../../../services/kooper';
import {formatISODateWithoutTime} from 'utils/datetimeHelpers';
import {logEvent} from '../../../utils/firebase';
import {BaseModalStage, MuiBaseModal} from '../MuiBaseModal';
import {Grid, Step, StepLabel, Stepper, Button} from '@mui/material';
import {DelayModalSelectReasonsContent, DelayModalSelectReasonsFooter} from './DelayModal.SelectReasons';
import {DelayModalSelectDateContent, DelayModalSelectDateFooter} from './DelayModal.SelectDate';


interface Props {
  updateBundlePage: Function,
  t: Function,
  bundleId: number,
}

interface State {
  open: boolean,
  stage: BaseModalStage,
  activeStep: 'select_date' | 'select_reasons',
  statusFeatureSelectOptions: Array<any>,
  newDate: any,
  steps: ['select_reasons', 'select_date'],
}

export class DelayModal extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      open: false,
      statusFeatureSelectOptions: [],
      stage: 'content',
      newDate: new Date(),
      steps: ['select_reasons', 'select_date'],
      activeStep: 'select_reasons',
    };
  }

  componentDidMount() {
    getStatusFeatureSelectOptions('delay_reason')
      .then((x) => {
        const keysWithChecked = x.data.statusFeatureSelectOptions.map((reason: any) => ({key: reason.selectOptionKey, checked: false}));
        this.setState({statusFeatureSelectOptions: keysWithChecked});
      })
      .catch(() => {
        this.setState({stage: 'failure'});
      });
  }

  handleSubmit() {
    this.setState({stage: 'loading'});
    let statusFeatures = this.state.statusFeatureSelectOptions.filter(x => x.checked).map(x => ({key: 'delay_reason', value: x.key}));
    statusFeatures.push({key: 'delivery_date', value: formatISODateWithoutTime(this.state.newDate)});
    updateBundleStatus(this.props.bundleId, 'delayed', statusFeatures)
      .then(() => {
        logEvent('bundle_delayed');
        this.setState({stage: 'success'});
      })
      .catch(() => this.setState({stage: 'failure'}));
  }

  handleChange = (key: string) => {
    let statusFeatureSelectOptionsCopy = [...this.state.statusFeatureSelectOptions];
    const currentSelectOption = statusFeatureSelectOptionsCopy.find(x => x.key === key);
    currentSelectOption.checked = !currentSelectOption.checked;
    this.setState({statusFeatureSelectOptions: statusFeatureSelectOptionsCopy});
  };

  handleToggle() {
    if (this.state.open && this.state.stage === 'success') {
      this.props.updateBundlePage();
    }
    this.setState({
      open: !this.state.open,
    });
  }

  handleStepChange = (direction: 'next' | 'previous') => {
    const currStepIndex = this.state.steps.findIndex(x => x === this.state.activeStep);
    this.setState({activeStep: this.state.steps[direction === 'next' ? currStepIndex + 1 : currStepIndex - 1]});
  };

  content = () => {
    return (
      <Grid container>
        <Grid item container xs={12} alignItems={'flex-start'}>
          {this.state.activeStep === 'select_reasons' &&
            <DelayModalSelectReasonsContent
              t={this.props.t}
              statusFeatureSelectOptions={this.state.statusFeatureSelectOptions}
              handleChange={(key) => this.handleChange(key)}
              handleStepChange={(direction) => this.handleStepChange(direction)}
            />
          }

          {this.state.activeStep === 'select_date' &&
            <DelayModalSelectDateContent
              t={this.props.t}
              handleNewDate={(date) => this.setState({newDate: date})}
              handleStepChange={(direction) => this.handleStepChange(direction)}
              newDate={this.state.newDate}
            />
          }
        </Grid>
      </Grid>
    );
  };

  renderStepper = () => {
    if (this.state.stage !== 'content') {
      return undefined;
    }
    return (
      <Stepper activeStep={this.state.steps.findIndex(x => x === this.state.activeStep)} alternativeLabel style={{width: '100%', height: '20px'}}>
        {this.state.steps.map(key =>
          <Step key={key}>
            <StepLabel sx={{'.MuiStepLabel-labelContainer': {display: 'none'}}}>
              {key}
            </StepLabel>
          </Step>)
        }
      </Stepper>
    );
  };

  footer = () => {
    return (
      <React.Fragment>
        {this.state.activeStep === 'select_reasons' &&
          <DelayModalSelectReasonsFooter
            {...this.props}
            handleStepChange={(dir) => this.handleStepChange(dir)}
            statusFeatureSelectOptions={this.state.statusFeatureSelectOptions}
          />}

        {this.state.activeStep === 'select_date' &&
          <DelayModalSelectDateFooter
            {...this.props}
            handleStepChange={(dir) => this.handleStepChange(dir)}
            handleSubmit={() => this.handleSubmit()}
          />}
      </React.Fragment>
    );
  };

  render() {
    const {t} = this.props;
    return (
      <MuiBaseModal
        stage={this.state.stage}
        button={<Button
          onClick={() => this.setState({open: true})}
          variant='outlined'
          color={'secondary'}
          size={'small'}
          fullWidth
        >
          {t('bundledetailspage:bs_action_panel_button_delayed')}
        </Button>
        }
        open={this.state.open}
        onToggle={() => this.handleToggle()}
        content={this.content()}
        header={this.renderStepper()}
        loadingMessageTitle={t('modals:delay_modal_loading_message')}
        successMessageTitle={t('modals:delay_modal_success_message')}
        height={'80vh'}
        footer={this.state.stage === 'content' ? this.footer() : undefined}
      />
    );
  }
}

// @ts-ignore
export default DelayModal;
