import {Card, CardContent, Grid, IconButton} from '@mui/material';
import Typography from '@mui/material/Typography';
import {CollapsedAuction} from './CollapsedAuction';
import React, {useState} from 'react';
import {KeyboardArrowDown, KeyboardArrowUp} from '@mui/icons-material';
import {AuctionStatus} from './AuctionStatus';


type Props = {
  t: Function,
  navigate: Function,
  auctionId: number,
  auctionStatusKey: string,
  index: number,
}

export const CollapsableAuctionDetails = (props: Props) => {

  const [collapse, setCollapse] = useState(props.index == 0);

  return (
    <Grid item xs={12} sx={{alignContent: 'flex-start', marginTop: '20px'}}>
      <Card sx={{
        borderColor: '#D1D5DB',
        borderRadius: '5px',
      }}
      >
        <CardContent sx={{paddingX: '10px'}}>
          <Grid container xs={12} key={'card-title-and-round'} sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', backgroundColor: '#F3F4F6'}}>
            <Grid item container xs={6} alignItems={'center'}>
              <Grid item>
                <Typography sx={{fontWeight: '600', fontSize: '17px', color: '#101C3E'}}>
                  {props.t('auctions:combi_auction_auction_id')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography sx={{fontWeight: '600', fontSize: '17px', color: '#101C3E', marginLeft: '10px'}}>{props.auctionId}</Typography>
              </Grid>
              <AuctionStatus statusKey={props.auctionStatusKey} t={props.t}></AuctionStatus>
            </Grid>
            <Grid item xs={6} key={'arrow'} justifyContent={'flex-end'} display={'flex'}>
              <IconButton onClick={() => setCollapse(!collapse)}>
                {collapse ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              </IconButton>
            </Grid>
          </Grid>
        </CardContent>
        <CollapsedAuction t={props.t} navigate={props.navigate} collapse={collapse} auctionId={props.auctionId} auctionStatusKey={props.auctionStatusKey} />
      </Card>
    </Grid>);

};