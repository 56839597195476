import React, {Component} from 'react';
import BaseModal from '../BaseModal';
import {withTranslation} from 'react-i18next';
import {Button, Collapse, FormInput} from 'shards-react';
import {updateKooperPassword} from '../../../services/kooper';
import {logEvent} from '../../../utils/firebase';

const passwordValidator = require('password-validator');

const kooperSchema = new passwordValidator();
kooperSchema
  .is().min(12)
  .has().digits(1)
  .has().symbols(1)
  .has().uppercase()
  .has().lowercase()
  .has().not().spaces();

export class ChangePasswordModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stage: 'content',
      oldPassword: '',
      password: '',
      confirmedPassword: '',
      collapseOpen: false,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {

    if (this.state.password !== prevState.password) {
      if (kooperSchema.validate(this.state.password)) {
        this.setState({collapseOpen: false});
      } else {
        this.setState({collapseOpen: true});
      }
    }
  }

  handleSubmit = () => {
    const {confirmedPassword, oldPassword} = this.state;
    this.setState({stage: 'loading'});
    updateKooperPassword(oldPassword, confirmedPassword)
      .then(() => {
        this.setState({stage: 'success'});
        logEvent('password_changed');

      })
      .catch(() => this.setState({stage: 'failure'}));
  };

  content = () => {
    const minPasswordLength = 6;
    const {t} = this.props;

    return (
      <div>
        <p>{t('password_modal_body')}</p>
        <div>{t('password_modal_old_password')}</div>
        <FormInput
          type={'password'}
          placeholder={t('password_modal_old_password')}
          className={'my-2'}
          autoComplete="new-password"
          value={this.state.oldPassword}
          onChange={(e) => {this.setState({oldPassword: e.target.value});}}
          valid={this.state.oldPassword?.length >= minPasswordLength}
        />
        <div>{t('password_modal_new_password')}</div>
        <FormInput
          type={'password'}
          placeholder={t('password_modal_password')}
          className={'my-2'}
          autoComplete="new-password"
          value={this.state.password}
          onChange={(e) => {
            this.setState({password: e.target.value});
          }}
          valid={kooperSchema.validate(this.state.password)}
        />
        <FormInput
          type={'password'}
          placeholder={t('password_modal_confirm_password')}
          className={'my-2'}
          autoComplete="new-password"
          value={this.state.confirmedPassword}
          onChange={(e) => {
            this.setState({confirmedPassword: e.target.value});
          }}
          valid={
            this.state.password === this.state.confirmedPassword &&
            kooperSchema.validate(this.state.confirmedPassword)
          }
          invalid={
            this.state.password !== this.state.confirmedPassword &&
            kooperSchema.validate(this.state.confirmedPassword)
          }
        />
        <Collapse open={this.state.collapseOpen} className="my-4">
          <div
            className="p-3 mt-3 border rounded"
            style={{backgroundColor: 'rgba(255,0,0,0.32)'}}
          >
            {t('password_modal_password_security_too_low')}
          </div>
        </Collapse>

        <Button
          block
          theme="info"
          className={'mt-4'}
          onClick={() => this.handleSubmit()}
          disabled={
            !(this.state.password === this.state.confirmedPassword) ||
            this.state.password.length < minPasswordLength
          }
        >
          Submit
        </Button>
      </div>
    );
  };

  render() {
    const {t} = this.props;
    return (
      <BaseModal
        name={'ChangePasswordModal'}
        stage={this.state.stage}
        open={this.props.open}
        content={this.content()}
        successMessage={t('modals:password_modal_success_message')}
        failureMessage={t('modals:password_modal_failure_message')}
        infoMessage={t('modals:password_modal_info_message')}
        modalTitle={t('modals:password_modal_title')}
        onToggle={() => this.props.onToggle()}
        noButton
        modalSize={'lg'}
      />
    );
  }
}

export default withTranslation('modals')(ChangePasswordModal);
