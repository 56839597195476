import React from 'react';
import {Button, Grid, TextField, Typography} from '@mui/material';
import {ArrowBackIos, ArrowForwardIos} from '@mui/icons-material';


type Props = {
  t: Function,
  comment: string,
  handleChange: (e) => void,
  handleStepChange: Function,
  handleSubmit: Function,
}

export const RejectModalAddionalInfo = (props: Props) => {
  return (
    <Grid container alignItems={'flex-start'}>

      <Grid item xs={12}>
        <Typography typography={'h6'}>
          {props.t('modals:reject_modal_title')}
        </Typography>
      </Grid>
      <Grid display={'flex'} item xs={12} marginBottom={5}>
        <Typography typography={'body'}>
          {props.t('modals:comment')}
        </Typography>
        <Typography typography={'body'} color='error' marginLeft={1}>
          *
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <TextField
          error={props.comment.length > 255}
          label={props.t('modals:your_message')}
          placeholder={'...'}
          multiline
          fullWidth
          minRows={6}
          maxRows={6}
          value={props.comment}
          onChange={props.handleChange}
        />
        <Grid item xs={12} marginTop={2}>
          <Typography typography={'body'} color='error' fontSize={10}>
            * {props.t('modals:reject_modal_required_field')}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const AdditionalInfoFooter = (props) => {
  return (
    <Grid item xs={12} container alignItems='flex-end' height={'100%'}>
      <Grid item xs={6} style={{justifyContent: 'flex-start', display: 'flex', paddingLeft: 20, paddingBottom: 20}}>
        <Button
          onClick={() => props.handleStepChange('previous')}
          variant={'outlined'}
          color={'secondary'}
        >
          <ArrowBackIos fontSize='small' style={{marginRight: 10}} />
          {props.t('modals:previous')}
        </Button>
      </Grid>
      <Grid item xs={6} style={{justifyContent: 'flex-end', display: 'flex', paddingRight: 20, paddingBottom: 20}}>
        <Button
          disabled={!props.comment.length}
          onClick={() => props.handleSubmit()}
          variant={'contained'}
          color={'error'}
        >
          {props.t('modals:reject_modal_submit_button')}

          <ArrowForwardIos fontSize='small' style={{marginLeft: 10}} />
        </Button>
      </Grid>
    </Grid>
  );
};