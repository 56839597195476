import React, {useState}     from 'react';
import {Col, Container, Row} from 'shards-react';
import PageTitle             from 'components/Common/PageTitle';
import BundleTable           from 'components/BundleTable/BundleTable';
import {useTranslation}      from 'react-i18next';
import SwitchSelector        from 'react-switch-selector';
import {logEvent}            from '../../utils/firebase';


const CounterOffers = (props: any) => {
  const {t} = useTranslation(['bundletable', 'status']);
  const [status, setStatus] = useState('counter_offer_requested');

  const options = [
    {
      label: t('status:counter_offer_requested_button'),
      value: 'counter_offer_requested',
    },
    {
      label: t('status:counter_offer_accepted_button'),
      value: 'counter_offer_accepted',
    },
    {
      label: t('status:counter_offer_submitted_button'),
      value: 'counter_offer_submitted',
    },
  ];

  return (
    <Container fluid className='main-content-container px-4'>
      <Row noGutters className='page-header py-4'>
        <PageTitle
          sm='4'
          title={t('bundletable:page_title_counter_offer')}
          subtitle=''
          className='text-sm-left'
        />
      </Row>
      <Row>
        <div style={{width: 500, height: 40, marginLeft: '14px', marginBottom: '10px'}}>
          <SwitchSelector
            onChange={(newValue: any) => {
              logEvent(`selector_${newValue}`);
              setStatus(newValue);
            }}
            options={options}
            initialSelectedIndex={0}
          />
        </div>
      </Row>
      <Row>
        <Col>
          <BundleTable
            {...props}
            statuses={[status]}
          />
        </Col>
      </Row>
    </Container>
  );
};

//@ts-ignore
export default CounterOffers;
