import React, {Component} from 'react';
import {DropzoneArea} from 'mui-file-dropzone';
import {BaseModalStage, MuiBaseModal} from '../MuiBaseModal';
import {Button, FormGroup, FormLabel, Grid, TextField} from '@mui/material';
import {DatePicker} from '@mui/x-date-pickers';
import moment from 'moment';
import {uploadInvoice} from '../../../services/kooper';

interface Props {
  bundleOrderedPartConfigs: any[],
  selectedBundleOrderedPartConfigIds: any[],
  t: Function
  open: boolean,
  handleClose: Function,
}

interface State {
  loading: boolean,
  currentFile: any,
  stage: BaseModalStage
  selectedDate: Date,
  minDate: Date,
  invoiceNumber: string
}

export class UploadInvoiceModal extends Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      currentFile: null,
      stage: 'content',
      selectedDate: new Date(),
      minDate: new Date(),
      invoiceNumber: '',
    };
  }

  componentDidMount() {
    this.setState({
      minDate: moment.max(this.props.bundleOrderedPartConfigs.filter(({id}) => this.props.selectedBundleOrderedPartConfigIds.includes(id)).map(({deliveryDate}) => moment(deliveryDate))).toDate(),
    });
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (prevProps.selectedBundleOrderedPartConfigIds !== this.props.selectedBundleOrderedPartConfigIds) {
      this.setState({
        minDate: moment.max(this.props.bundleOrderedPartConfigs.filter(({id}) => this.props.selectedBundleOrderedPartConfigIds.includes(id)).map(({deliveryDate}) => moment(deliveryDate))).toDate(),
      });
    }
  }

  handleSubmit = () => {
    this.setState({stage: 'loading', loading: true});

    const form = new FormData();
    form.append('file', this.state.currentFile);
    form.append('bundleOrderedPartConfigIds', JSON.stringify(this.props.selectedBundleOrderedPartConfigIds));
    form.append('documentType', 'invoice');
    form.append('issueDate', this.state.selectedDate.toISOString());
    form.append('invoiceNumber', this.state.invoiceNumber.trim());

    uploadInvoice(form)
      .then(() => this.setState({stage: 'success'}))
      .catch(() => this.setState({stage: 'failure'}));
  };

  handleToggle = () => {
    this.props.handleClose();
    this.setState({
      stage: 'content',
      currentFile: null,
      selectedDate: new Date(),
      invoiceNumber: '',
      loading: false,
    });
  };

  content = () => {
    const {t} = this.props;

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DropzoneArea
            acceptedFiles={['application/pdf']}
            dropzoneText={t('invoicing:upload_invoice_dropzone_text')}
            onChange={(files) => this.setState({currentFile: files[0]})}
            filesLimit={1}
            showPreviews
            showPreviewsInDropzone={false}
            showFileNames
            previewText={'.'}
            useChipsForPreview
            showAlerts={false}
            maxFileSize={25000000}
            fileObjects={null}
            onDelete={() => {}}
          />
        </Grid>
        <Grid item xs={4}>
          <FormGroup>
            <FormLabel>
              {t('invoicing:invoice_issue_date')}
            </FormLabel>
            <DatePicker
              value={this.state.selectedDate}
              onChange={(date) => this.setState({selectedDate: date!})}
              minDate={this.state.minDate}
              renderInput={(params) => <TextField {...params} size={'small'} />}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={4}>
          <FormGroup>
            <FormLabel>
              {t('invoicing:invoice_number')}
            </FormLabel>
            <TextField
              size={'small'}
              value={this.state.invoiceNumber}
              onChange={(e) => this.setState({invoiceNumber: e.target.value})}
            />
          </FormGroup>
        </Grid>
      </Grid>
    );
  };

  footer = () => {
    const isDisabled = !this.state.currentFile || !this.state.selectedDate || !this.state.invoiceNumber || this.state.loading;

    return (
      <Grid item xs={12} justifyContent="center" alignItems="flex-end" height={'100%'} paddingX={10}>
        <Button
          fullWidth
          onClick={() => this.handleSubmit()}
          disabled={isDisabled}
          sx={{'&.MuiButton-root:disabled': {backgroundColor: '#00AFD345', color: '#FFF'}}}
        >
          {this.props.t('invoicing:upload_invoice_for_selected_parts')}
        </Button>
      </Grid>
    );
  };

  render() {
    const {t, open} = this.props;

    return (
      <MuiBaseModal
        stage={this.state.stage}
        onToggle={() => this.handleToggle()}
        open={open}
        content={this.content()}
        footer={this.footer()}
        height={'80vh'}
        successMessageTitle={t('invoicing:upload_modal_success_message')}
        errorMessageTitle={t('invoicing:upload_modal_failure_message')}
        header={t('invoicing:upload_modal_title')}
      />
    );
  }
}
