import React, {useEffect} from 'react';
import {Row, Col, Button} from 'shards-react';
import ErrorLogo from 'assets/img/kaktus404.svg';

import './style.css';
import Swal from 'sweetalert2';
import {logEvent} from '../../utils/firebase';
import {useTranslation} from 'react-i18next';
import {submitUserFeedback} from '../../services/sentry';
import {useGlobal} from 'reactn';


const ErrorPage = (props) => {
  const {t} = useTranslation('errors');
  const [global, setGlobal] = useGlobal();

  let statusCode = props.statusCode || props.match?.params?.statusCode;
  if (Number.isNaN(parseFloat(statusCode))) {
    statusCode = '???';
  }
  useEffect(() => {
    logEvent(`error_page_${statusCode}`);
    if (['500', '???'].includes(statusCode) && props.sentryEventId) {
      Swal.fire({
        input: 'textarea',
        icon: 'error',
        title: t('error_page_modal_title'),
        text: t('error_page_modal_subtitle'),
        inputPlaceholder: t('error_page_modal_placeholder'),
        showCancelButton: true,
        width: '60%',
      })
        .then(async ({value}) => {
          await submitUserFeedback(value, props.sentryEventId, global.userInfo);
        });
    }
  }, []);

  const constructMessage = (title, subtitle) => {
    return (
      <React.Fragment>
        <Row style={{display: 'flex', justifyContent: 'center', marginTop: '40px'}}>
          <h3>
            {title}
          </h3>
        </Row>
        <Row style={{display: 'flex', justifyContent: 'center'}}>
          <h4>
            {subtitle}
          </h4>
        </Row>
        <Row style={{display: 'flex', justifyContent: 'center', marginTop: 30}}>

          <Button style={{marginRight: 10}} onClick={() => window.location.href = '/'}>{t('error_page_button_to_dashboard')}</Button>

          {!(props?.match?.params) &&
          <Button style={{marginLeft: 10}} onClick={() => window.location.reload()}>{t('error_page_button_reload')}</Button>}

        </Row>
      </React.Fragment>
    );
  };


  return (
    <div style={{marginTop: '4vh', width: '100%'}}>
      <Row style={{marginTop: '20px', display: 'flex', justifyContent: 'center'}}>
        <img style={{width: '700px'}} alt='Error' src={ErrorLogo} />
      </Row>


      <Row style={{marginTop: '40px'}}>
        <div className='notfound'>
          <div className='notfound-404'>
            <h1>
              <span>{statusCode[0]}</span>
              <span style={{color: '#43CCAD'}}>{statusCode[1]}</span>
              <span>{statusCode[2]}</span>
            </h1>
          </div>
        </div>
      </Row>


      <div>

        <Row style={{display: 'flex', justifyContent: 'center', marginTop: '40px'}}>
          <h3>
            {t(`error_${statusCode}_title`)}
          </h3>
        </Row>
        <Row style={{display: 'flex', justifyContent: 'center'}}>
          <h4>
            {t(`error_${statusCode}_subtitle`)}
          </h4>
        </Row>
        <Row style={{display: 'flex', justifyContent: 'center', marginTop: 30}}>

          <Button style={{marginRight: 10}} onClick={() => window.location.href = '/'}>{t('error_page_button_to_dashboard')}</Button>

          {!(props?.match?.params) &&
          <Button style={{marginLeft: 10}} onClick={() => window.location.reload()}>{t('error_page_button_reload')}</Button>}

        </Row>

      </div>

    </div>
  );
};

export default ErrorPage;