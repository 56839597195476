import {Button, Grid, Paper, Typography} from '@mui/material';
import React from 'react';
import {ArrowBackIos, ArrowForwardIos} from '@mui/icons-material';
import {StaticDatePicker} from '@mui/x-date-pickers';
import moment from 'moment';
import {useAtom} from 'jotai';
import {CounterOfferModal_CounterOfferDate} from '../../../global-atoms';


type ContentProps = {
  t: Function,
  handleStepChange: Function,
  deliveryDate: Date
}

type FooterProps = {
  handleSubmit: Function,
  handleStepChange: Function,
  t: Function
}

export const CounterOfferModalSelectDate = (props: ContentProps) => {
  const [counterOfferDate, setCounterOfferDate] = useAtom(CounterOfferModal_CounterOfferDate);

  return (
    <Grid container alignItems={'flex-start'} marginTop={'5px'} rowSpacing={1}>
      <Grid item xs={12} marginBottom={'5px'}>
        <Typography typography={'h6'}>
          {props.t('modals:counter_offer_modal_select_date_title')}
        </Typography>
      </Grid>

      <Grid item xs={12} md={2}></Grid>
      <Grid item xs={12} md={8} sx={{'.css-hlj6pa-MuiDialogActions-root': {display: 'none'}}} display={'flex'} justifyContent={'center'}>
        <Paper sx={{borderRadius: '10px', overflow: 'hidden', width: 330, height: 300}} variant={'outlined'}>
          <StaticDatePicker
            onChange={(newValue: any) => {setCounterOfferDate(newValue);}} // @ts-ignore
            value={counterOfferDate}
            showToolbar={false}
            minDate={moment(props.deliveryDate).add(1, 'day').toDate()}
            renderInput={() => <></>}
          />
        </Paper>
      </Grid>
      <Grid item xs={12} md={2} />
    </Grid>
  );
};

export const CounterOfferModalSelectDateFooter = (props: FooterProps) => {
  return (
    <Grid item xs={12} container alignItems='flex-end' height={'100%'}>
      <Grid item xs={6} style={{justifyContent: 'flex-start', display: 'flex', paddingLeft: 20, paddingBottom: 20}}>
        <Button
          onClick={() => props.handleStepChange('previous')}
          variant={'outlined'}
          color={'secondary'}
        >
          <ArrowBackIos fontSize='small' style={{marginRight: 10}} />
          {props.t('modals:previous')}
        </Button>
      </Grid>
      <Grid item xs={6} style={{justifyContent: 'flex-end', display: 'flex', paddingRight: 20, paddingBottom: 20}}>
        <Button
          onClick={() => props.handleSubmit()}
          variant={'contained'}
          color={'error'}
        >
          {props.t('modals:submit')}

          <ArrowForwardIos fontSize='small' style={{marginLeft: 10}} />
        </Button>
      </Grid>
    </Grid>
  );
};