import React from 'react';
import {Grid, Typography} from '@mui/material';
import {timeAgo} from '../../i18n';
import QuestionIcon from '../../assets/icons/question.svg';


interface Props {
  bundle: any;
  t: Function;
}

export const BundleStatus = (props: Props) => {

  const showContent = () => {
    let status = props.bundle.statusKey;
    const openStatus = props.bundle.bundleStatuses.find(status => status.statusKey === 'open');
    const rejectedStatus = props.bundle.bundleStatuses.find(status => status.statusKey === 'rejected');
    const lostButRejectedStatus = props.bundle.bundleStatuses.find(status => status.statusKey === 'lost_but_would_have_rejected');
    const deliveredStatus = props.bundle.bundleStatuses.find(status => status.statusKey === 'delivered');

    switch (status) {
      case 'open':
        return `${props.t('bundledetailspage:bs_status_released')} ${timeAgo.format(new Date(openStatus.createdAt))} `;

      case 'rejected':
        return `${props.t('bundledetailspage:bs_status_rejected')} ${timeAgo.format(new Date(rejectedStatus.createdAt))} `;

      case 'lost_but_would_have_rejected':
        return `${props.t('bundledetailspage:bs_status_rejected')} ${timeAgo.format(new Date(lostButRejectedStatus.createdAt))} `;

      case 'counter_offer_requested':
        return props.t('bundledetailspage:bs_status_counter_offer_requested');

      case 'counter_offer_submitted':
        return props.t('bundledetailspage:bs_status_counter_offer_submitted');

      case 'counter_offer_accepted':
        return props.t('bundledetailspage:bs_status_counter_offer_accepted');

      case 'lost':
        if (new Date(props.bundle.createdAt) > new Date(process.env.REACT_APP_RELEASE_TIMESTAMP as string)) {
          return `${props.t('bundledetailspage:bs_status_released')} ${timeAgo.format(new Date(openStatus.createdAt))} `;
        }
        return <></>;

      case 'ordered':
      case 'delayed':
      case 'shipped':
        return `${timeAgo.format(new Date(props.bundle.counterOfferDate || props.bundle.deliveryDate)).replace('in', '').trim()} ${props.t('bundledetailspage:bs_until_delivery')}`;

      case 'delivered':
        return `${props.t('bundledetailspage:bs_delivered')} ${timeAgo.format(new Date(deliveredStatus.createdAt))} `;

      default:
        return <></>;
    }
  };

  return (
    <Grid item xs={12}>
      <Typography sx={{color: '#A6A6A8'}} variant={'subtitle1'}>
        {showContent()}
      </Typography>
    </Grid>);
};



