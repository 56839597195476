import React, {useState} from 'react';
import {Button, Grid} from '@mui/material';
import {BaseModalStage, MuiBaseModal} from '../MuiBaseModal';
import {CounterOfferModalConfirmPrice, CounterOfferModalConfirmPriceFooter} from './CounterOfferModal.ConfirmPrice';
import {CounterOfferModalSelectDate, CounterOfferModalSelectDateFooter} from './CounterOfferModal.SelectDate';
import {updateBundleStatus} from '../../../services/kooper';
import {CounterOfferModal_CounterOfferDate, CounterOfferPrices} from '../../../global-atoms';
import {useAtom} from 'jotai';
import {formatGermanToISO, formatISODateWithoutTime} from '../../../utils/datetimeHelpers';


interface Props {
  t: Function,
  bundleId: number,
  size?: string,
  data: any,
  buttonDisabled?: boolean
  updateBundlePage: Function
}

export const CounterOfferModal = (props: Props) => {

  const [open, setOpen] = useState(false);
  const [stage, setStage] = useState<BaseModalStage>('content');
  const [activeStep, setActiveStep] = useState('confirm_price');
  const [priceConfirmed, setPriceConfirmed] = useState(false);
  const [counterOfferPrices] = useAtom(CounterOfferPrices);
  const [counterOfferDate] = useAtom(CounterOfferModal_CounterOfferDate);

  const wasRejectedWithDeliveryDate = props.data.bundleStatuses.find(bs => bs.statusKey === 'rejected')?.bundleStatusFeatureValues.some(bsfv => bsfv.value === 'cant_hold_delivery_time');
  const steps = ['confirm_price', 'enter_new_date'];

  const handleToggle = () => {
    if (open && stage === 'success') {
      props.updateBundlePage();
    }
    setOpen(!open);
  };

  const handleStepChange = (direction: 'next' | 'previous') => {
    const currStepIndex = steps.findIndex(x => x === activeStep);
    setActiveStep(steps[direction === 'next' ? currStepIndex + 1 : currStepIndex - 1]);
  };

  const handleSubmit = () => {
    setStage('loading');
    const payloadPrices = counterOfferPrices
      .filter(x => x.active && x.counterOfferPrice)
      .map((x) => ({bundleOrderedPartConfigId: x.bundleOrderedPartConfigId, key: 'counter_offer_price', value: x.counterOfferPrice}));

    const payloadDates = counterOfferDate ? props.data.bundleOrderedPartConfigs.map(x => ({
      bundleOrderedPartConfigId: x.id,
      key: 'counter_offer_date',
      value: formatISODateWithoutTime(formatGermanToISO(counterOfferDate)),
    })) : [];
    const payload = [...payloadPrices, ...payloadDates];

    updateBundleStatus(props.bundleId, 'counter_offer_submitted', [], {bundleOrderedPartConfigFeatureValues: payload})
      .then(() => {
        setStage('success');
      })
      .catch(() => setStage('failure'));
  };

  const content = () => {
    return (
      <Grid container>
        <Grid item container xs={12} alignItems={'flex-start'}>
          {activeStep === 'confirm_price' &&
            <CounterOfferModalConfirmPrice
              t={props.t}
              priceConfirmed={priceConfirmed}
              handlePriceConfirm={setPriceConfirmed}
              numberOfPartsInBundle={props.data.bundleOrderedPartConfigs.length}
              bundleOrderedPartConfigs={props.data.bundleOrderedPartConfigs}
            />
          }
          {activeStep === 'enter_new_date' &&
            <CounterOfferModalSelectDate
              t={props.t}
              handleStepChange={handleStepChange}
              deliveryDate={props.data.deliveryDate}
            />}
        </Grid>
      </Grid>
    );
  };
  return (
    <MuiBaseModal
      stage={stage}
      button={
        <Button
          onClick={() => handleToggle()}
          color={'primary'}
          fullWidth
          disabled={!counterOfferPrices.filter(x => x.active).length || counterOfferPrices.some(x => !x.counterOfferPrice && x.active)}
        >
          {props.t('bundledetailspage:bs_action_panel_button_start_counter_offer')}
        </Button>
      }
      open={open}
      onToggle={() => handleToggle()}
      content={content()}
      loadingMessageTitle={props.t('modals:counter_offer_modal_loading_message')}
      successMessageTitle={props.t('modals:counter_offer_modal_success_message')}
      height={'66vh'}
      width={'50vw'}
      footer={stage === 'content' ?
        activeStep === 'confirm_price' ?
          <CounterOfferModalConfirmPriceFooter
            handleSubmit={handleSubmit}
            handleStepChange={handleStepChange}
            hasMultipleSteps={wasRejectedWithDeliveryDate}
            priceConfirmed={priceConfirmed}
            t={props.t}
          /> :
          <CounterOfferModalSelectDateFooter
            handleSubmit={handleSubmit}
            handleStepChange={handleStepChange}
            t={props.t}
          /> :
        <></>}
    />
  );

};