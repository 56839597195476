import axios from 'axios';
import {getToken, getUserInfo, handleUnauthorized} from './auth';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import {getGlobal, setGlobal} from 'reactn';
import * as Sentry from '@sentry/browser';
import {toast} from 'react-hot-toast';

export const kooperAuth = axios.create({
  timeout: 100000,
  baseURL: process.env.REACT_APP_KOOPER_AUTH_URL,
});

export const kooper = axios.create({
  timeout: 100000,
  baseURL: `${process.env.REACT_APP_KOOPER_KAKTUS_URL}/api`,
});

export const knest = axios.create({
  timeout: 100000,
  baseURL: `${process.env.REACT_APP_KNEST_KAKTUS_URL}`,
});

createAuthRefreshInterceptor(kooperAuth, handleUnauthorized, {
  statusCodes: [401, 403],
  skipWhileRefreshing: false,
});

createAuthRefreshInterceptor(kooper, handleUnauthorized, {
  statusCodes: [401, 403],
  skipWhileRefreshing: false,
});

createAuthRefreshInterceptor(knest, handleUnauthorized, {
  statusCodes: [401, 403],
  skipWhileRefreshing: false,
});

kooperAuth.interceptors.request.use((request) => {
  request.headers.Authorization = getToken();
  return request;
});

kooper.interceptors.request.use((request) => {
  request.headers.Authorization = getToken();
  return request;
});

kooper.interceptors.response.use(
  async response => {

    if (response.config.method === 'get') {
      const {data} = await getUserInfo();
      await setGlobal({...getGlobal(), userInfo: data});
    }

    return response;
  },
  async error => {
    if (error.response?.config?.url.includes('/api/bundle-teasers/')) {
      window.location.href = `/error/404`;
    } else {
      Sentry.captureException(error);
      toast.error(<div> {error.message} <br /> {error.response?.data?.message || ''} </div>);
      return Promise.reject(error);
    }
  });

knest.interceptors.request.use((request) => {
  request.headers.Authorization = getToken();
  request.headers['access-control-allow-origin'] = '*';
  return request;
});

knest.interceptors.response.use(
  async response => {

    if (response.config.method === 'get') {
      const {data} = await getUserInfo();
      await setGlobal({...getGlobal(), userInfo: data});
    }
    return response;
  },
  async error => {
    if (error.response?.config?.url.includes('/api/bundle-teasers/')) {
      window.location.href = `/error/404`;
    } else {
      Sentry.captureException(error);
      toast.error(<div> {error.message} <br /> {error.response?.data?.message || ''} </div>);
      return Promise.reject(error);
    }
  });
