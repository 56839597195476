import moment from 'moment';
import * as DateHolidays from 'date-holidays';
import {palette} from './palette';


const dateHolidays = new DateHolidays.default();
dateHolidays.init('DE', undefined, undefined, {types: ['public']});

/*declare module '@mui/material/IconButton' {
 interface IconButtonClasses {
 variant: true;
 }
 }*/
export const components = {
  MuiBackdrop: {
    styleOverrides: {
      root: {
        backdropFilter: 'blur(2px)',
      },
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      root: {
        height: 20,
      },
      label: {
        fontSize: 12,
        color: '#616161',
        fontWeight: 400,
      },
    },
  },
  MuiButton: {
    defaultProps: {
      variant: 'contained',
    },
    styleOverrides: {
      sizeMedium: {height: '30px'},
      outlinedSecondary: {
        backgroundColor: 'white', borderColor: palette.secondary.main,
      },
      root: {
        boxShadow: '0px 1px 2px -3px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
        ':hover': {
          boxShadow: '0px 1px 2px -3px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
        },
        borderRadius: '30px',
        '&:focus': {
          outline: 0,
        },
      },
    },
  },

  MuiButtonBase: {
    styleOverrides: {
      root: {
        '&:focus': {
          outline: 0,
        },
      },
    },
  },
  MuiStaticDatePicker: {
    styleOverrides: {
      backgroundColor: 'black',
    },
    defaultProps: {
      shouldDisableDate: (date) => {
        const isWeekend = ['Sat', 'Sun'].includes(moment(date).format('ddd'));
        const isHoliday = !!dateHolidays.isHoliday(date);
        return isWeekend || isHoliday;
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      formControl: {
        borderRadius: '.625rem',
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        borderRadius: '.625rem',
        backgroundColor: '#F3F3F3',
        borderStyle: 'solid',
        borderWidth: '0.5px',
        borderColor: '#DBDBDB',
        boxShadow: 'none'
        ,
      },
    },
  },

  MuiTooltip: {
    styleOverrides: {
      arrow: {color: palette.secondary.main},
      tooltip: {
        borderRadius: '2px',
        backgroundColor: palette.secondary.main,
        color: 'white',
      },
    },
  },
  MuiDataGrid: {
    styleOverrides: {
      root: {
        border: 0,
        '.MuiDataGrid-columnSeparator': {color: 'transparent'},
        '.MuiDataGrid-cell:focus-within': {outlineWidth: '0px'},
        '.MuiDataGrid-columnHeader:focus-within': {outlineWidth: '0px'},
      },
    },
  },

};