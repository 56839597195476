import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import {createBrowserHistory} from 'history';
import * as Sentry from '@sentry/browser';
import {Integrations} from '@sentry/tracing';
import App from './App';
import './i18n';
import './styles/shards-dashboards.min.css';
import React, {setGlobal} from 'reactn';
import {createRoot} from 'react-dom/client';
import {LicenseInfo} from '@mui/x-license-pro';

//Init MUI X Pro
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_X_PRO_LICENSE);

const hist = createBrowserHistory();

if (process.env.REACT_APP_SENTRY_ENABLE) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      new Integrations.BrowserTracing({
          tracingOrigins: [
            'localhost',
            'kreatize.com',
          ],
          beforeNavigate: context => {
            return {
              ...context,
              name: location.pathname
                .replace(/KREA:BNDL-[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/g, ':uuid')
                .replace(/[a-f0-9]{32}/g, ':hash')
                .replace(/\d+/g, ':id'),
            };
          },
        },
      ),
    ],
    tracesSampleRate: 1.0,
    release: process.env.REACT_APP_SENTRY_RELEASE,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    project: 'kaktus',
    autoSessionTracking: true,
  });
}

setGlobal({
  userInfo: null,
  history: hist,
});

const root = createRoot(document.getElementById('root'));
root.render(<App history={hist} />);
