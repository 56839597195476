import React, {ReactElement} from 'react';
import {getStatusFeatureSelectOptions, updateBundleStatus} from '../../../services/kooper';
import {logEvent} from 'utils/firebase';
import {Step, Button, StepLabel, Stepper, Grid} from '@mui/material';
import {BaseModalStage, MuiBaseModal} from '../MuiBaseModal';
import {RejectModalSelectReasons, SelectReasonsFooter} from './RejectModal.SelectReasons';
import {RejectModalSelectDate, SelectDateFooter} from './RejectModal.SelectDate';
import {AdditionalInfoFooter, RejectModalAddionalInfo} from './RejectModal.AddionalInfo';
import {formatISODateWithoutTime} from '../../../utils/datetimeHelpers';

interface Props {
  status: string,
  updateBundlePage: any,
  t: Function,
  bundleId: number,
  size?: string,
  data: any,
  buttonDisabled?: boolean
  textButton: string

}

interface State {
  open: boolean,
  comment: string,
  stage: BaseModalStage,
  steps: ('more_information' | 'enter_new_date' | 'select_reasons')[],
  activeStep: string,
  newDate: Date,
  statusFeatureSelectOptions: Array<any>,
  footer: ReactElement | undefined,

}

export class RejectModal extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      open: false,
      stage: 'content',
      steps: ['select_reasons', 'more_information'],
      activeStep: 'select_reasons',
      newDate: new Date(props.data.deliveryDate),
      statusFeatureSelectOptions: [],
      footer: undefined,
      comment: '',
    };
  }

  componentDidMount() {
    getStatusFeatureSelectOptions('reject_reason')
      .then((x) => {
        const keysWithChecked = x.data.statusFeatureSelectOptions.map((sfso: any) => ({key: sfso.selectOptionKey, checked: false}));
        this.setState({statusFeatureSelectOptions: keysWithChecked});
      })
      .catch(() => {
        this.setState({stage: 'failure'});
      });
  }

  handleToggle() {
    if (this.state.open && this.state.stage === 'success') {
      this.props.updateBundlePage();
    }
    this.setState({
      open: !this.state.open,
    });
  }

  handleChange(key: string) {
    let statusFeatureSelectOptionsCopy = [...this.state.statusFeatureSelectOptions];

    const currentSelectOption = statusFeatureSelectOptionsCopy.find(x => x.key === key);
    currentSelectOption.checked = !currentSelectOption.checked;

    const checkedOptions = this.state.statusFeatureSelectOptions.filter(x => x.checked);
    const stepsCopy = [...this.state.steps];

    const isRejectedOnlyWithLeadtime = checkedOptions.length === 1 && checkedOptions[0].key === 'cant_hold_delivery_time';
    stepsCopy[1] = isRejectedOnlyWithLeadtime ? 'enter_new_date' : 'more_information';

    this.setState({steps: stepsCopy, statusFeatureSelectOptions: statusFeatureSelectOptionsCopy});
  }

  async handleSubmit() {
    this.setState({stage: 'loading'});
    try {
      let statusFeatures = this.state.statusFeatureSelectOptions.filter(x => x.checked).map(x => ({key: 'reject_reason', value: x.key}));
      if (this.state.comment) {
        logEvent('rejection_comment_submitted');
        statusFeatures = [...statusFeatures, {key: 'comment', value: this.state.comment}];
      }
      if (this.props.status === 'lost') {
        await updateBundleStatus(this.props.bundleId, 'lost_but_would_have_rejected', statusFeatures);

      } else {

        await updateBundleStatus(this.props.bundleId, 'rejected', statusFeatures);

        if (statusFeatures.length === 1 && statusFeatures[0].value === 'cant_hold_delivery_time') {
          await updateBundleStatus(this.props.bundleId, 'counter_offer_requested');

          const dates = this.props.data.bundleOrderedPartConfigs.map((bopc) => ({
            bundleOrderedPartConfigId: bopc.id,
            key: 'counter_offer_date',
            value: formatISODateWithoutTime(this.state.newDate),
          }));
          await updateBundleStatus(this.props.bundleId, 'counter_offer_submitted', [], {bundleOrderedPartConfigFeatureValues: dates});
          logEvent('automatic_leadtime_counteroffer');
        }
      }
      this.setState({stage: 'success'});
    } catch (e) {
      this.setState({stage: 'failure'});
    }
  }

  renderStepper = () => {
    if (this.state.stage !== 'content') {
      return undefined;
    }
    return (
      <Stepper activeStep={this.state.steps.findIndex(x => x === this.state.activeStep)} alternativeLabel style={{width: '100%', height: '20px'}}>
        {this.state.steps.map(key => <Step key={key}><StepLabel sx={{'.MuiStepLabel-labelContainer': {display: 'none'}}}>{key}</StepLabel></Step>)}
      </Stepper>
    );
  };

  handleStepChange = (direction: 'next' | 'previous') => {
    const currStepIndex = this.state.steps.findIndex(x => x === this.state.activeStep);
    this.setState({activeStep: this.state.steps[direction === 'next' ? currStepIndex + 1 : currStepIndex - 1]});
  };

  content = () => {
    return (
      <Grid container>
        <Grid item container xs={12} alignItems={'flex-start'}>
          {this.state.activeStep === 'select_reasons' &&
            <RejectModalSelectReasons
              t={this.props.t}
              statusFeatureSelectOptions={this.state.statusFeatureSelectOptions}
              handleChange={(key) => this.handleChange(key)}
              handleStepChange={(direction) => this.handleStepChange(direction)}
            />
          }

          {this.state.activeStep === 'enter_new_date' &&
            <RejectModalSelectDate
              t={this.props.t}
              handleNewDate={(date) => this.setState({newDate: date})}
              handleStepChange={(direction) => this.handleStepChange(direction)}
              deliveryDate={this.props.data.deliveryDate}
              newDate={this.state.newDate}
            />
          }

          {this.state.activeStep === 'more_information' &&
            <RejectModalAddionalInfo
              t={this.props.t}
              comment={this.state.comment}
              handleChange={(e) => this.setState({comment: e.target.value})}
              handleStepChange={(direction) => this.handleStepChange(direction)}
              handleSubmit={() => this.handleSubmit()}
            />}
        </Grid>
      </Grid>
    );
  };

  footer = () => {
    return (
      <React.Fragment>
        {this.state.activeStep === 'select_reasons' &&
          <SelectReasonsFooter
            {...this.props}
            handleStepChange={(dir) => this.handleStepChange(dir)}
            statusFeatureSelectOptions={this.state.statusFeatureSelectOptions}
          />}

        {this.state.activeStep === 'enter_new_date' &&
          <SelectDateFooter
            {...this.props}
            handleStepChange={(dir) => this.handleStepChange(dir)}
            handleSubmit={() => this.handleSubmit()}
          />}

        {this.state.activeStep === 'more_information' &&
          <AdditionalInfoFooter
            {...this.props}
            handleStepChange={(dir) => this.handleStepChange(dir)}
            handleSubmit={() => this.handleSubmit()}
            comment={this.state.comment}
          />}
      </React.Fragment>
    );
  };

  render() {
    return (
      <MuiBaseModal
        stage={this.state.stage}
        button={
          <Button
            disabled={this.props.buttonDisabled}
            onClick={() => this.handleToggle()}
            variant="outlined"
            color={'secondary'}
            fullWidth
          >
            {this.props.t(this.props.textButton)}
          </Button>
        }
        open={this.state.open}
        onToggle={() => this.handleToggle()}
        content={this.content()}
        header={this.renderStepper()}
        loadingMessageTitle={this.props.t(this.state.steps[1] === 'more_information' ? 'modals:reject_modal_loading_message' : 'modals:reject_modal_offer_loading_message')}
        successMessageTitle={this.props.t(this.state.steps[1] === 'more_information' ? 'modals:reject_modal_success_message_title' : 'modals:reject_modal_offer_success_message_title')}
        successMessageSubtitle={this.props.t(this.state.steps[1] === 'more_information' ? 'modals:reject_modal_success_message_subtitle' : 'modals:reject_modal_offer_success_message_subtitle')}
        height={'80vh'}
        footer={this.state.stage === 'content' ? this.footer() : undefined}
      />
    );
  }
}