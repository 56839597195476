import React from 'react';
import noPreview from '../../assets/img/no-preview.jpg';
import {palette} from '../../styles/theme/palette';
import Typography from '@mui/material/Typography';
import {Grid} from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {useAtom} from 'jotai';
import {ZoomModal_CurrentBundleOrderedPartConfigId, ZoomModal_MousePosition, ZoomModal_Open} from '../../global-atoms';


const Thumbnail = (props) => {

  const [hover, setHover] = React.useState(false);
  const [, setCurrentBundleOrderedPartConfigId] = useAtom(ZoomModal_CurrentBundleOrderedPartConfigId);
  const [, setOpen] = useAtom(ZoomModal_Open);
  const [, setMousePosition] = useAtom(ZoomModal_MousePosition);

  const setThumbnailToNoPreviewAvailable = id => {
    document.getElementById(id).src = noPreview;
  };

  return (
    <div style={{position: 'relative'}}>
      <div
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={(e) => {
          setCurrentBundleOrderedPartConfigId(props.bundleOrderedPartConfig.id);
          setOpen(true);
          setMousePosition({x: e.clientX, y: e.clientY});
        }}
        style={{
          backgroundColor: hover ? palette.secondary.main : 'transparent',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          position: 'absolute',
          zIndex: 1,
          opacity: 0.7,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '3px',
        }}
      >
        {hover &&
          <Grid container alignItems={'center'} justifyContent={'center'}>
            <Grid item display={'flex'} alignContent={'center'}>
              <Typography variant='subtitle1' color={'#FFF'}>
                {props.t('bundledetailspage:bs_view_drawing')}
              </Typography>
            </Grid>
            <Grid item display={'flex'} alignContent={'center'}>
              <OpenInNewIcon sx={{width: '13px', height: '13px', marginLeft: '5px', color: '#FFF'}} />
            </Grid>
          </Grid>
        }
      </div>
      <img
        className={props.className}
        id={props.id}
        alt='Attachment preview'
        src={props.src}
        style={{
          objectFit: 'contain',
          border: '1px solid #131C3E',
          borderRadius: '3px',
          backgroundColor: '#FFF',
          width: props.width,
          height: props.height,
        }}
        onError={() => setThumbnailToNoPreviewAvailable(props.id)}
      />
    </div>
  );
};

export default Thumbnail;