import React from 'react';
import TimerIcon from '../../assets/icons/timer.svg';
import TruckIcon from '../../assets/icons/truck.svg';
import QuestionIcon from '../../assets/icons/question.svg';
import InProductionIcon from '../../assets/icons/blueMachine.svg';
import DeliveredIcon from '../../assets/icons/delivered.svg';
import HandsIcon from '../../assets/icons/hands.svg';
import RejectedIcon from '../../assets/icons/fingerDown.svg';
import EyeIcon from '../../assets/icons/eye.svg';
import {Grid, IconButton, Tooltip, Typography} from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';


interface Props {
  status: string;
  t: Function;
  currentBundleStatus: any;
  createdAt: Date;
}

export const BundleStatusIcons = (props: Props) => {

  let status = props.status;
  let iconSrc, toolTipKey;

  switch (status) {
    case 'open':
      toolTipKey = 'respond_open_request';
      iconSrc = QuestionIcon;
      break;

    case 'rejected':
    case 'lost_but_would_have_rejected':
      toolTipKey = 'bundle_rejected';
      iconSrc = RejectedIcon;
      break;

    case 'counter_offer_requested':
      toolTipKey = 'waiting_for_counter_offer';
      iconSrc = TimerIcon;
      break;

    case 'counter_offer_accepted':
      toolTipKey = 'counter_offer_accepted';
      iconSrc = HandsIcon;
      break;

    case 'shipped':
      toolTipKey = 'bundle_in_transit';
      iconSrc = TruckIcon;
      break;

    case 'ordered':
      toolTipKey = 'bundle_in_production';
      iconSrc = InProductionIcon;
      break;

    case 'delivered':
      toolTipKey = 'bundle_delivered';
      iconSrc = DeliveredIcon;
      break;

    case 'counter_offer_submitted':
      toolTipKey = 'reviewing_counter_offer';
      iconSrc = EyeIcon;
      break;

    case 'lost':
      if (new Date(props.createdAt) > new Date(process.env.REACT_APP_RELEASE_TIMESTAMP as string)) {
        toolTipKey = 'respond_open_request';
        iconSrc = QuestionIcon;
      }
      break;

    case 'delayed':
      return (
        <Grid item>
          <Tooltip
            arrow
            placement={'right'}
            componentsProps={{tooltip: {sx: {maxWidth: '2000px', paddingX: '20px', paddingY: '10px'}}}}
            title={
              <>
                <Typography sx={{color: '#FFF', fontSize: '12px', marginBottom: '10px'}}>{props.t('bundledetailspage:bs_tooltip_delay_reason')}</Typography>
                <ul style={{marginLeft: '10px'}}>
                  {props.currentBundleStatus.bundleStatusFeatureValues
                    .filter(bundleStatusFeatureValue => bundleStatusFeatureValue.statusFeatureKey === 'delay_reason')
                    .map(bundleStatusFeatureValue => <li> {props.t(`commenttypes:${bundleStatusFeatureValue.value}`)}</li>)
                  }
                </ul>
              </>
            }
          >
            <IconButton sx={{'&.MuiIconButton-root:hover': {backgroundColor: 'transparent'}, padding: 0}}>
              <AccessTimeIcon sx={{height: '18px', color: '#5A6078'}} />
            </IconButton>
          </Tooltip>
        </Grid>
      );
    default:
      return <></>;
  }
  return (
    <Grid item>
      <Tooltip arrow placement={'top'} title={props.t(`bundledetailspage:bs_tooltip_${toolTipKey}`)}>
        <img src={iconSrc} style={{width: '18px', height: '18px'}} />
      </Tooltip>
    </Grid>
  );
};


