import React from 'react';
import {Col, Container, Row} from 'shards-react';
import PageTitle from 'components/Common/PageTitle';
import BundleTable from 'components/BundleTable/BundleTable';
import {useTranslation} from 'react-i18next';


const BundlesInProduction = (props: any) => {

  const getStatusFilterFromURL = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const filter = urlParams.get('statuses');
    return filter;
  };

  const getAdditionalFilters = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const filter = urlParams.get('filter');
    if (filter) {
      let formattedFilter = {};
      filter
        .replace('[', '')
        .replace(']', '')
        .split(',')
        .map(x => {
          const arr = x.split('=');
          formattedFilter[arr[0]] = JSON.parse(arr[1]);
        });
      return formattedFilter;
    }
    return null;
  };

  const {t} = useTranslation('bundletable');

  let statuses = ['accepted', 'ordered', 'delayed'];
  const urlFilter = getStatusFilterFromURL();

  if (urlFilter) {
    statuses = urlFilter
      .replace('[', '')
      .replace(']', '')
      .split(',');
  }

  return (
    <Container fluid className='main-content-container px-4'>
      <Row noGutters className='page-header py-4'>
        <PageTitle
          sm='4'
          title={t('page_title_in_production')}
          subtitle={t('page_subtitle_in_production')}
          className='text-sm-left'
        />
      </Row>
      <Row>
        <Col>
          <BundleTable statuses={statuses} filter={getAdditionalFilters()} {...props} />
        </Col>
      </Row>
    </Container>
  );
};

export default BundlesInProduction;
