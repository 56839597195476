import Typography from '@mui/material/Typography';
import {TextField} from '@mui/material';
import {formatCurrency} from '../../../utils/formatHelpers';
import {useAtom} from 'jotai';
import {CounterOfferPrices} from '../../../global-atoms';


type Props = {
  rowSelected: boolean,
  pricePerPiece: number,
  counterOfferPrice: number,
  bundleStatusKey: string,
  bopcId: number,
  batchSize: number
}

export const CollapsablePartDetailsPricePerPiece = (props: Props) => {
  const [counterOfferPrices, setCounterOfferPrices] = useAtom(CounterOfferPrices);

  const handleCounterOfferInput = (event) => {
    setCounterOfferPrices([
      ...counterOfferPrices.filter(x => x.bundleOrderedPartConfigId !== props.bopcId),
      {
        bundleOrderedPartConfigId: props.bopcId,
        counterOfferPrice: parseFloat(event.target.value),
        batchSize: props.batchSize,
        active: props.rowSelected,
      },
    ]);
  };

  if (props.bundleStatusKey === 'counter_offer_requested' && props.rowSelected) {
    return <>
      <Typography variant='subtitle2' sx={{fontSize: '12px', fontWeight: 600, color: '#d7d7d7', marginRight: '5px', textDecoration: 'line-through'}}>
        {`${formatCurrency(props.pricePerPiece)}`}
      </Typography>
      <TextField
        size={'small'}
        variant={'outlined'}
        sx={{width: '100px', height: '25px'}}
        inputProps={{sx: {paddingLeft: '5px'}}}
        InputProps={{
          sx: {
            height: '25px',
            borderRadius: '5px',
            fontSize: '12px',
            padding: 0,
          },
          endAdornment: <Typography variant='subtitle2' sx={{fontSize: '12px', fontWeight: 600, marginRight: '5px'}}>EUR</Typography>,
        }}
        placeholder={'Your Offer'}
        type={'number'}
        defaultValue={counterOfferPrices.find(x => x.bundleOrderedPartConfigId === props.bopcId)?.counterOfferPrice}
        onBlur={handleCounterOfferInput} //@ts-ignore
        onKeyDown={(e) => e.key === 'Enter' && e.target.blur()}
      />
    </>;
  }
  if (!!props.counterOfferPrice) {
    return <>
      <Typography variant='subtitle2' sx={{fontSize: '12px', fontWeight: 600, color: '#d7d7d7', marginRight: '5px', textDecoration: 'line-through'}}>
        {`${formatCurrency(props.pricePerPiece)}`}
      </Typography>

      <Typography variant='subtitle2' sx={{fontSize: '12px', fontWeight: 600, marginRight: '5px'}}>
        {`${formatCurrency(props.counterOfferPrice)}`}
      </Typography>
    </>;
  }

  return (
    <Typography variant='subtitle2' sx={{fontSize: '12px', fontWeight: 600, marginRight: '5px'}}>
      {`${formatCurrency(props.pricePerPiece)}`}
    </Typography>
  );
};
