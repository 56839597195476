import React, {Suspense, useEffect} from 'react';
import {useGlobal} from 'reactn';
import {createBrowserHistory} from 'history';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import AppLayout from './layouts/App/AppLayout.js';
import Loading from './components/Loading/Loading';
import BrowserSupport from './components/BrowserSupport/BrowserSupport';
import {isBrowserSupported} from './utils/browserSupportHelpers';
import {getToken, getUserInfo} from './services/auth';
import {initializeFirebase} from './utils/firebase';
import * as Sentry from '@sentry/browser';
import {ThemeProvider, StyledEngineProvider} from '@mui/material/styles';
import {theme} from './styles/theme/theme';
import {LocalizationProvider} from '@mui/x-date-pickers';
import deLocale from 'date-fns/locale/de';
import enLocale from 'date-fns/locale/en-US';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import i18n from './i18n';

const hist = createBrowserHistory();

const App = () => {
  if (!localStorage.getItem('lang')) {
    localStorage.setItem('lang', 'de');
  }

  const [user, setUser] = useGlobal('userInfo');

  useEffect(() => {
    getUserInfo().then(({data}) => {
      setUser(data);
      initializeFirebase(data.email);
      Sentry.setUser({email: data.email, username: `${data.firstName} ${data.lastName}`});
      Sentry.setContext('userInfo', data);
      Sentry.setTag('email', data.email);
      if (data.language) i18n.changeLanguage(data.language).catch(console.error);
    });
  }, []);

  if (!window.location.pathname.includes('bundle')) {
    document.title = 'KREATIZE Kaktus';
  }

  // Ensure user is authenticated
  getToken();

  if (!user) {
    return <Loading />;
  } else if (isBrowserSupported()) {
    return (
      <Suspense fallback={<Loading />}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={i18n.language === 'de' ? deLocale : enLocale}>
              <BrowserRouter history={hist}>
                <Routes>
                  <Route path="/*" element={<AppLayout path="/" history={hist} user={user} />} />
                </Routes>
              </BrowserRouter>
            </LocalizationProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </Suspense>
    )
      ;
  } else {
    return (
      <BrowserSupport />
    );
  }
};

export default App;
