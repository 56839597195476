// TODO: DELETE THIS FILE
import moment from 'moment';
import {Day} from 'react-modern-calendar-datepicker';


export const formatDate = (date) => {
  if (!date) {
    return null;
  }

  return new Date(date)
    .toLocaleDateString('de-DE', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
};

export const formatISODateWithoutTime = (ISODateString) => {
  return moment(ISODateString).format('YYYY-MM-DD');
};

export const formatGermanToISO = (germanDate) => {
  return moment(germanDate, 'D.M.YYYY');
};

export const formatIsoDateToCustomObject = (ISODateString) => {
  const date = new Date(ISODateString);
  return {day: date.getDate(), month: date.getMonth() + 1, year: date.getFullYear()};
};

export const formatToRelativeDateDifference = (date) => {
  let diffTime = Math.abs(Math.min(new Date().valueOf() - new Date(date).valueOf(), 0));
  let days = diffTime / (24 * 60 * 60 * 1000);
  let hours = (days % 1) * 24;
  let minutes = (hours % 1) * 60;
  let secs = (minutes % 1) * 60;
  return {
    days: Math.floor(days),
    hours: Math.floor(hours),
    minutes: Math.floor(minutes),
    secs: Math.floor(secs),
  };
};