import React from 'react';
import IconButton from '@mui/material/IconButton';
import SaveIcon from '@mui/icons-material/Save';
import {ThreeStateCheckboxSwitch} from './SelfServiceTable';
import {Card, CardBody} from 'shards-react';


export const SelfServicePostProcesses = (props: any) => {
  const {t} = props;

  const renderTableRows = () => {
    return props.processes.map((process: any) => {
      return (
        <tr key={process.key}>
          <td>{t(`processes:${process.key}`)}</td>
          <td>
            <ThreeStateCheckboxSwitch
              checked={process.active}
              onEnable={() => {
                if (process.processFeatures.find((x: any) => x.processFeatureKey === 'additional_leadtime').value) {
                  props.handleToggle(process, true);
                } else {
                  process.collapseOpen = true;
                  process.active = null;
                  props.handleProcessUpdate(process);
                }
              }}
              onDisable={() => props.handleToggle(process, false)}
            />
          </td>

          <td>
            {props.createProcessFeatureInputGroup(process.processFeatures.find((x: any) => x.processFeatureKey === 'additional_leadtime'), process)}
          </td>
          <td>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <IconButton
                disabled={!process.processFeatures.find((x: any) => x.processFeatureKey === 'additional_leadtime')?.value && !process.collapseOpen}
                onClick={() => props.handleToggle(process, true)}
                style={{padding: '0px', color: process.processFeatures.find((x: any) => x.processFeatureKey === 'additional_leadtime')?.value && process.collapseOpen ? 'red' : ''}}
                size='large'
              >
                <SaveIcon />
              </IconButton>
              {(process.processFeatures.find((x: any) => x.processFeatureKey === 'additional_leadtime')?.value && process.collapseOpen) &&
                <div style={{fontSize: '30px'}}>
                  <span role={'img'} aria-label={'!'}>❗</span>
                </div>
              }
            </div>
          </td>
        </tr>
      );
    });
  };
  return (
    <Card small className='mb-4 pt-2'>
      <CardBody>
        <table className='table mb-0'>
          <thead className={'bg-light'}>
            <td>{t('processClasses:post_process')}</td>
            <td>
              <div>{t('bundletable:available')}</div>
            </td>
            <td style={{width: '35%'}}>{t('processFeatures:additional_leadtime')}</td>
            <td>{t('selfService:save')}</td>
          </thead>
          {renderTableRows()}
        </table>
      </CardBody>
    </Card>
  );
};