import CloudQueueRoundedIcon from '@mui/icons-material/CloudQueueRounded';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import BuildIcon from '@mui/icons-material/Build';
import HistoryIcon from '@mui/icons-material/History';
import ClearIcon from '@mui/icons-material/Clear';
import SettingsIcon from '@mui/icons-material/Settings';
import Dashboard from 'containers/Dashboard/Dashboard';
import AvailableBundles from 'containers/AvailableBundles/AvailableBundles';
import BundlesInProduction from 'containers/BundlesInProduction/BundlesInProduction';
import CounterOffers from 'containers/CounterOffers/CounterOffers';
import Complaints from 'containers/Complaints/Complaints';
import ProducedBundles from 'containers/ProducedBundles/ProducedBundles';
import LostBundles from 'containers/LostBundles/LostBundles';
import SelfService from 'containers/SelfService/SelfService';
import AuctionIcon from 'assets/icons/auction.svg';
import {Receipt, RequestQuote} from '@mui/icons-material';
import {CombinatorialAuctions} from './containers/CombinatorialAuctions/CombinatorialAuctions';
import React from 'react';
import RFQ from './containers/RFQ/RFQ';
import Invoicing from './containers/Invoicing/Invoicing';

const dashRoutes = [
  {
    path: 'dashboard',
    name: 'dashboard',
    key: 'route_dashboard',
    icon: <DashboardIcon />,
    component: Dashboard,
    layout: '/',
  },
  {
    path: 'available',
    name: 'route_partlist_open',
    key: 'route_bundles_open',
    icon: <CloudQueueRoundedIcon />,
    component: AvailableBundles,
    layout: '/',
  },
  {
    path: 'rfq',
    name: 'rfq',
    key: 'route_rfq',
    icon: <RequestQuote />,
    component: RFQ,
    layout: '/',
  },
  {
    path: 'combinatorial-auctions',
    name: 'combinatorial-auctions',
    key: 'route_combinatorial_auctions',
    icon: <img src={AuctionIcon} alt={'auction-icon'} />,
    component: CombinatorialAuctions,
    layout: '/',

  },
  {
    path: 'counter-offers',
    name: 'counter_offers',
    key: 'route_bundles_counteroffer',
    icon: <LocalOfferIcon />,
    component: CounterOffers,
    layout: '/',
  },
  {
    path: 'in-production',
    name: 'in_production',
    key: 'route_bundles_in_production',
    icon: <BuildIcon />,
    component: BundlesInProduction,
    layout: '/',
  },
  {
    path: 'complaints',
    name: 'complaints',
    key: 'route_bundles_complaints',
    icon: <SentimentVeryDissatisfiedIcon />,
    component: Complaints,
    layout: '/',
  },
  {
    path: 'produced',
    name: 'produced',
    key: 'route_bundles_produced',
    icon: <HistoryIcon />,
    component: ProducedBundles,
    layout: '/',
  },
  {
    path: 'lost',
    name: 'lost',
    key: 'route_bundles_lost',
    icon: <ClearIcon />,
    component: LostBundles,
    layout: '/',
  },
  {
    path: 'invoicing',
    name: 'invoicing',
    key: 'route_invoicing',
    icon: <Receipt />,
    component: Invoicing,
    layout: '/',
  },
  {
    path: 'self-service',
    name: 'self_service',
    key: 'route_account_processes',
    icon: <SettingsIcon />,
    component: SelfService,
    layout: '/',
  },
];
export default dashRoutes;
