import React from 'react';

export default function BrowserSupport() {
  return (
    <div id="browser-support" className="wrapper">
      <img
        className="status-page-image pb-2"
        alt="kreatize-logo"
        src={require('assets/img/kaktus404.svg')}
      />

      <h2 className="not-supported-page-title">
        For security reasons we don't support this version of your browser,
        <em>and neither should you!</em>
      </h2>

      <p className="not-supported-intro">
        You are visiting this page because we detected an unsupported browser.
        Your browser does not support security features that we require. We
        highly recommend that you update your browser. If you believe you have
        arrived here in error, please
        <a href="mailto:support@kreatize.com"> contact us</a>. Be sure to
        include your browser version.
      </p>

      <div className="browsers-container">
        <h2 className="supported-intro">These are the ones we recommend:</h2>

        <ul className="supported-browsers-list">
          <li className="supported-browsers-list-item browser-chrome">
            <div className="supported-browser-information">Google Chrome</div>
            <div className="supported-browser-download">
              <a
                href="https://www.google.com/intl/en/chrome/browser/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Download latest
              </a>
            </div>
            <div className="clearfix"></div>
          </li>
          <li className="supported-browsers-list-item browser-firefox">
            <div className="supported-browser-information">Mozilla Firefox</div>
            <div className="supported-browser-download">
              <a
                href="https://www.mozilla.org/firefox/all/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Download latest
              </a>
            </div>
            <div className="clearfix"></div>
          </li>
          <li className="supported-browsers-list-item browser-opera">
            <div className="supported-browser-information">Opera</div>
            <div className="supported-browser-download">
              <a
                href="http://www.opera.com/download/get/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Download latest
              </a>
            </div>
            <div className="clearfix"></div>
          </li>
          <li className="supported-browsers-list-item browser-safari">
            <div className="supported-browser-information">Apple Safari</div>
            <div className="supported-browser-download">
              <a
                href="https://support.apple.com/en-us/HT201541"
                target="_blank"
                rel="noopener noreferrer"
              >
                Upgrade your OS
              </a>
            </div>
            <div className="clearfix"></div>
          </li>
          <li className="supported-browsers-list-item browser-ie">
            <div className="supported-browser-information">Microsoft Edge</div>
            <div className="supported-browser-download">
              <a
                href="https://www.microsoft.com/en-us/windows/microsoft-edge"
                target="_blank"
                rel="noopener noreferrer"
              >
                Upgrade your OS (only on Windows 10)
              </a>
            </div>
            <div className="clearfix"></div>
          </li>
        </ul>
      </div>
    </div>
  );
}
