import React, {Component} from 'react';
import {Row, Col, Button} from 'shards-react';
import SweetAlert from 'react-bootstrap-sweetalert';
import AsyncSelect from 'react-select/async';
import {Tooltip} from '@mui/material';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import image from './gender.svg';
import './ImpersonationModal.css';


const t = (key) => i18n.t(key);

class ImpersonationModal extends Component {
  constructor() {
    super();
    this.state = {
      open: false,
      selectedCompany: null,
    };
  }

  getOptions = async (inputValue) => {
    try {
      const companies = await this.props.getCompanies(inputValue);
      const companyNames = companies.map(company => {
        return {label: company.companyName, value: company.assetId};
      });
      return companyNames;
    } catch(err) {
      this.setState({error: err.response.status});
    }
  };

  handleCompanyChange = (company) => {
    this.setState({selectedCompany: company});
  };

  startImpersonation = (assetId) => {
    this.props.startImpersonation(assetId)
      .then(() => window.location.reload(false));
  };

  stopImpersonation = () => {
    this.props.stopImpersonation()
      .then(() => window.location.reload(false));
  };

  showImpersonatedCompany = () => {

    if (this.props.user && this.props.user.impersonating) {
      return (
        <React.Fragment>
          <Row className={'impersonation-row'} style={{marginTop: '0px'}}>
              <span className={'impersonation-text'}>
                {t('modals:impersonation_modal_you_are_currently_impersonating')}:
              </span>
          </Row>
          <Row className={'impersonation-row'}>
              <span>
                {this.props.user.impersonatedCompany.companyName}
              </span>
          </Row>

          <Row className={'impersonation-row'}>
            <Button
              onClick={() => this.stopImpersonation()}
              theme={'danger'}
              className={'stop-button'}
            >
              {t('modals:impersonation_modal_stop_impersonating')}
            </Button>
          </Row>
          <hr className='solid' />
        </React.Fragment>
      );
    }
  };

  showModal = () => {
    return (
      <SweetAlert
        customClass={'impersonation-modal'}
        closeOnClickOutside={true}
        onConfirm={() => {}}
        showConfirm={false}
        title={<h5>{t('modals:impersonation_modal_impersonation')}</h5>}
      >
        <div className={'main-wrapper'}>

          {this.showImpersonatedCompany()}
          <Row className={'impersonation-row'}>
            <div className={'select-wrapper'}>
              <AsyncSelect
                placeholder={t('modals:impersonation_modal_select_company_to_impersonate')}
                loadOptions={this.getOptions}
                onChange={(e) => this.handleCompanyChange(e)}
                value={this.state.selectedCompany}
                styles={{
                  menuPortal: base => ({...base, zIndex: 9999}),
                }}
                menuPortalTarget={document.body}
              />
            </div>
          </Row>
          <Row className={'impersonation-row'}>
            <Col>
              <Button
                onClick={() => this.setState({open: false})}
                theme={'secondary'}
                block
              >
                {t('modals:impersonation_modal_close')}
              </Button>
            </Col>
            <Col>
              <Button
                disabled={!this.state.selectedCompany}
                onClick={() => this.startImpersonation(this.state.selectedCompany.value)}
                theme={'success'}
                block
              >
                {t('modals:impersonation_modal_start_impersonating')}
              </Button>
            </Col>
          </Row>
        </div>
      </SweetAlert>
    );
  };

  render() {
    return (
      <div>
        <Tooltip title={t('common:nav_impersonation_tooltip')}>
          <span>
          <Button className={'impersonation-button'} onClick={() => this.setState({open: true})}>
            <img src={image} alt={'impersonation'} />
          </Button>
          </span>
        </Tooltip>
        {this.state.open && this.showModal()}
      </div>
    );
  }
};

ImpersonationModal.propTypes = {
  /** Kooper User Object */
  user: PropTypes.object.isRequired,
  /** Async Function that resolves in a list of companies */
  getCompanies: PropTypes.func.isRequired,
  /** Async function which starts the impersonation */
  startImpersonation: PropTypes.func.isRequired,
  /** Async function which stops the impersonation */
  stopImpersonation: PropTypes.func.isRequired,

};

export default ImpersonationModal;