import React from 'react';
import {ThreeStateCheckboxSwitch} from './SelfServiceTable';
import {Button, Card, CardBody, CardFooter, CardHeader, Col, Collapse, Row} from 'shards-react';
import _ from 'lodash';


export const SelfServiceProcesses = (props: any) => {

  const renderProcessFeatures = (process: any) => {
    const {t} = props;
    const processFeatures = process.processFeatures.map(processFeature => ({...processFeature, translation: t(`processFeatures:${processFeature.processFeatureKey}`)}));
    return (
      <Collapse open={process.active || process.collapseOpen}>
        <table className='table mb-0'>
          {_.orderBy(processFeatures, ['translation'], ['asc'])?.map((processFeature: any) => {
            return (
              <tr>
                <td>{t(`processFeatures:${processFeature.processFeatureKey}`)}</td>
                <td>{props.createProcessFeatureInputGroup(processFeature, process)}</td>
              </tr>
            );
          })}
        </table>
      </Collapse>
    );
  };

  return props.processes.map((process: any) => {

    return (
      <Card small className='mb-4 pt-2' key={process.key}>
        <CardHeader>
          <Row>
            <Col>{props.t(`processes:${process.key}`)}</Col>
            <Col style={{display: 'flex', justifyContent: 'flex-end'}}>
              <div style={{marginRight: '10px'}}>
                {!process.active && process.collapseOpen && '(Noch nicht gespeichert)'}
              </div>
              <ThreeStateCheckboxSwitch
                checked={process.active}
                onEnable={() => {
                  if (process.processFeatures.every((x: any) => x.value)) {
                    props.handleToggle(process, true);
                  } else if (process.active === false) {
                    process.active = null;
                    process.collapseOpen = true;
                    props.handleProcessUpdate(process);
                  } else {
                    process.collapseOpen = true;
                    props.handleProcessUpdate(process);
                  }
                }}
                onDisable={() => props.handleToggle(process, false)}
              />

            </Col>
          </Row>
        </CardHeader>
        <CardBody>{renderProcessFeatures(process)}</CardBody>
        {(process.collapseOpen || process.active) &&
          <CardFooter>
            <div style={{width: '100%'}}>
              <Button
                block
                disabled={!process.processFeatures.every((x: any) => x.value)}
                theme={'success'}
                onClick={() => props.handleToggle(process, true)}
              >
                {props.t('selfService:save')}
              </Button>
            </div>
          </CardFooter>}
      </Card>
    );
  });
};